import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Loader from "../../components/Loader/Loader";
import banner from "../../assets/images/banner.png";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import useStyles from "./styles";

import { get_sub_category, get_banner_page } from "../../api/API";

import SmoothImage from 'react-smooth-image';

const Product = () => {
  const classes = useStyles();

  let { Product } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Product]);

  // API

  let { category_id } = useParams();

  const [isBusy, setIsBusy] = React.useState(true);
  const [product, setProduct] = React.useState([]);
  const [subCategoryName, setSubCategoryName] = React.useState("");
  const [banner, setBanner] = React.useState([]);

  const getProductByCategory = () => {
    let postparam = {
      category_id: category_id,
      type: "products",
    };
    get_sub_category(postparam).then((json) => {
      setProduct(json.data[0].products);
      setSubCategoryName(json.data[0].name);
      setIsBusy(true);
    });
  };
  const getBanner = () => {
    let postparam = {
      page: "Sub Category",
    };
    get_banner_page(postparam).then((json) => {
      setBanner(json.data);
      setIsBusy(false);
    });
  };
  React.useEffect(() => {
    getProductByCategory();
    getBanner();
    setIsBusy(false);
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == false ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          <Container>
            <Carousel>
              {banner == "" ? (
                <></>
              ) : (
                <>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </>
              )}
            </Carousel>
            <Section>
              <Paragraph size={600} center margin="0 0 1rem 0">
                {subCategoryName}
              </Paragraph>
              <div className={classes.theProductContainer}>
                <Grid container spacing={3}>
                  {product.map((item) => (
                    <Grid xs={6} sm={4} md={3} key={item.categori_id}>
                      <Link
                        className={classes.theProductCard}
                        to={`../productdetail/${item.product_id}`}
                      >
                        <div className={classes.theProductIMG}>
                          {/* <img
                            className={classes.img}
                            src={item.product_image}
                          /> */}
                          <SmoothImage
                            className={classes.img}
                            src={item.product_image}
                          />
                        </div>
                        <div className={classes.theDetail}>
                          {item.sales_price == null ? (
                            <Paragraph
                              size="300"
                              color="#f4b14a"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              RM {parseFloat(item.pricing).toFixed(2)}
                            </Paragraph>
                          ) : (
                            <Paragraph
                              size="300"
                              color="#f4b14a"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              RM {parseFloat(item.sales_price).toFixed(2)}
                            </Paragraph>
                          )}
                          <div className={classes.theFlex}>
                            <Paragraph
                              size="300"
                              color="#878787"
                              textDecoration="line-through"
                              bold="600"
                              margin="0 1rem 0 0"
                            >
                              RM{parseFloat(item.pricing).toFixed(2)}
                            </Paragraph>

                            {item.sales_value == null ? (
                              <Paragraph size="300" bold="600" color="#000">
                                0%
                              </Paragraph>
                            ) : (
                              <Paragraph size="300" bold="600" color="#000">
                                {parseFloat(item.sales_value)}%
                              </Paragraph>
                            )}
                          </div>
                          <Paragraph
                            size="300"
                            color="#878787"
                            bold="600"
                            margin="0 0 .4rem 0"
                          >
                            {item.name}
                          </Paragraph>
                        </div>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Section>
          </Container>
        </>
      )}
    </React.Fragment>
  );
};

export default Product;
