import styled from "styled-components";

export const StickyHeaderContainer = styled.div`
  background: ${({ background }) => background};
  padding: 0.4rem 0.4rem;

  @media screen and (min-width: 992px) {
    padding: 0.8rem 1.6rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.8rem 4rem;
  }

  p {
    color: #000;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
`;
