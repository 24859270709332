import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import banner from "../../assets/images/banner.png";
import useStyles, { FlexContainer, Icon, SubCategoryList } from "./styles";
import { search } from "../../api/API";
import { useHistory } from "react-router";
import SmoothImage from "react-smooth-image";

const SearchProduct = (props) => {
  const classes = useStyles();

  let { SearchProduct } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [SearchProduct]);

  // API

  const history = useHistory();
  const [isBusy, setIsBusy] = React.useState(true);
  const [product, setProduct] = React.useState([]);

  // SEARCH PRODUCT

  const [form, setForm] = React.useState({
    search: "",
  });

  const [searchParam, setSearchParam] = React.useState({
    keyword: "",
  });

  React.useEffect(async () => {
    if (props.match.params.query) {
      var res = await search({
        ...form,
        keyword: props.match.params.query,
      });
      if (res.status) {
        setProduct(res.data);
        setIsBusy(true);
      } else {
        // displayProduct();
        // var res = await search({
        //   ...form,
        //   keyword: "",
        // });
        // setProduct(res.data);
        // setIsBusy(true);
        alert(res.message);
      }
    }
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == false ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          <Container>
            <Section>
              <div className={classes.theProductContainer}>
                <Grid container spacing={3}>
                  {product.map((item) => (
                    <Grid xs={6} sm={4} md={3} key={item.categori_id}>
                      <Link
                        className={classes.theProductCard}
                        to={`../productdetail/${item.product_id}`}
                      >
                        <div className={classes.theProductIMGMobile}>
                          {item.max_stock == "" ? (
                            <>
                              {item.stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {item.max_stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <SmoothImage
                            imageStyles={{
                              width: "100%",
                              height: "100px",
                              objectFit: "contain",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                            src={item.product_image}
                          />
                        </div>
                        <div className={classes.theProductIMG}>
                          {item.max_stock == "" ? (
                            <>
                              {item.stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="500"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {item.max_stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="500"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <SmoothImage
                            imageStyles={{
                              width: "100%",
                              height: "150px",
                              objectFit: "contain",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                            src={item.product_image}
                          />
                        </div>
                        <div className={classes.theDetail}>
                          {item.single_price == null ? (
                            <Paragraph
                              size="300"
                              color="#f4b14a"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              RM {parseFloat(item.pricing).toFixed(2)}
                            </Paragraph>
                          ) : (
                            <Paragraph
                              size="300"
                              color="#f4b14a"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              RM {parseFloat(item.single_price).toFixed(2)}
                            </Paragraph>
                          )}
                          {item.ori_price != "0.00" && (
                            <div className={classes.theFlex}>
                              <Paragraph
                                size="300"
                                color="#878787"
                                textDecoration="line-through"
                                bold="600"
                                margin="0 1rem 0 0"
                              >
                                RM{parseFloat(item.ori_price).toFixed(2)}
                              </Paragraph>
                            </div>
                          )}
                          <Paragraph
                            size="300"
                            color="#878787"
                            bold="600"
                            margin="0 0 .4rem 0"
                          >
                            {item.name}
                          </Paragraph>
                        </div>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Section>
          </Container>
        </>
      )}
    </React.Fragment>
  );
};

export default SearchProduct;
