// import axios from "axios";
const host = "https://staging.stocky.com.my/admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
    // let platform = "website";
    // postparam["platform"] = platform;

    // alert(token)
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const login_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_user",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const login_social_email = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_social_email",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const login_social_facebook = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_social_facebook",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const get_category = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_category",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_sub_category = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_sub_category",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_all_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_all_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const calculate2 = async (postparam) => {
  var new_postparam = {
    ...postparam,
    product: []
  };
  var products = (JSON.parse(postparam.product));

  for (var i = 0; i < products.length; i++) {
    new_postparam.product.push({
      product_id: products[i].product_id,
      product_option_selection_id: products[i].product_option_selection_id,
      quantity: products[i].quantity,
      index: products[i].index
    });
  }
  new_postparam.product = JSON.stringify(new_postparam.product);
  var response = await fetch(
    access_endpoint_link + "calculate2",
    format_request(new_postparam)
  );
  var json = await response.json();
  return json;
};

export const get_banner = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const orderhistory = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "orderhistory",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const orderhistory_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "orderhistory_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_otp = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_otp",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const new_get_blog = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "new_get_blog",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_blog_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_blog_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_user_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product_option_selection = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product_option_selection",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product_option = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product_option",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_banner_page = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner_page",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_subcategory_banner_page = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_subcategory_banner_page",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_specific_product_option_selection = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_specific_product_option_selection",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_order_by_intent = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_order_by_intent",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};
export const stripe_payment = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "payment_intent",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

// export const get_delivery_slot = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "get_delivery_slot",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// };

export const get_delivery_time = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_delivery_time",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_voucher_code = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_voucher_code",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const shop_purchase_phase_2_new = async (postparam) => {
  var new_postparam = {
    ...postparam,
    product: []
  };
  var products = (JSON.parse(postparam.product));

  for (var i = 0; i < products.length; i++) {
    new_postparam.product.push({
      product_id: products[i].product_id,
      product_option_selection_id: products[i].product_option_selection_id,
      quantity: products[i].quantity,
      index: products[i].index
    });
  }
  new_postparam.product = JSON.stringify(new_postparam.product);
  var response = await fetch(
    access_endpoint_link + "shop_purchase_phase_2_new",
    format_request(new_postparam)
  );
  var json = await response.json();
  return json;
};

export const search = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "search",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_state = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_state",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const filter_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "filter_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const forgot_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "forgot_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_city = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_city",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const choose_date_time = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "choose_date_time",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address_list = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address_list",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const new_deliver_now = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "new_deliver_now",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_eligible_express = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_eligible_express",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_one_hour_delivery = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_one_hour_delivery",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_new_arrival_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_new_arrival_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_google_place_details = async (value) => {
  var data = await format_request({
    place_id: encodeURIComponent(value)
  });
  if (data) {
    var response = await fetch(access_endpoint_link + 'get_google_place_details', data);

    var json = await response.json();
    if (json.status == false) {
      alert(json.message);
    } else {
      return json;
    }
  }
}

export const google_search = async (value) => {
  var data = await format_request({
    value: encodeURIComponent(value)
  });
  if (data) {
    var response = await fetch(access_endpoint_link + 'google_search', data);

    var json = await response.json();
    if (json.status == false) {
      alert(json.message);
    } else {
      return json;
    }
  }
};

export const add_user_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "add_user_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_user_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_user_address_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user_address_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_user_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_user_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};
