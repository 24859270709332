import React from "react";
import {
  Container,
  Checkbox,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Modal,
  TextField,
} from "@material-ui/core";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import { Link, useHistory } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import pin from "../../assets/images/pin.svg";
import moment from "moment";
import useStyles, {
  ShowAddress,
  ModalAddress,
  ModalHeader,
  FlexIcon,
  ModalBody,
} from "./styles";
import { Close, Bookmark, ArrowForwardIos, Room, Edit } from "@material-ui/icons";
import {
  choose_date_time,
  get_delivery_time,
  get_address_list,
  new_deliver_now,
  check_eligible_express,
  google_search,
  get_google_place_details,
  add_user_address,
  get_user_address,
  get_user_address_detail,
  edit_user_address
} from "../../api/API";

import useAddress from "../../hooks/useAddress";
import Loader from "../../components/Loader/Loader";
import { is } from "@babel/types";

export default function ExpressDelivery() {
  const classes = useStyles();

  const history = useHistory();

  const { addUserAddress, clearAddress } = useAddress();

  const [addressData, setAddressData] = React.useState({
    address: "",
    postcode: "",
    city: "",
    state: "",
  });


  /// ADD ADDRESS BOOK

  const [addressBook, setAddressBook] = React.useState({
    title: '',
    unit: '',
    address: '',
    postcode: '',
    city: '',
    state: '',
    latitude: '',
    longitude: '',
  });

  // GOOGLE MAP

  const mapContainerStyle = {
    height: "440px",
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  // const [center, setCenter] = React.useState({
  //   lat: 0,
  //   lng: 0,
  // });

  const [location, setLocation] = React.useState({
    lat: parseFloat(1.5469),
    lng: parseFloat(-103.7781),
    latitude: parseFloat(1.5469),
    longitude: parseFloat(-103.7781),
    latitudeDelta: 0.0421,
    longitudeDelta: 0.0421,
  });

  const loadMarkerPosition = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then((e) => {
      if (e.results.length) {
        var city = "";
        var postcode = "";
        var state = "";
        for (var i = 0; i < e.results[0].address_components.length; i++) {
          if (
            inArray("postal_code", e.results[0].address_components[i].types)
          ) {
            postcode = e.results[0].address_components[i].long_name;
          }
          if (inArray("locality", e.results[0].address_components[i].types)) {
            city = e.results[0].address_components[i].long_name;
          }
          if (
            inArray(
              "administrative_area_level_1",
              e.results[0].address_components[i].types
            )
          ) {
            state = e.results[0].address_components[i].long_name;
          }
        }
        setAddressData({
          ...addressData,
          address: e.results[0].formatted_address,
          city: city,
          postcode: postcode,
          state: state,
        });

        setLocation({
          lat: e.results[0].geometry.location.lat,
          lng: e.results[0].geometry.location.lng,
          latitude: e.results[0].geometry.location.lat,
          longitude: e.results[0].geometry.location.lng,
        });
      }
    });
  };

  const inArray = (needle, haystack) => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  };

  React.useEffect(() => {
    Geocode.setApiKey("AIzaSyDGX8_b5F5IpadgnfN9shEGBBihoWEVmb8");

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
          latitude: parseFloat(position.coords.latitude),
          longitude: parseFloat(position.coords.longitude),
        });
        Geocode.fromLatLng(
          parseFloat(position.coords.latitude),
          parseFloat(position.coords.longitude)
        ).then((e) => {
          if (e.results.length) {
            var city = "";
            var postcode = "";
            var state = "";
            for (var i = 0; i < e.results[0].address_components.length; i++) {
              if (
                inArray("postal_code", e.results[0].address_components[i].types)
              ) {
                postcode = e.results[0].address_components[i].long_name;
              }
              if (
                inArray("locality", e.results[0].address_components[i].types)
              ) {
                city = e.results[0].address_components[i].long_name;
              }
              if (
                inArray(
                  "administrative_area_level_1",
                  e.results[0].address_components[i].types
                )
              ) {
                state = e.results[0].address_components[i].long_name;
              }
            }
            setAddressData({
              ...addressData,
              address: e.results[0].formatted_address,
              city: city,
              postcode: postcode,
              state: state,
            });
          }

          console.log(e.results);
        });
      });
    }
  }, []);

  //



  const [userAddressBookData, setUserAddressBookData] = React.useState({});

  const [isDefault, setIsDefault] = React.useState(false);

  const handleChangeAddress = (e) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setAddressBook({
      ...addressBook,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAddressBook = (e) => {
    setUserAddressBookData({
      ...userAddressBookData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCheck = (event) => {

    setIsDefault(event.target.checked);

  };

  // RENDERS DAYS

  const [getDays, setGetDays] = React.useState([]);

  const [chooseDays, setChooseDays] = React.useState("Today");

  React.useEffect(() => {
    choose_date_time().then((json) => {
      setGetDays(json.data);
      setChooseDays(json.data[0].value);
    });
  }, []);

  const handleChooseDays = (date) => {
    setChooseDays(date);

    let postparam = {
      date: date,
    };
    get_delivery_time(postparam).then((json) => {
      setGetTime(json.data);
      setChooseTime(json.data[0].time);
      console.log(json.data);
    });
  };

  React.useEffect(() => {
    handleChooseDays(chooseDays);
  }, []);

  // RENDER TIME

  const [getTime, setGetTime] = React.useState([]);

  const [chooseTime, setChooseTime] = React.useState();

  const handleChooseTime = (times) => {
    setChooseTime(times);
  };

  // CHOOSE DELIVERY METHOD

  const [chooseDeliveryMethod, setChooseDeliveryMethod] =
    React.useState("false");

  const [deliveryNow, setDeliveryNow] = React.useState("DeliveryNow");

  // const handleChooseDeliveryMethod = () => {
  //   setDeliveryNow('DeliveryNow')
  // }

  // MODAL SHOW ADDRESS

  const [openAddressModal, setOpenAddressModal] = React.useState(false);

  const handleOpenAddressModal = () => {
    setOpenAddressModal(true);
  };
  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
  };

  //

  const [ownAddressList, setOwnAddressList] = React.useState([]);

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const getOwnAddressList = () => {
    get_address_list({ token: token }).then((json) => {
      setOwnAddressList(json.data);
    });
  };

  React.useEffect(() => {
    getOwnAddressList();
  }, []);

  //

  const [geocodeAddressList, setGeocodeAddressList] = React.useState([]);
  const [geocodeTextInput, setGeocodeTextInput] = React.useState("");

  //

  const googlePlacesSearch = async (value) => {

    google_search(value).then(res => {
      if (res.status) {

        setGeocodeAddressList(res.data);
      }

      setIsSearching(false);
    }).catch(e => {

      setIsSearching(false);
    });
  }

  const [keyDownTimer, setKeyDownTimer] = React.useState(null);

  const [isSearching, setIsSearching] = React.useState(false);

  const searchPlaces = (e) => {
    // console.log("Start search", e.target.value);
    googlePlacesSearch(e);
  };

  const handleChangeGeocode = (e) => {
    setGeocodeTextInput(e.target.value);

    let searchAddress = e.target.value;

    setIsSearching(true);
    var timer = keyDownTimer;
    clearTimeout(timer);
    console.log("change", searchAddress);
    var newTimer = setTimeout(() => {
      if (searchAddress != "") {
        searchPlaces(searchAddress);
      } else {
        setGeocodeAddressList([]);
        setIsSearching(false);
      }
    }, 500);
    setKeyDownTimer(newTimer);

    setAddressBook({
      ...addressBook,
      address: searchAddress,
    });
  };


  const handleChooseAddressFromPlace = async (item) => {

    get_google_place_details(item.place_id).then(res => {
      if (res.status) {
        var item = res.data.result;
        var city = '';
        var postcode = '';
        var state = '';
        var streetnumber = '';
        var route = '';
        var street = '';

        for (var i = 0; i < item.address_components.length; i++) {
          if (
            inArray(
              'postal_code',
              item.address_components[i].types,
            )
          ) {
            postcode = item.address_components[i].long_name;
          }
          if (
            inArray('locality', item.address_components[i].types)
          ) {
            city = item.address_components[i].long_name;
          }
          if (
            inArray(
              'administrative_area_level_1',
              item.address_components[i].types,
            )
          ) {
            state = item.address_components[i].long_name;
          }
          if (
            inArray('route', item.address_components[i].types)
          ) {
            route = item.address_components[i].long_name;
          }
          if (
            inArray(
              'street_number',
              item.address_components[i].types,
            )
          ) {
            streetnumber = item.address_components[i].long_name;
          }
          if (
            inArray(
              'sublocality',
              item.address_components[i].types,
            )
          ) {
            street = item.address_components[i].long_name;
          }
        }

        let address = item.formatted_address;

        setLocation({
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
          latitudeDelta: 0.0022,
          longitudeDelta: 0.0021,
        });
        console.log('address', {
          ...addressData,
          address: address,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
        });

        setAddressData({
          ...addressData,
          address: address,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
        });

        setAddressBook({
          ...addressBook,
          address: address,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,

          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),

          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });

        setUserAddressBookData({
          ...userAddressBookData,
          address: address,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });


        // handleCloseAddressModal();
      }
    });
  };

  const [hiddenConfirmAddress, setHiddenConfirmAddress] = React.useState(false);


  const handleChooseAddress = item => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlace(item);
    } else {
      var city = '';
      var postcode = '';
      var state = '';
      var streetnumber = '';
      var route = '';
      var street = '';

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray('postal_code', item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray('locality', item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            'administrative_area_level_1',
            item.address_components[i].types,
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray('route', item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray('street_number', item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray('sublocality', item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;
    setLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    setAddressData({
      ...addressData,
      address: address,
      route: route,
      streetnumber: streetnumber,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
    });

    setAddressBook({
      ...addressBook,
      address: address,
      route: route,
      streetnumber: streetnumber,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
    });

    setShowAddAddressBook(3);

    // handleCloseAddressModal();

    // setHiddenConfirmAddress(true);
  };

  const handleChooseAddressEdit = item => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlace(item);
    } else {
      var city = '';
      var postcode = '';
      var state = '';
      var streetnumber = '';
      var route = '';
      var street = '';

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray('postal_code', item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray('locality', item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            'administrative_area_level_1',
            item.address_components[i].types,
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray('route', item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray('street_number', item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray('sublocality', item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;

    setLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    // setAddressData({
    //   ...addressData,
    //   address: address,
    //   route: route,
    //   streetnumber: streetnumber,
    //   street: street,
    //   city: city,
    //   postcode: postcode,
    //   state: state,
    // });

    setUserAddressBookData({
      ...userAddressBookData,
      address: address,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
    });

    setShowAddAddressBook(4);
  };

  const handleChooseHistoryAddress = (address) => {
    Geocode.fromAddress(address).then((res) => {
      console.log(res);
      var addresses = [];
      if (res.status == "OK") {
        for (var j = 0; j < res.results.length; j++) {
          var temp_address = "";

          let city = "";
          let postcode = "";
          let state = "";
          let streetnumber = "";
          let route = "";
          let street = "";

          var details = res.results[j];
          for (var i = 0; i < details.address_components.length; i++) {
            if (inArray("postal_code", details.address_components[i].types)) {
              postcode = details.address_components[i].long_name;
            }
            if (inArray("locality", details.address_components[i].types)) {
              city = details.address_components[i].long_name;
            }
            if (
              inArray(
                "administrative_area_level_1",
                details.address_components[i].types
              )
            ) {
              state = details.address_components[i].long_name;
            }
            if (inArray("route", details.address_components[i].types)) {
              route = details.address_components[i].long_name;
            }
            if (inArray("street_number", details.address_components[i].types)) {
              streetnumber = details.address_components[i].long_name;
            }
            if (inArray("sublocality", details.address_components[i].types)) {
              street = details.address_components[i].long_name;
            }
          }

          addresses.push({
            address: res.results[j].formatted_address,
            lat: parseFloat(res.results[j].geometry.location.lat),
            lng: parseFloat(res.results[j].geometry.location.lng),
            latitude: parseFloat(res.results[j].geometry.location.lat),
            longitude: parseFloat(res.results[j].geometry.location.lng),
            city: city,
            postcode: postcode,
            state: state,
            route: route,
            street: street,
            streetnumber: streetnumber,
          });
        }

        if (addresses.length) {
          // console.log("FOUND", addresses);
          var tempaddress = addresses[0];
          setLocation({
            lat: parseFloat(tempaddress.lat),
            lng: parseFloat(tempaddress.lng),
            latitude: parseFloat(tempaddress.lat),
            longitude: parseFloat(tempaddress.lng),
            latitudeDelta: 0.0022,
            longitudeDelta: 0.0021,
          });

          setAddressData({
            ...addressData,
            address: tempaddress.address,
            route: tempaddress.route,
            streetnumber: tempaddress.streetnumber,
            street: tempaddress.street,
            city: tempaddress.city,
            postcode: tempaddress.postcode,
            state: tempaddress.state,
          });
        }
      }
    });
    handleCloseAddressModal();
  };

  const [deliveryNowTime, setDeliveryNowTime] = React.useState("");

  const handleDeliveryNow = () => {
    // alert('sad')
    new_deliver_now({}).then((json) => {
      // alert(JSON.stringify(json))
      if (json.status) {
        setDeliveryNowTime(json.data.time);

        let postparam = {
          ...addressData,
          // ...deliveryDate,
          // ...time,
          // ...location,
          latitude: location.lat,
          longitude: location.lng,
          time: json.data.time,
          date: chooseDays,
        };

        check_eligible_express(postparam).then((res) => {
          if (res) {
            let dataListing = {
              // ...location,
              latitude: location.lat,
              longitude: location.lng,
              ...addressData,
              // ...time,
              // ...deliveryDate
              time: json.data.time,
              date: chooseDays,
            };

            addUserAddress(dataListing);

            console.log(dataListing);

            // console.log('current  ' + deliveryNowTime);

            let array = [];

            array.push(dataListing);

            localStorage.setItem("addressListing", JSON.stringify(array));

            history.push({
              pathname: "/expressproductlist",
              state: {
                latitude: location.latitude,
                longitude: location.longitude,
              },
            });

            console.log("DATA PASS TO NXT", JSON.stringify(array));
          } else {
            alert(json.message);
          }
        });
      } else {
        alert(json.message);
      }
    });
  };

  //

  const handleSubmit = () => {

    // var deliveryDATE = 'Today';
    // if (chooseDays != 'Today') {
    //   var asd = chooseDays + ' ' + new Date().getFullYear();
    //   var deliveryDATE = moment(asd).format('YYYY-MM-DD');
    // }


    // alert(deliveryDATE)

    let postparam = {
      ...addressData,
      // ...deliveryDate,
      // ...time,
      // ...location,
      latitude: location.lat,
      longitude: location.lng,
      time: chooseTime,
      date: chooseDays,
    };

    if (
      addressData.length == 0 ||
      // deliveryDate.length == 0 ||
      // time.length == 0
      chooseDays.length == 0 ||
      chooseTime.length == 0
    ) {
      alert("All field required");
    }

    check_eligible_express(postparam).then((res) => {
      if (res) {
        // console.log(postparam);

        let dataListing = {
          // ...location,
          latitude: location.lat,
          longitude: location.lng,
          ...addressData,
          // ...time,
          // ...deliveryDate
          time: chooseTime,
          date: chooseDays,
        };

        addUserAddress(dataListing);

        console.log(dataListing);

        let array = [];

        array.push(dataListing);

        localStorage.setItem("addressListing", JSON.stringify(array));

        // navigation.navigate('ExpressProductListScreen', {
        //   latitude: location.latitude,
        //   longitude: location.longitude,
        //   ...addressData,
        // });

        history.push({
          pathname: "/expressproductlist",
          state: {
            latitude: location.latitude,
            longitude: location.longitude,
          },
        });

        // history.push("/expressproductlist");
        console.log(dataListing);

        // setTimeout(() => {
        //   console.log(userAddress);
        // }, 5000);
      } else {
        alert(res.message);
      }
    });
  };




  const [showAddAddressBook, setShowAddAddressBook] = React.useState(1);

  const handleAddAddressBook = (text) => {
    setShowAddAddressBook(text);
  };

  // const handleOpen = txt => {
  //   setShowAddAddressBook(1)
  // }

  // ADD 



  const handleSaveAddressBook = () => {
    add_user_address({
      token: token,
      ...addressBook,
    }).then(json => {
      if (json.status) {
        alert(json.message);
        handleCloseAddressModal();
        setShowAddAddressBook(1);
        getUserAddressBook();
        setAddressBook({
          ...addressBook,
          title: '',
          unit: '',
          address: '',
          postcode: '',
          city: '',
          state: '',
          latitude: '',
          longitude: '',
        })
      } else {
        alert(json.message)
      }
    });
  };

  // llist

  const [userAddressBook, setUserAddressBook] = React.useState([]);


  const handleChooseAddressBook = user_address_id => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then(json => {
      setUserAddressBookData(json.data);

      setAddressData({
        ...addressData,
        address: json.data.address,
        postcode: json.data.postcode,
        city: json.data.city,
        state: json.data.state,
        unit: json.data.unit
      });

      let lat = json.data.latitude;
      let lng = json.data.longitude;

      setLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        latitudeDelta: 0.0022,
        longitudeDelta: 0.0021,
      });

      // alert(JSON.stringify(json.data))
    });

    handleCloseAddressModal();

    // alert(JSON.stringify(userAddressBookData))
  };


  // GET ADDRESS BOOK

  const getUserAddressBook = () => {
    get_user_address({ token: token }).then(json => {
      setUserAddressBook(json.data);
    });
  };

  const getUserAddressBookDetail = user_address_id => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then(json => {
      setUserAddressBookData(json.data);

      if (json.data.is_default == '1') {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    });
  };

  React.useEffect(() => {
    getUserAddressBook();
    getUserAddressBookDetail();
  }, [])

  const getUserAddressID = user_address_id => {
    handleAddAddressBook(4);

    getUserAddressBookDetail(user_address_id);

    console.log(user_address_id);
  };

  const handleEditAddressBook = () => {
    let res = '0';
    if (isDefault == true) {
      res = '1';
    }
    let postparam = {
      ...userAddressBookData,
      is_default: res,
    };
    edit_user_address(postparam).then(json => {
      if (json.status) {
        alert(json.message);
        // handleCloseAddress()
        setShowAddAddressBook(1);
        getUserAddressBook();
      } else {
        alert(json.message);
      }
    });
  };

  const handleDeleteAddress = () => {
    let postparam = {
      ...userAddressBookData,
      deleted: 1,
    };
    edit_user_address(postparam).then(json => {
      if (json.status) {
        alert(json.message);
        setShowAddAddressBook(1);
        getUserAddressBook();
      } else {
        alert(json.message);
      }
    });
  };

  const handleLogin = () => {
    history.push('/login')
  }


  return (
    <React.Fragment>
      <Navbar />
      <LoadScript
        googleMapsApiKey="AIzaSyDGX8_b5F5IpadgnfN9shEGBBihoWEVmb8"
      // libraries={["places"]}
      >
        <Container>
          <Section>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={7}>
                <div className={classes.theBox}>
                  {/* {placesLoaded && (
                    <GoogleMap
                      id="map"
                      mapContainerStyle={mapContainerStyle}
                      zoom={14}
                      center={location}
                      // onLoad={placesLoaded}
                      // region={location}
                    >
                      <Marker
                        draggable={true}
                        position={location}
                        onDragEnd={loadMarkerPosition}
                      ></Marker>
                    </GoogleMap>
                  )} */}

                  <GoogleMap
                    id="map"
                    mapContainerStyle={mapContainerStyle}
                    zoom={14}
                    center={location}
                    onLoad={onMapLoad}
                  // region={location}
                  >
                    <Marker
                      draggable={true}
                      position={location}
                      onDragEnd={loadMarkerPosition}
                    ></Marker>
                  </GoogleMap>

                  <br />
                  {/* <Paragraph
                    color="#1e1e1e"
                    bold="600"
                    style={{ fontSize: "1rem", marginBottom: '.6rem' }}
                  >
                    Search Address
                  </Paragraph>
                  <a
                    style={{ color: "#101010" }}
                    onClick={() => handleOpenAddressModal()}
                  >
                    <ShowAddress>
                      <Paragraph style={{fontSize: 14}} bold="500">{addressData.address}</Paragraph>
                    </ShowAddress>
                  </a> */}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Card>
                  <div>
                    {/* <div >
                      <Link className={classes.theFlexCreateBtn} onClick={() => handleOpenAddressModal()} >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Bookmark style={{ color: '#000', marginRight: 4 }} />
                          <div>
                            <Paragraph color="#000" bold="600">Create Address Book</Paragraph>
                            <Paragraph
                              color="#878787"
                              bold="400"
                              style={{ fontSize: 12 }}
                            >
                              Save your favourite address
                            </Paragraph>
                          </div>
                        </div>
                        <ArrowForwardIos style={{ fontSize: 16, color: '#000' }} />
                      </Link>
                    </div> */}


                    {/* <FormInput
                      bg="#fff"
                      placeholder="Unit / Block"
                      name="unit"
                      label="Unit / Block"
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChangeAddress}
                    /> */}

                    {/* {hiddenConfirmAddress == true && (
                      <FormInput
                        bg="#fff"
                        placeholder="Confirm Address"
                        name="address"
                        label="Confirm Address"
                        color="#1e1e1e"
                        bold="600"
                        multiline
                        rows={4}
                        value={addressData.address}
                        onChange={handleChangeAddress}
                      />
                    )} */}

                    {token != "" && token != undefined && token != 'undefined' ? (
                      <>
                        <Link onClick={() => handleOpenAddressModal()}>
                          <Paragraph bold="700" color="#e60c0b" margin="0 0 1rem 0" style={{ fontSize: '1rem' }}>PRESS HERE TO CHANGE CURRENT ADDRESS</Paragraph>
                        </Link>

                        <Link className={classes.theCurrentAddress} onClick={() => handleOpenAddressModal()}>
                          {addressData.address}
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link onClick={() => handleLogin()}>
                          <Paragraph bold="700" color="#e60c0b" margin="0 0 1rem 0" style={{ fontSize: '1rem' }}>PRESS HERE TO CHANGE CURRENT ADDRESS</Paragraph>
                        </Link>

                        <Link className={classes.theCurrentAddress} onClick={() => handleLogin()}>
                          {addressData.address}
                        </Link>
                      </>
                    )}




                  </div>
                  <br />
                  <div>
                    <Link
                      className={
                        deliveryNow == "DeliveryNow"
                          ? classes.theChooseActive
                          : classes.theChoose
                      }
                      onClick={() => setDeliveryNow("DeliveryNow")}
                    >
                      <Paragraph color="#000" bold="600" style={{ fontSize: 14 }}>
                        Deliver Now
                      </Paragraph>
                      <Paragraph
                        color="#878787"
                        bold="400"
                        style={{ fontSize: 12 }}
                      >
                        Estimated Delivery Time 60 - 90 mins
                      </Paragraph>
                    </Link>
                    <br />
                    <Link
                      className={
                        deliveryNow != "DeliveryNow"
                          ? classes.theChooseActive
                          : classes.theChoose
                      }
                      onClick={() => setDeliveryNow("ChooseDelivery")}
                    >
                      <Paragraph color="#000" bold="600" style={{ fontSize: 14 }}>
                        Choose Date & Time
                      </Paragraph>
                      <Paragraph
                        color="#878787"
                        bold="400"
                        style={{ fontSize: 12 }}
                      >
                        Schedule Your Delivery Time
                      </Paragraph>
                    </Link>
                  </div>
                  <br />
                  {deliveryNow != "DeliveryNow" ? (
                    <>
                      <div>
                        <Paragraph
                          color="#1e1e1e"
                          bold="600"
                          style={{ fontSize: "1rem", marginBottom: "1rem" }}
                        >
                          Delivery On:
                        </Paragraph>
                        <div className={classes.theFlex}>
                          {getDays.map((item) => (
                            <Link
                              chooseDays={chooseDays}
                              className={
                                chooseDays === item.value
                                  ? classes.theChooseActive_
                                  : classes.theChoose_
                              }
                              onClick={() => handleChooseDays(item.value)}
                            >
                              <Paragraph
                                color="#000"
                                center
                                style={{
                                  paddingLeft: "1rem",
                                  paddingRight: "1rem",
                                }}
                              >
                                {item.date}
                              </Paragraph>
                            </Link>
                          ))}
                        </div>
                        <Paragraph
                          color="#1e1e1e"
                          bold="600"
                          style={{ fontSize: "1rem", marginBottom: "1rem" }}
                        >
                          Delivery Time:
                        </Paragraph>
                        <div className={classes.theTimeHeight}>
                          {getTime.map((item) => (
                            <Link
                              className={
                                chooseTime === item.time
                                  ? classes.theTimeActive
                                  : classes.theTime
                              }
                              onClick={() => handleChooseTime(item.time)}
                            >
                              <Paragraph color="#000" center>
                                {item.time}
                              </Paragraph>
                            </Link>
                          ))}
                        </div>
                      </div>
                      <Button
                        label="Confirm Delivery Slot"
                        bg="#ffa921"
                        color="#fff"
                        bold="600"
                        onClick={() => handleSubmit()}
                        pd="0.6rem 2rem"
                      />
                    </>
                  ) : (
                    <Button
                      label="Confirm Delivery Now"
                      bg="#ffa921"
                      color="#fff"
                      bold="600"
                      onClick={() => handleDeliveryNow()}
                      pd="0.6rem 2rem"
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </Section>
        </Container>

        <Modal
          open={openAddressModal}
          onClose={handleCloseAddressModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalAddress>


            {showAddAddressBook == 1 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleCloseAddressModal()}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <div className={classes.thePadding}>
                    <Paragraph bold="700" margin="0 0 1rem 0" style={{ fontSize: '1.1rem' }} >SAVED ADDRESS BOOK</Paragraph>
                    <Link className={classes.theFlexCreateBtn} onClick={() => handleAddAddressBook(2)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Bookmark style={{ color: '#000', marginRight: 4 }} />
                        <div>
                          <Paragraph color="#000" bold="600">Create Address Book</Paragraph>
                          <Paragraph
                            color="#878787"
                            bold="400"
                            style={{ fontSize: 12 }}
                          >
                            Save your favourite address
                          </Paragraph>
                        </div>
                      </div>
                      <ArrowForwardIos style={{ fontSize: 16, color: '#000' }} />
                    </Link>

                    {userAddressBook.map(item => (
                      <Link className={classes.theFlexCreateBtn_}   >
                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleChooseAddressBook(item.user_address_id)}>
                          <Room style={{ color: '#000', marginRight: 4 }} />
                          <div>
                            <Paragraph color="#000" bold="600">{item.title}</Paragraph>
                            <Paragraph
                              color="#878787"
                              bold="400"
                              style={{ fontSize: 12, width: '95%' }}
                            >
                              {item.address}
                            </Paragraph>
                          </div>
                        </div>
                        <Link onClick={() => getUserAddressID(item.user_address_id)}>
                          <Edit style={{ fontSize: 16, color: '#000' }} />
                        </Link>
                      </Link>
                    ))}

                  </div>
                </ModalBody>

              </>
            )}

            {showAddAddressBook == 2 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph bold="700" margin="0 0 1rem 0" style={{ fontSize: '1.2rem' }}>ENTER YOUR ADDRESS</Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeTextInput}
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeGeocode}
                    margin="0"
                  />
                  {isSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isSearching == false && geocodeAddressList.length != 0 && (
                    <>
                      {geocodeAddressList.map((item) => (
                        <Link
                          className={
                            item.address != addressData.address
                              ? classes.theDefaultAddress
                              : classes.theSelectedAddress
                          }
                          onClick={() => handleChooseAddress(item)}
                        >
                          {item.name != "" && (
                            <Paragraph
                              color="#000"
                              bold="700"
                              mb="4"
                              size="15">
                              {item.name}
                            </Paragraph>
                          )}
                          <Paragraph color="#000">{item.formatted_address}</Paragraph>
                        </Link>
                      ))}
                    </>
                  )}
                </ModalBody>
                <br />
              </>
            )}


            {showAddAddressBook == 3 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(2)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph bold="700" margin="0 0 1rem 0" style={{ fontSize: '1.2rem' }}>ADD ADDRESS BOOK</Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeAddress}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeAddress}

                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Address"
                    name="address"
                    label="Address"
                    color="#1e1e1e"
                    bold="600"
                    value={addressBook.address}
                    multiline
                    rows={4}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    color="#1e1e1e"
                    bold="600"
                    value={addressBook.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    color="#1e1e1e"
                    bold="600"
                    value={addressBook.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    color="#1e1e1e"
                    bold="600"
                    value={addressBook.state}
                  />

                  <Button
                    label="Submit"
                    bg="#ffa921"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleSaveAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}


            {showAddAddressBook == 4 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph bold="700" margin="0 0 1rem 0" style={{ fontSize: '1.2rem' }}>EDIT ADDRESS BOOK</Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.title}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.unit}

                  />

                  <Paragraph color="#495057" bold="600" margin="1rem 0" style={{ fontSize: '1rem' }}>Address</Paragraph>
                  <Link className={classes.theCurrentAddress} onClick={() => handleAddAddressBook(5)}>
                    {userAddressBookData.address}
                  </Link>

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    color="#1e1e1e"
                    bold="600"
                    value={userAddressBookData.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    color="#1e1e1e"
                    bold="600"
                    value={userAddressBookData.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    color="#1e1e1e"
                    bold="600"
                    value={userAddressBookData.state}
                  />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      onChange={handleChangeCheck}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      name="is_default"
                      checked={isDefault}
                      value={userAddressBookData.is_default}
                    />
                    <Paragraph bold="600" color="#1e1e1e">Default Address</Paragraph>
                  </div>
                  <br />
                  <Button
                    label="Delete Address"
                    bg="#dc3545"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleDeleteAddress()}
                  />
                  <br />
                  <Button
                    label="Submit"
                    bg="#ffa921"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleEditAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}

            {showAddAddressBook == 5 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(4)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph bold="700" margin="0 0 1rem 0" style={{ fontSize: '1.2rem' }}>ENTER YOUR ADDRESS</Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeTextInput}
                    color="#1e1e1e"
                    bold="600"
                    onChange={handleChangeGeocode}
                    margin="0"
                  />
                  {isSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isSearching == false && geocodeAddressList.length != 0 && (
                    <>
                      {geocodeAddressList.map((item) => (
                        <Link
                          className={
                            item.address != addressData.address
                              ? classes.theDefaultAddress
                              : classes.theSelectedAddress
                          }
                          onClick={() => handleChooseAddressEdit(item)}
                        >
                          {item.name != "" && (
                            <Paragraph
                              color="#000"
                              bold="700"
                              mb="4"
                              size="15">
                              {item.name}
                            </Paragraph>
                          )}
                          <Paragraph color="#000">{item.formatted_address}</Paragraph>
                        </Link>
                      ))}
                    </>
                  )}
                </ModalBody>
                <br />
              </>
            )}



          </ModalAddress>


        </Modal>
      </LoadScript>
    </React.Fragment>
  );
}
