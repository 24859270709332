import React, { useState } from "react";
import { Container, Grid, Select, MenuItem } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import banner from "../../assets/images/banner.png";
import useStyles, {
	FlexContainer,
	Icon,
	SubCategoryList,
	FormInputSelect,
} from "./styles";
import {
	get_sub_category,
	get_banner_page,
	get_subcategory_banner_page,
	filter_product,
	get_one_hour_delivery
} from "../../api/API";
import { useHistory } from "react-router";
import SmoothImage from "react-smooth-image";

const ExpressProductListScreen = (props) => {
	const classes = useStyles();

	console.log(useHistory())
	let { ExpressProductListScreen } = useLocation();

	React.useEffect(() => { }, [ExpressProductListScreen]);

	// console.log(useHistory)
	// API
	const history = useHistory();
	const [isBusy, setIsBusy] = React.useState(true);
	const [sortState, setSortState] = React.useState(0);
	const [subCategory, setSubCategory] = React.useState([]);
	const [subSubCategory, setSubSubCategory] = React.useState([]);
	const [product, setProduct] = React.useState([]);
	const [selectionPrice, setSelectionPrice] = React.useState([]);

	// const getProduct = () => {
	// 	let postparam = {
	// 		category_id: category_id,
	// 		type: "products",
	// 		// is_sort: sortState,
	// 	};

	// 	get_sub_category(postparam).then((json) => {
	// 		setProduct(json.data[0].products);
	// 		// setIsBusy(true);

	// 		var previousOffset = window.localStorage.getItem("scrollYCategory");
	// 		var previousCategory = window.localStorage.getItem("current_category");

	// 		if (
	// 			previousOffset != undefined &&
	// 			previousCategory != undefined &&
	// 			previousCategory == category_id
	// 		) {
	// 			// setTimeout(() => {
	// 			window.scrollTo(0, previousOffset);
	// 			// }, 3000);
	// 		} else {
	// 			window.scrollTo(0, 0);
	// 		}
	// 	});
	// };

	const [shopID, setShopID] = React.useState("");

	const getProduct = () => {
		let postparam = {
			latitude: props.history.location.state.latitude,
			longitude: props.history.location.state.longitude,
		};

		get_one_hour_delivery(postparam).then(json => {
			if (json.status) {
				setProduct(json.data);
				setShopID(json.shop_id);
				// setProductID()
				setIsBusy(true);

				window.localStorage.setItem("shopID", parseFloat(json.shop_id))

			} else {
				alert(json.message);
				history.push("/expressdelivery");
			}

		});
	};

	console.log(shopID)

	// const handleChange = (event) => {
	// 	console.log("select", event.target.value);
	// 	setSortState(event.target.value);

	// 	let postparam = {
	// 		category_id: category_id,
	// 		type: "products",
	// 		is_sort: event.target.value,
	// 	};

	// 	get_sub_category(postparam).then((json) => {
	// 		setProduct(json.data[0].products);
	// 		// setIsBusy(true);

	// 		var previousOffset = window.localStorage.getItem("scrollYCategory");
	// 		var previousCategory = window.localStorage.getItem("current_category");

	// 		if (
	// 			previousOffset != undefined &&
	// 			previousCategory != undefined &&
	// 			previousCategory == category_id
	// 		) {
	// 			// setTimeout(() => {
	// 			window.scrollTo(0, previousOffset);
	// 			// }, 3000);
	// 		} else {
	// 			window.scrollTo(0, 0);
	// 		}
	// 	});
	// };

	// const [loacation, setLocation] = React.useState({
	// 	latitude: props.history.location.state.latitude,
	// 	longitude: props.history.location.state.longitude
	// })


	React.useEffect(() => {
		// setSubCategory([]);
		// setProduct([]);
		// console.log(props);
		getProduct();
		setIsBusy(false);
	}, []);


	const OpenShopProdDetail = (product_id) => {
		history.push({
			pathname: "/productdetail/" + product_id,
			state: {
				shop_id: shopID,
			}
		});


	}



	return (
		<React.Fragment>
			{/* <StickyHeader /> */}
			<Navbar />
			{isBusy == false ? (
				<>
					<Loader
						type="TailSpin"
						color="#ff8001"
						height={80}
						// timeout={3000}
						width={80}
					/>
				</>
			) : (
				<>
					<Container>
						<Section>
							<div className={classes.theProductContainer}>
								{/* <div className={classes.theProductFilter}>
									<FormInputSelect>
										<Select
											onChange={handleChange}
											displayEmpty
											inputProps={{ "aria-label": "Without label" }}
											name="is_sort"
											value={sortState}
										>
											<MenuItem value={0}>Default</MenuItem>
											<MenuItem value={1}>Price Low To High</MenuItem>
											<MenuItem value={2}>Price Hight To Low</MenuItem>
										</Select>
									</FormInputSelect>
								</div> */}
								<Grid container spacing={3}>
									{product.map((item) => (
										<Grid xs={6} sm={4} md={3} key={item.product_id}>
											<div
												className={classes.theProductCard}
												onClick={() => OpenShopProdDetail(item.product_id)}
											// to={`../productdetail/${item.product_id}`}
											>
												<div className={classes.theProductIMGMobile}>
												{item.max_stock == "" ? (
														<>
															{item.stock == 0 ? (
																<div className={classes.theOutofStock}>
																	<Paragraph
																		size="500"
																		color="#fff"
																		bold="600"
																		center
																	>
																		Out Of Stock
																	</Paragraph>
																</div>
															) : (
																<></>
															)}
														</>
													) : (
														<>
															{item.max_stock == 0 ? (
																<div className={classes.theOutofStock}>
																	<Paragraph
																		size="500"
																		color="#fff"
																		bold="600"
																		center
																	>
																		Out Of Stock
																	</Paragraph>
																</div>
															) : (
																<></>
															)}
														</>
													)}

													<SmoothImage
														imageStyles={{
															width: "100%",
															height: "150px",
															objectFit: "contain",
															maxHeight: "100%",
															top: "50%",
															left: "50%",
															transform: "translate(-50%,-50%)",
														}}
														src={item.image}
													/>
												</div>

												<div className={classes.theProductIMG}>
													{item.max_stock == "" ? (
														<>
															{item.stock == 0 ? (
																<div className={classes.theOutofStock}>
																	<Paragraph
																		size="500"
																		color="#fff"
																		bold="600"
																		center
																	>
																		Out Of Stock
																	</Paragraph>
																</div>
															) : (
																<></>
															)}
														</>
													) : (
														<>
															{item.max_stock == 0 ? (
																<div className={classes.theOutofStock}>
																	<Paragraph
																		size="500"
																		color="#fff"
																		bold="600"
																		center
																	>
																		Out Of Stock
																	</Paragraph>
																</div>
															) : (
																<></>
															)}
														</>
													)}

													<SmoothImage
														imageStyles={{
															width: "100%",
															height: "150px",
															objectFit: "contain",
															top: "50%",
															left: "50%",
															transform: "translate(-50%,-50%)",
														}}
														src={item.image}
													/>
												</div>

												<div className={classes.theDetail}>
													{item.single_price == null ? (
														<Paragraph
															size="300"
															color="#f4b14a"
															bold="600"
															margin="0 0 .4rem 0"
														>
															RM {parseFloat(item.pricing).toFixed(2)}
														</Paragraph>
													) : (
														<Paragraph
															size="300"
															color="#f4b14a"
															bold="600"
															margin="0 0 .4rem 0"
														>
															RM {parseFloat(item.single_price).toFixed(2)}
														</Paragraph>
													)}
													{item.ori_price != "0.00" && (
														<div className={classes.theFlex}>
															<Paragraph
																size="300"
																color="#878787"
																textDecoration="line-through"
																bold="600"
																margin="0 1rem 0 0"
															>
																RM{parseFloat(item.ori_price).toFixed(2)}
															</Paragraph>
														</div>
													)}
													<Paragraph
														size="300"
														color="#878787"
														bold="600"
														margin="0 0 .4rem 0"
													>
														{item.name}
													</Paragraph>
												</div>
											</div>
										</Grid>
									))}
								</Grid>
							</div>
						</Section>
					</Container>
				</>
			)}
		</React.Fragment>
	);
};

export default ExpressProductListScreen;
