import React from "react";
import {
  Container,
  Grid,
  TextField,
  Modal,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Add, ChevronRight, Close, Edit, Delete } from "@material-ui/icons";

import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
  ModalAddress,
  ModalHeader,
  FlexIcon,
  ModalBody,
  FormBox,
  FormInputSelect,
} from "./styles";

import account from "../../assets/images/account.svg";
import pin from "../../assets/images/pin.svg";
import bag from "../../assets/images/bag.svg";
import gift from "../../assets/images/gift.svg";
import { Home, Mail, Phone } from "@material-ui/icons";
// import "./Addresses.css";

// import {
//   get_user_address,
//   get_state,
//   user_address,
//   edit_user_address,
//   delete_user_address,
//   get_user_profile,
// } from "../../api/API";
import useStyles from "./styles";

export default function Addresses() {
  const history = useHistory();
  const classes = useStyles();

  let { Addresses } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Addresses]);

  let { user_address_id } = useParams();

  // GET PROFILE
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    // let postparam = {
    //   token: token,
    // };
    // get_user_profile(postparam).then((json) => {
    //   setUser(json.data);
    // });
  }, []);

  // GET USER ADDRESS

  const [token, setToken] = React.useState("");
  const [address, setAddress] = React.useState([]);

  React.useEffect(() => {
    // let postparam = {
    //   token: token,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddress(json.data);
    // });
  }, []);

  // GET STATE

  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    // get_state().then((json) => {
    //   setState(json.data);
    // });
  }, []);

  // MODAL ADD ADDRESS

  const [openaddmodal, setOpenAddModal] = React.useState(false);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  // ADD ADDRESS

  const [addressData, setAddressData] = React.useState({
    address: "",
    postcode: "",
    city: "",
    country: "Malaysia",
    state_id: "",
  });

  const { is_active } = addressData;

  const handleChange = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.value,
    });
    setAddressEditData({
      ...addressEditData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.checked,
    });
    setEditCheck({ ...editCheck, [event.target.name]: event.target.checked });
  };

  const handleSubmitAddress = () => {
    // let res = "0";
    // if (addressData.is_active == true) {
    //   res = "1";
    // }
    // if (
    //   addressData.address.length == 0 ||
    //   addressData.state_id.length == 0 ||
    //   addressData.postcode.length == 0 ||
    //   addressData.city.length == 0 ||
    //   addressData.country.length == 0
    // ) {
    //   alert("All Field Required");
    // } else {
    //   let postparam = {
    //     ...addressData,
    //     is_active: res,
    //   };
    //   user_address(postparam).then((json) => {
    //     if (json.status) {
    //       alert("Added Successful");
    //     } else {
    //     }
    //   });
    // }
  };

  // MODAL EDIT ADDRESS

  const [openeditmodal, setOpenEditModal] = React.useState(false);
  const [modalID, setModalID] = React.useState(false);
  const [editCheck, setEditCheck] = React.useState(false);

  const handleOpenEditModal = (modalID, event) => {
    // setOpenEditModal(true);
    // let postparam = {
    //   token: token,
    //   user_address_id: modalID,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddressEditData(json.data[0]);
    //   setModalID(modalID);
    //   console.log(modalID);
    //   if (json.data[0].is_active == "1") {
    //     console.log("true", addressEditData);
    //     setEditCheck(true);
    //   } else {
    //     setEditCheck(false);
    //   }
    // });
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  // EDIT ADDRESS

  const [addressEditData, setAddressEditData] = React.useState({});

  React.useEffect(() => {
    getAddressData();
  }, []);

  const getAddressData = (modalID) => {
    // let postparam = {
    //   token: token,
    //   user_address_id: modalID,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddressEditData(json.data);
    //   console.log(JSON.stringify(json.data));
    // });
  };

  const handeleEditAddress = () => {
    // let res = "0";
    // if (editCheck.is_active == true) {
    //   res = "1";
    // }
    // let postparam = {
    //   ...addressEditData,
    //   is_active: res,
    // };
    // edit_user_address(postparam).then((json) => {
    //   if (json.status) {
    //     alert("Edit Address Successful");
    //   }
    // });
  };

  // DELETE USER ADDRESS

  const [deleteAddress, setDeleteAddress] = React.useState({});

  const handleDeleteAddress = (user_address_id) => {
    // let postparam = {
    //   user_address_id: user_address_id,
    // };
    // delete_user_address(postparam).then((json) => {
    //   if (json.status) {
    //     window.confirm("Are you sure want to delete this address?");
    //     setDeleteAddress(json.data);
    //     window.location.reload();
    //   }
    // });
  };

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      <Container>
        <Section>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <SideBarContainer>
                <SideBarBox>
                  <Icon>
                    <Circle>
                      <img className={classes.theIMG} src={account} />
                    </Circle>
                  </Icon>
                  {/* <p className="name">{user.name}</p> */}
                  <p className="name">LOREM IPSUM</p>
                </SideBarBox>
                <SiderBarList>
                  <NavFlexLink to="/account">
                    <SmallIcon>
                      <SmallIconIMG src={account} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      My Account
                    </Paragraph>
                  </NavFlexLink>
                  {/* <NavFlexLink to="/addresses">
                    <ActiveBorder />
                    <SmallIcon>
                      <SmallIconIMG src={pin} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Address
                    </Paragraph>
                  </NavFlexLink> */}
                  <NavFlexLink to="/orders">
                    <SmallIcon className="theIcon">
                      <SmallIconIMG src={bag} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Orders
                    </Paragraph>
                  </NavFlexLink>
                </SiderBarList>
              </SideBarContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Card>
                <div className={classes.theHeaderProfile}>
                  <Paragraph size="600" color="#7f222c" bold="600">
                    Address
                  </Paragraph>
                  <Link
                    className={classes.thFlexAdd}
                    onClick={() => handleOpenAddModal()}
                  >
                    <Add className={classes.theIcon} />
                    <Paragraph color="#1e1e1e" bold="600">
                      Add Address
                    </Paragraph>
                  </Link>
                </div>
                <div className="theForm">
                  <Grid container spacing={3}>
                    {address.map((item) => (
                      <Grid item xs={12} sm={6} md={6}>
                        <div className={classes.theCardAddress}>
                          <div className={classes.theFlex}>
                            <Paragraph
                              color="#1e1e1e"
                              bold="600"
                              margin="0 1rem 0.6rem 0"
                            >
                              {item.name}
                            </Paragraph>
                            <Paragraph
                              color="#1e1e1e"
                              bold="600"
                              margin="0 1rem 0.6rem 0"
                            >
                              {item.contact}
                            </Paragraph>
                          </div>
                          <Paragraph
                            color="1e1e1e"
                            bold="600"
                            margin="0 0 1rem 0"
                          >
                            {item.address}, {item.postcode}, {item.city},
                            {item.state}, {item.country}
                          </Paragraph>
                          {item.is_active == 1 ? (
                            <Paragraph size="200" color="#1e1e1e">
                              *Default delivery address
                            </Paragraph>
                          ) : (
                            <></>
                          )}
                          <div className={classes.theAction}>
                            <Link
                              className={classes.theActionLink}
                              onClick={() =>
                                handleDeleteAddress(item.user_address_id)
                              }
                            >
                              <Delete className={classes.theActionIcon} />
                            </Link>
                            <Link
                              className={classes.theActionLink}
                              onClick={() =>
                                handleOpenEditModal(item.user_address_id)
                              }
                            >
                              <Edit className={classes.theActionIcon} />
                            </Link>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Modal
        open={openaddmodal}
        onClose={handleCloseAddModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalAddress>
          <ModalHeader>
            <FlexIcon onClick={() => handleCloseAddModal()}>
              <Close className={classes.theActionLink} />
            </FlexIcon>
          </ModalHeader>
          <ModalBody>
            <div className={classes.theForm}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={6}>
                  <FormBox>
                    {/* <div className="theFormGroup">
                      <p className="label">Full Name</p>
                      <TextField
                        className="theFormInput"
                        placeholder="Full Name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div> */}

                    <FormInput
                      bg="#fff"
                      placeholder="Address"
                      name="address"
                      label="Address"
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChange}
                    />

                    <FormInput
                      bg="#fff"
                      placeholder="Postcode"
                      name="postcode"
                      label="Postcode"
                      color="#1e1e1e"
                      bold="600"
                      type="number"
                      onChange={handleChange}
                    />
                  </FormBox>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <FormBox>
                    {/* <div className="theFormGroup">
                      <p className="label">Phone Number</p>
                      <TextField
                        className="theFormInput"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </div> */}

                    <FormInput
                      bg="#fff"
                      placeholder="City"
                      name="city"
                      label="City"
                      color="#1e1e1e"
                      bold="600"
                      type="number"
                      onChange={handleChange}
                    />

                    <div className="theFormGroup">
                      <Paragraph
                        size="300"
                        color="#1e1e1e"
                        bold="600"
                        margin="1rem 0"
                      >
                        State
                      </Paragraph>
                      <FormInputSelect>
                        <Select
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="state_id"
                        >
                          <MenuItem disabled>Please Select Your State</MenuItem>
                          {state.map((item) => (
                            <MenuItem key={item.state_id} value={item.state_id}>
                              {item.state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormInputSelect>
                    </div>
                    <div className="theFormGroup">
                      <div className={classes.theRow}>
                        <Checkbox
                          onChange={handleChangeCheck}
                          // onChange={handleChange}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          name="is_active"
                          checked={is_active}
                          // value={changeText}
                        />
                        <Paragraph color="#1e1e1e" bold="600">
                          Set Primary Address
                        </Paragraph>
                      </div>
                    </div>
                    <div className={classes.theSubmit}>
                      <Button
                        label="Submit"
                        bold="600"
                        bg="#7f222c"
                        color="#fff"
                        onClick={() => handleSubmitAddress()}
                      />
                    </div>
                  </FormBox>
                </Grid>
              </Grid>
            </div>
          </ModalBody>
        </ModalAddress>
      </Modal>
      <Modal
        open={openeditmodal}
        onClose={handleCloseEditModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // setModalID={modalID}
      >
        <ModalAddress>
          <ModalHeader>
            <FlexIcon onClick={() => handleCloseEditModal()}>
              <Close className={classes.theActionLink} />
            </FlexIcon>
          </ModalHeader>
          <ModalBody>
            <div className={classes.theForm}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={6}>
                  <FormBox>
                    {/* <div className="theFormGroup">
                      <p className="label">Full Name</p>
                      <TextField
                        className="theFormInput"
                        placeholder="Full Name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div> */}

                    <FormInput
                      bg="#fff"
                      placeholder="Address"
                      name="address"
                      label="Address"
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChange}
                      value={addressEditData.address}
                    />

                    <FormInput
                      bg="#fff"
                      placeholder="Postcode"
                      name="postcode"
                      label="Postcode"
                      color="#1e1e1e"
                      bold="600"
                      type="number"
                      onChange={handleChange}
                      type="number"
                      value={addressEditData.postcode}
                    />
                  </FormBox>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <FormBox>
                    {/* <div className="theFormGroup">
                      <p className="label">Phone Number</p>
                      <TextField
                        className="theFormInput"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </div> */}

                    <FormInput
                      bg="#fff"
                      placeholder="City"
                      name="city"
                      label="City"
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChange}
                      value={addressEditData.city}
                    />

                    <div className="theFormGroup">
                      <Paragraph
                        size="300"
                        color="#1e1e1e"
                        bold="600"
                        margin="1rem 0"
                      >
                        State
                      </Paragraph>
                      <FormInputSelect>
                        <Select
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="state_id"
                          value={addressEditData.state_id || ""}
                        >
                          <MenuItem disabled>Please Select State</MenuItem>
                          {state.map((item) => (
                            <MenuItem key={item.state_id} value={item.state_id}>
                              {item.state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormInputSelect>
                    </div>
                    <div className="theFormGroup">
                      <div className={classes.theRow}>
                        <Checkbox
                          onChange={handleChangeCheck}
                          // onChange={handleChange}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          name="is_active"
                          checked={editCheck}
                          value={addressEditData.is_active}
                        />
                        <Paragraph color="#1e1e1e" bold="600">
                          {addressEditData.is_active}
                        </Paragraph>
                        <Paragraph color="#1e1e1e" bold="600">
                          Set as Default Delivery Address
                        </Paragraph>
                      </div>
                    </div>
                    <div className={classes.theSubmit}>
                      <Button
                        label="Submit"
                        bold="600"
                        bg="#7f222c"
                        color="#fff"
                        onClick={() => handleSubmitAddress()}
                      />
                    </div>
                  </FormBox>
                </Grid>
              </Grid>
            </div>
          </ModalBody>
        </ModalAddress>
      </Modal>
    </React.Fragment>
  );
}
