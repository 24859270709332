import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FormControl, TextField } from "@material-ui/core";
import { md } from "../../styles/responsive";

export const config = {
  root: {},
  theCreateBtn: {
    position: "relative",
    margin: "1rem 0",
    textAlign: "center",
  },
  theFlex: {
    alignItems: "center",
    display: "flex",
  },
  theNavLink: {
    color: "#7f222c",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    display: "flex",
    margin: "1rem 0",
  },

  padd: {
    position: "relative",
    //   width:"100%",
    margin: "0 auto",
    ...md("sm", {
      width: "60%",
    }),
    ...md("xmd", {
      width: "40%",
    }),
  },
  theLabel: {
    color: "#495057",
    fontSize: "1rem",
    fontWeight: "600",
  },

  theBox: {
    position: "relative"
  },

  theNumber: {
    position: "absolute",
    top: "4px",
    left: "10px",
    fontSize: "17px",
    padding: "6px 0 7px",
    display: "block"
  },
  theGoogleLogin: {
    position: "relative",
    width: "100% !important",
    backgroundColor: "rgb(66, 133, 244) !important",
    color: "rgb(255, 255, 255) !important",

    "& span": {
      display: "block",
      textAlign: "center",
      color: "#fff",
      width: "100%",
    }
  },
};
export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;


export const FormBox = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
`;

export const FormInputSelect = styled(FormControl)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: ${(props) => props.textcolor};
  background: ${(props) => props.bg};
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;


// export const PhoneInput = styled(TextField)`
//   display: block;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #1e1e1e;
//   background-color: #eeeeee;
//   background-clip: padding-box;
//   border: 1px solid #eaeaea;
//   border-radius: 6px;
//   transition: border-color 0.15s ease-in-out,
//     box-shadow 0.15s ease-in-out;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   text-indent: 14px;

//   .MuiInput-underline:before {
//     border-bottom: none !important;
//   }
//   .MuiInput-underline:after {
//     border-bottom: none !important;
//   }
//   .MuiInputBase-root {
//     width: 90%;
//   }
//   .MuiInputBase-root {
//     font-weight: 500 !important;
//   }
//   .MuiInput-underline {
//     &:before {
//       position: relative !important;
//     }
//   }
// `;

export const PhoneInput = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  /* border-radius: 6px; */
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-indent: 14px; */
  width: 100%;
  
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 90%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const CustomFormControl = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  /* border-left: 2px solid #e5e5ea;
  border-top: 2px solid #e5e5ea;
  border-bottom: 2px solid #e5e5ea; */
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
`;

export default makeStyles(config);
