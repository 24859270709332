import React from "react";
import { Route, useHistory } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import SubCategoryPage from "./pages/SubCategory/SubCategory";
import ProductPage from "./pages/Product/Product";
import ProductDetailPage from "./pages/ProductDetail/ProductDetail";
import AccountPage from "./pages/Account/Account";
import AddressesPage from "./pages/Addresses/Addresses";
import OrdersPage from "./pages/Orders/Orders";
import OrderDetailPage from "./pages/OrderDetail/OrderDetail";
import CheckoutPage from "./pages/Checkout/Checkout";
import ArticlePage from "./pages/Article/Article";
import ArticleDetailPage from "./pages/ArticleDetail/ArticleDetail";
import RegisterPage from "./pages/Register/Register";
import LoginPage from "./pages/Login/Login";
import PaymentPage from './pages/Payment/Payment';
import Sub_SubCategoryPage from "./pages/Sub_SubCategory/Sub_SubCategory";
import SearchProductPage from "./pages/SearchProduct/SearchProduct";
import ExpressDeliveryPage from "./pages/ExpressDelivery/ExpressDelivery"
import ExpressProductListPage from "./pages/ExpressProductList/ExpressProductList"

export default function Routes() {
  return (
    <div>

      <Route
        exact
        path="/"
        render={(props) => <HomePage {...props}></HomePage>}
      />
      <Route

        path="/subcategory/:category_id"
        render={(props) => <SubCategoryPage {...props}></SubCategoryPage>}
      />
      <Route

        path="/product/:category_id"
        render={(props) => <ProductPage {...props}></ProductPage>}
      />
      <Route

        path="/productdetail/:product_id"
        render={(props) => <ProductDetailPage {...props}></ProductDetailPage>}
      />
      <Route

        path="/account"
        render={(props) => <AccountPage {...props}></AccountPage>}
      />
      <Route

        path="/addresses"
        render={(props) => <AddressesPage {...props}></AddressesPage>}
      />
      <Route

        path="/orders"
        render={(props) => <OrdersPage {...props}></OrdersPage>}
      />
      <Route

        path="/orderdetail/:orders_id"
        render={(props) => <OrderDetailPage {...props}></OrderDetailPage>}
      />
      <Route

        path="/checkout"
        render={(props) => <CheckoutPage {...props}></CheckoutPage>}
      />

      <Route

        path="/articledetail/:blog_id"
        render={(props) => <ArticleDetailPage {...props}></ArticleDetailPage>}
      />
      <Route

        path="/article/product/:product_id"
        render={(props) => <ProductDetailPage is_fast_delivery={1} {...props}></ProductDetailPage>}
      />
      <Route

        path="/article"
        render={(props) => <ArticlePage {...props}></ArticlePage>}
      />
      <Route

        path="/register"
        render={(props) => <RegisterPage {...props}></RegisterPage>}
      />
      <Route

        path="/login"
        render={(props) => <LoginPage {...props}></LoginPage>}
      />
      <Route

        path="/sub_subcategory/:category_id"
        render={(props) => <Sub_SubCategoryPage {...props}></Sub_SubCategoryPage>}
      />
      <Route

        path="/payment/:type"
        render={(props) => <PaymentPage {...props}></PaymentPage>}
      />
      <Route

        path="/searchproduct/:query"
        render={(props) => <SearchProductPage {...props}></SearchProductPage>}
      />
      <Route

        path="/expressdelivery"
        render={(props) => <ExpressDeliveryPage {...props}></ExpressDeliveryPage>}
      />
      <Route

        path="/expressproductlist"
        render={(props) => <ExpressProductListPage {...props}></ExpressProductListPage>}
      />
    </div>
  );
}
