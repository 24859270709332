import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { md } from "../../styles/responsive";

const config = {
    theBox: {
        width: "500px",
        left: 0
    },
};

export default makeStyles(config);
