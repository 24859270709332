import React from "react";
import {
  Container,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import useStyles, { PhoneInput, CustomFormControl } from "./styles";
import { register, get_otp } from "../../api/API";

export default function Register() {
  const classes = useStyles();
  const history = useHistory();
  let { Register } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Register]);

  // GET OTP

  const [requestOTP, setRequestOTP] = React.useState({
    contact: "",
  });

  const [initialTime, setInitialTime] = React.useState(0);

  const [startTimer, setStartTimer] = React.useState(false);

  const handleGetOTP = () => {
    // requestOTP.mobile = value;

    get_otp(requestOTP).then((json) => {
      if (json.status) {
        alert("Successful! Please check your phone for your OTP code");
        setInitialTime(60);
        setStartTimer(true);
      }else{
        alert(json.message);
      }
    });
  };

  React.useEffect(() => {
    if (initialTime > 0) {
      setTimeout(() => {
        console.log("startTime, ", initialTime);
        setInitialTime(initialTime - 1);
      }, 1000);
    }

    if (initialTime === 0 && startTimer) {
      console.log("done");
      setStartTimer(false);
    }
  }, [initialTime, startTimer]);

  // REGISTER

  const [registerData, setRegisterData] = React.useState({
    name: "",
    age: "",
    contact: "",
    password: "",
    password2: "",
    otp: ""
  });

  const handleChange = (event) => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
    setRequestOTP({
      ...requestOTP,
      [event.target.name]: event.target.value,
    });
  };

  const handleRegister = () => {
    if (
      registerData.name.length == 0 ||
      registerData.age.length == 0 ||
      registerData.contact.length == 0 ||
      registerData.password.length == 0 ||
      registerData.password2.length == 0 ||
      registerData.otp.length == 0
    ) {
      alert("All Field Required");
      return;
    }

    if (registerData.password !== registerData.password2) {
      alert("Passwords do not match");
      return;
    }

    register(registerData).then((json) => {
      if (json.status) {
        alert("Register Successful");
        history.push("/login");
      } else {
        // alert(json.message);
      }
    });
  };



  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      <Container>
        <Section>
          <Card className={classes.padd}>
            <div className="theForm">
              <div className="theLogo">{/* <img src={clover} /> */}</div>
              <Paragraph
                style={{ fontSize: "1.4rem" }}
                color="#7f222c"
                bold="600"
                margin="0 0 1rem 0"
                center
              >
                Register
              </Paragraph>

              <FormInput
                bg="#eeeeee"
                placeholder="Full Name"
                name="name"
                label="Full Name"
                color="#1e1e1e"
                bold="600"
                onChange={handleChange}
              />

              <FormInput
                bg="#eeeeee"
                placeholder="Date of Birth"
                name="age"
                label="Date of Birth"
                color="#1e1e1e"
                bold="600"
                type="date"
                onChange={handleChange}
              />

              <Paragraph color="#1e1e1e" bold="600" margin="0 0 1rem 0" style={{fontSize: "1rem"}}>Contact Number</Paragraph>
              
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderLeft: "1px solid #d7d7d7",
                    borderTop: "1px solid #d7d7d7",
                    borderBottom: "1px solid #d7d7d7",
                    backgroundColor: "#f5f7fa",
                    padding: "10px 5px",
                    color: "#909399",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    width: "50px",
                    textAlign: "center",
                    fontWeight: "400",
                  }}

                >
                  +60
                </div>
                <PhoneInput
                  placeholder="Contact Number"
                  name="contact"
                  onChange={handleChange}
                  type="number"
                />

              </div>

              <FormInput
                bg="#eeeeee"
                placeholder="Password"
                name="password"
                label="Password"
                color="#1e1e1e"
                bold="600"
                type="password"
                onChange={handleChange}
              />

              <FormInput
                bg="#eeeeee"
                placeholder="Confirm Password"
                name="password2"
                label="Confirm Password"
                color="#1e1e1e"
                bold="600"
                type="password"
                onChange={handleChange}
              />

              <p className={classes.theLabel}>SMS Verification Code</p>

              {initialTime != 0 ? (
                <div style={{ display: "flex" }}>
                  <CustomFormControl
                    placeholder="OTP Code"
                    name="otp"
                    onChange={handleChange}
                  />
                  <Link
                    style={{
                      borderRight: "1px solid #d7d7d7",
                      borderTop: "1px solid #d7d7d7",
                      borderBottom: "1px solid #d7d7d7",
                      backgroundColor: "#f5f7fa",
                      padding: "10px 20px 10px 20px",
                      color: "#909399",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "165px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    {initialTime} seconds
                  </Link>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <CustomFormControl
                    placeholder="OTP Code"
                    name="otp"
                    onChange={handleChange}
                  />
                  <Link
                    style={{
                      borderRight: "1px solid #d7d7d7",
                      borderTop: "1px solid #d7d7d7",
                      borderBottom: "1px solid #d7d7d7",
                      backgroundColor: "#f5f7fa",
                      padding: "10px 20px 10px 20px",
                      color: "#909399",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "165px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                    onClick={() => handleGetOTP()}
                  >
                    GET CODE
                  </Link>
                </div>
              )}

              <br />

              <div className={classes.theCreateBtn}>
                <Button
                  bg="#7f222c"
                  color="#fff"
                  bold="600"
                  label="Create Account"
                  onClick={() => {
                    handleRegister();
                  }}
                />
                <br />
                <Button
                  label="Back To Login"
                  color="#fff"
                  bg="#264653"
                  bold="600"
                  to="/login"
                  style={{ padding: "0.6rem 2rem" }}
                />
                <p>
                  By clicking this button, you agree to our
                  <Link className={classes.theNavLink}> Terms</Link>,
                  <Link className={classes.theNavLink}> Privacy Policy</Link>
                  &nbsp;and
                  <Link className={classes.theNavLink}> Security Policy</Link>.
                </p>
              </div>
            </div>
          </Card>
        </Section>
      </Container>
    </React.Fragment>
  );
}
