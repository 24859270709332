import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import banner from "../../assets/images/banner.png";

import useStyles, {
  CardArticleContainer,
  ArticleIMGContainer,
  IMGArticle,
  Tag,
  Span,
  FlexDetail,
} from "./styles";

const Article = () => {
  const classes = useStyles();

  // let { Article } = useLocation();
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [Article]);

  return (
    <React.Fragment>
      
    </React.Fragment>
  );
};

export default Article;
