import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FormControl } from "@material-ui/core";
import { md } from "../../styles/responsive";

export const config = {
  root: {},
  theHeaderProfile: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "2rem",
  },
  theForm: {
    position: "relative",
    marginBottom: "1rem",
  },

  theIMG: {
    width: "70%",
    margin: "0 auto",
  },

  theCardOrdersList: {
    position: "relative",
    background: "#fcfcfc",
    padding: "0 1rem",
    borderLeft: "6px solid #7f222c",
    marginBottom: "2rem",

    ...md("md", {
      padding: "0rem 2rem",
    }),
  },

  theBoxOrder: {
    position: "relative",
  },

  theSpan: {
    marginLeft: "1rem",
    fontWeight: "600",
  },

  price: {
    color: "#fa4c4e",
    fontSize: "1rem",
    fontWeight: "700",
    marginLeft: "1rem",
  },

  theCardBorder: {
    position: "relative",
    padding: "0.2rem 1rem",
    border: "1px dashed #7f222c",
    margin: "1rem 0",
  },

  theCardDetail: {
    position: "relative",
  },

  theAction: {
    position: "relative",
    width: "100%",
  },
  theActionBtn: {
    position: "relative",
    width: "100%",
  },
};

export const SideBarContainer = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
  padding: 1rem 2rem;

  @media screen and (min-width: 992px) {
    /* width: 90%; */
  }
`;

export const SideBarBox = styled.div`
  position: relative;
  text-align: center;
`;

export const Icon = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Circle = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);

  @media screen and (min-width: 992px) {
    padding: 0.  0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const SiderBarList = styled.div`
  position: relative;
`;

export const NavFlexLink = styled(Link)`
  align-items: center;
  display: flex;
  padding: 1rem 0;
`;

export const SmallIcon = styled.div`
  position: relative;
  width: 8%;
  display: flex;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    width: 4%;
  }
  @media screen and (min-width: 992px) {
    width: 10%;
  }
`;

export const SmallIconIMG = styled.img`
  width: 100%;
`;

export const ActiveBorder = styled.div`
  position: relative;
  background: #7f222c;
  width: 4px;
  height: 30px;
  margin-right: 1rem;
`;

export default makeStyles(config);
