import React from "react";
import { Container } from "@material-ui/core";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Add, Remove, Close, ChevronRight } from "@material-ui/icons";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import useStyles from "./styles";
import { orderhistory_detail } from "../../api/API";

export default function OrderDetail() {
  const classes = useStyles();
  let { orders_id } = useParams();

  let { OrderDetail } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [OrderDetail]);

  const history = useHistory();

  // GET ORDER HISTORY DETAIL

  const [order, setOrder] = React.useState({});
  const [orderProduct, setOrderProduct] = React.useState([]);

  React.useEffect(() => {
    let postparam = {
      orders_id: orders_id,
    };
    orderhistory_detail(postparam).then((json) => {
      setOrder(json.data[0]);
      setOrderProduct(json.data[0].product);
    });
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      <Container>
        <Section>
          <Card>
            <div className={classes.theHeaderProfile}>
              <Paragraph
                size="600"
                color="#7f222c"
                bold="600"
              // margin="0 0 1.8rem 0"
              >
                Order Number: {order.orders_id}
              </Paragraph>

              {order.order_status == '2' && (
                <a href={order.link} target="_blank">
                  <i class="fas fa-file-invoice-dollar" style={{ color: "#7f222c", fontSize: '1.4rem' }}></i>
                </a>
              )}

              {/* <Button color="#fff" bg="#7f222c" label="DOWNLOAD RECEIPT" style={{padding: "0.6rem 1rem"}}/> */}

            </div>
            {/* <div className="theScroll">
              <div className={classes.theTable}>
                <table>
                  <tr className={classes.theBorderHeader}>
                    <th width="40%">
                      <Paragraph
                        size="300"
                        color="#1e1e1e"
                        bold="500"
                        margin="1rem 0"
                      >
                        Product
                      </Paragraph>
                    </th>
                    <th width="20%">
                      <Paragraph
                        size="300"
                        color="#1e1e1e"
                        bold="500"
                        margin="1rem 0"
                      >
                        Quantity
                      </Paragraph>
                    </th>
                    <th width="20%" style={{
                      textAlign: 'right'
                    }}>
                      <Paragraph
                        size="300"
                        color="#1e1e1e"
                        bold="500"
                        margin="1rem 0"
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        Price
                      </Paragraph>
                    </th>
                  </tr>
                  {orderProduct.map((item) => (
                    <tr className={classes.theTableList}>
                      <td>
                        <div className={classes.theProduct}>
                          <div className={classes.theFlex}>
                            <div className={classes.theProdIMG}>
                              <img src={item.image} style={{
                                maxHeight: "100%"
                              }} />
                            </div>
                            <div className={classes.theDetail}>
                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                bold="600"
                                margin="0 0 0.4rem 0"
                              >
                                {item.name}
                              <br />
                              <small>{item.option_name}</small>
                              </Paragraph>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className={classes.theQuantity}>
                          <div className={classes.theIncrement}>
                            <div className={classes.theBox}>
                              <Paragraph
                                center
                                size="300"
                                color="#1e1e1e"
                                bold="600"
                              >
                                x {item.quantity}
                              </Paragraph>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="theTotalPrice">
                          <Paragraph
                            size="300"
                            color="#1e1e1e"
                            bold="600"
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            RM {item.amount}
                          </Paragraph>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div> */}
            <div className="cart_product_list">
              {orderProduct.map((item) => (
                <div
                  className={
                    classes.theTableList
                  }
                >
                  <div className={classes.theFlex}>
                    <div className={classes.theProdIMG}>
                      <img className={classes.img} src={item.image} />
                    </div>
                    <div className={classes.theDetail}>
                      {item.option == "" ? (
                        <Paragraph
                          size="300"
                          color="#1e1e1e"
                          bold="600"
                          margin="0 0 0.4rem 0"
                          className={classes.prod}
                        >
                          {item.name}
                        </Paragraph>
                      ) : (
                        <Paragraph
                          size="300"
                          color="#1e1e1e"
                          bold="600"
                          margin="0 0 .6rem 0"
                          className={classes.prod}
                        >
                          {item.name} - {item.option}
                        </Paragraph>
                      )}

                      <div className={classes.theQuantity}>
                        <Paragraph
                          size="200"
                          color="#1e1e1e"
                          bold="500"
                          margin="0rem 1rem 0 0"
                        >
                          Quantity :
                        </Paragraph>
                        <Paragraph
                          size="300"
                          color="#1e1e1e"
                          bold="600"
                        >
                          {item.quantity}
                        </Paragraph>
                      </div>
                      <div className={classes.theTotalPrice}>
                        <Paragraph
                          size="300"
                          color="#7f222c"
                          bold="500"
                          margin=".4rem 0"
                        >
                          RM {item.amount}
                        </Paragraph>
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </div>
            <div className={classes.theTotalAmount}>
              <Paragraph center size="500" color="#1e1e1e" bold="700">
                Total Amount:
                <span className={classes.price}>RM {order.final_amount}</span>
              </Paragraph>
            </div>
          </Card>
        </Section>
      </Container>
    </React.Fragment>
  );
}
