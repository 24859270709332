import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
} from "./styles";

import account from "../../assets/images/account.svg";
import pin from "../../assets/images/pin.svg";
import bag from "../../assets/images/bag.svg";
import gift from "../../assets/images/gift.svg";

// import "./Orders.css";

import { orderhistory, get_user_profile } from "../../api/API";

import useStyles from "./styles";

export default function Orders() {
  const classes = useStyles();

  let { Orders } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Orders]);

  // GET PROFILE
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    get_user_profile(postparam).then((json) => {
      setUser(json.data);
    });
  }, []);

  // ORDER HISTORY

  const [token, setToken] = React.useState("");

  const [orderList, setOrderList] = React.useState([]);
  // const [productList, setProductList] = React.useState([]);

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    orderhistory(postparam).then((json) => {
      setOrderList(json.data);
      console.log(json.data);
    });
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      <Container>
        <Section>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <SideBarContainer>
                <SideBarBox>
                  <Icon>
                    <Circle>
                      <img className={classes.theIMG} src={account} />
                    </Circle>
                  </Icon>
                  <p className="name">{user.name}</p>
                  {/* <p className="name">LOREM IPSUM</p> */}
                </SideBarBox>
                <SiderBarList>
                  <NavFlexLink to="/account">
                    <SmallIcon>
                      <SmallIconIMG src={account} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      My Account
                    </Paragraph>
                  </NavFlexLink>
                  {/* <NavFlexLink to="/addresses">
                    <SmallIcon>
                      <SmallIconIMG src={pin} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Address
                    </Paragraph>
                  </NavFlexLink> */}
                  <NavFlexLink to="/orders">
                    <ActiveBorder />
                    <SmallIcon className="theIcon">
                      <SmallIconIMG src={bag} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Orders
                    </Paragraph>
                  </NavFlexLink>
                </SiderBarList>
              </SideBarContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Card>
                <div className={classes.theHeaderProfile}>
                  <Paragraph size="600" color="#7f222c" bold="600">
                    Orders
                  </Paragraph>
                </div>
                <div className={classes.theForm}>
                  {orderList.map((item) => (
                    <div className={classes.theCardOrdersList}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8}>
                          <div className={classes.theBoxOrder}>
                            <div className={classes.theOrderDetail}>
                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                margin="1rem 0"
                              >
                                Order Number:{" "}
                                <span className={classes.theSpan}>
                                  {item.orders_id}
                                </span>
                              </Paragraph>

                              {/* <Paragraph
                                size="300"
                                color="#1e1e1e"
                                margin="1rem 0"
                              >
                                Total Order Amount:
                                <span className={classes.price}>
                                  RM {item.final_amount}
                                </span>
                              </Paragraph> */}

                              {item.delivery_type == 'standard' && (

                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Delivery Type
                                  <span className={classes.theSpan}>
                                    Standard Delivery
                                  </span>
                                </Paragraph>
                              )}

                              {item.delivery_type == 'instant' && (

                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Delivery Type
                                  <span className={classes.theSpan}>
                                    Express Delivery
                                  </span>
                                </Paragraph>
                              )}
                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                margin="1rem 0"
                              >
                                Purchase Date:
                                <span className={classes.theSpan}>
                                  {item.created_date}
                                </span>
                              </Paragraph>

                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                margin="1rem 0"
                              >
                                Payment Status:
                                <span className={classes.theSpan}>
                                  {item.status}
                                </span>
                              </Paragraph>

                              {item.delivery_type == 'standard' && (
                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Tracking Number:
                                  <span className={classes.theSpan}>
                                    {item.tracking_number}
                                  </span>
                                </Paragraph>
                              )}

                              {item.delivery_type == 'instant' && (
                                <>
                                  <Paragraph
                                    size="300"
                                    color="#1e1e1e"
                                    margin="1rem 0"
                                  >
                                    Vehicles Type:
                                    <span className={classes.theSpan}>
                                      {item.vehicle_type}
                                    </span>
                                  </Paragraph>
                                  <Paragraph
                                    size="300"
                                    color="#1e1e1e"
                                    margin="1rem 0"
                                  >
                                    Vehicles Number:
                                    <span className={classes.theSpan}>
                                      {item.vehicle_number}
                                    </span>
                                  </Paragraph>
                                </>
                              )}

                            </div>
                            <div className={classes.theCardBorder}>
                              <div className={classes.theCardDetail}>
                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Name:
                                  <span className={classes.theSpan}>
                                    {item.receiver_name}
                                  </span>
                                </Paragraph>
                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Unit/ Block:
                                  <span className={classes.theSpan}>
                                    {item.unit}
                                  </span>
                                </Paragraph>
                              </div>
                              <div className={classes.theCardDetail}>
                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  margin="1rem 0"
                                >
                                  Address:
                                  <span className={classes.theSpan}>
                                    {item.address}
                                  </span>
                                </Paragraph>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <div className={classes.theAction}>
                            <div className={classes.theActionBtn}>
                              <Button
                                label="View Detail"
                                color="#fff"
                                bg="#7f222c"
                                bold="600"
                                pd="0.6rem 2rem"
                                to={`../orderdetail/${item.orders_id}`}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </React.Fragment>
  );
}
