import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { md } from "../../styles/responsive";

export const IncrementAction = styled.div`
  background: #f4ad46;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${(props) => props.margin};
`;

const config = {
  theProductContainer: {
    position: "relative",
    margin: "1rem 0 0 0",
  },

  theProductCard: {
    position: "relative",
    width: " 100%",
    margin: "0 auto",
    display: "block",
    padding: "0 0 1rem 0",
  },

  theProductIMG_: {
    position: "relative",
    borderRadius: "6px",
    // border: "1px solid #d7d7d7",
    // padding: "1rem",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      height: "auto",
      // objectFit: "contain",
    }
  },

  theProductIMG2_: {
    position: "relative",
    borderRadius: "6px",
    // border: "1px solid #d7d7d7",
    // padding: "1rem",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      height: "300px",
      objectFit: "contain",

      ...md("sm", {
        height: "350px",
      }),

      ...md("md", {
        height: "400px",
      }),

      ...md("xmd", {
        height: "500px",
      }),
    }
  },

  theProdutIMGDescp: {
    position: "relative",
    width: "90%",
    margin: "0 auto",
    "& img": {
      width: "100%",
      // height: "300px",
      // objectFit: "contain",

      // ...md("sm", {
      //   height: "350px",
      // }),

      // ...md("md", {
      //   height: "400px",
      // }),

      // ...md("xmd", {
      //   height: "500px",
      // }),
    }
  },

  theDetail: {
    position: "relative",
    // width: "100%",
    // margin: "0 auto",
    padding: "0 1rem",
    marginTop: "2rem",

    // ...md("sm", {
    //   width: "90%",
    // }),
  },

  theFlex: {
    alignItems: "center",
    display: "flex",
    marginBottom: ".4rem",
  },

  //

  theDiscount: {
    fontSize: "1.2rem",
    ...md("sm", {
      fontSize: "1.8rem",
    }),
  },

  theGallery: {
    position: "relative",
    width: "100%",
  },

  theGalleryFlex: {
    position: "relative",
    width: "100%",
    flexDirection: "row",
    flexWrap: "unset",
    overflowX: "auto",
    display: "flex",
  },

  theNavLink: {
    position: "relative",
    padding: "0.4rem",
    cursor: "pointer",
    marginRight: "1rem",

    "& img": {
      width: "80px",
      height: "80px",
      objectFit: "contain",
    },
  },

  theNavLinkActive: {
    position: "relative",
    padding: "0.4rem",
    cursor: "pointer",
    marginRight: "1rem",
    border: "1px solid #ececec",

    "& img": {
      width: "80px",
      height: "80px",
      objectFit: "contain",
    },
  },

  //

  theVariantContainer: {
    position: "relative",
    margin: "1rem 0",
  },

  theVariantList: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    padding: ".2rem",
    background: "#f7f7f7",
    flexWrap: "wrap",
  },

  label: {
    position: "relative",
    width: "90%",
    margin: "0 auto",
    display: "block",
  },

  theVariantCard: {
    position: "relative",
    color: "#000",
    fontWeight: "700",
    margin: "0",
    padding: ".475rem 1rem",
    border: "1px solid transparent",
  },

  // LABEL

  theOptionBox: {
    margin: "0 .7rem 0 0",
    cursor: "pointer",
  },

  theVariantCardActive: {
    position: "relative",
    color: "#d0011b",
    fontWeight: "700",
    margin: "0",
    padding: ".475rem 1rem",
    border: "1px solid #d0011b",
  },

  theVariantCardActiveTick: {
    width: "1rem",
    height: "1rem",
    position: "absolute",
    overflow: "hidden",
    right: 0,
    bottom: 0,

    "&:before": {
      border: "1rem solid transparent",
      borderBottom: "1rem solid #ee4d2d",
      content: '""',
      position: "absolute",
      right: "-1rem",
      bottom: 0,
    },
  },


  theVariantCardOutStock: {
    position: "relative",
    background: "#d7d7d7",
    fontWeight: "700",
    margin: "0",
    padding: ".475rem 1rem",
    // border: "1px solid #878787",
  },

  theVariantCardTick: {
    display: "none",
  },

  theCheckIcon: {
    position: "absolute",
    right: 0,
    bottom: 0,
    color: " #fff",
    fontSize: ".6rem",
    fontWeight: "700",
  },

  theFlexCheckbox: {
    width: "100%",
    alignItems: "center",
    // display: "flex",
  },

  theHiddenRadioButton: {
    display: "none",
  },

  //

  theSelectBox: {
    margin: "0 .5rem 0 0",
    cursor: "pointer",

    ...md("xs", {
      margin: "0 .8rem 0 0",
    }),

    ...md("sm", {
      margin: "0 1rem 0 0",
    }),
  },

  theSelectionList: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    // margin: "21px 0 0 0",
    flexWrap: "wrap",
  },

  theSelectionCard: {
    position: "relative",
    color: "#fff",
    fontWeight: "600",
    margin: "0",
    padding: "8px .8rem",
    // border: "1px solid transparent",
    background: " #f4ad46",

    ...md("xs", {
      padding: "8px .4rem",
    }),

    ...md("sm", {
      padding: "8px .8rem",
    }),

    ...md("md", {
      padding: "7px .8rem",
    }),

    ...md("xmd", {
      padding: "7px 1rem",
    }),
  },

  theSelectionCardActive: {
    position: "relative",
    color: "#fff",
    fontWeight: "600",
    margin: "0",
    padding: "14px .8rem",
    // border: "1px solid #d0011b",
    background: " #f4ad46",

    ...md("xs", {
      padding: "14px .4rem",
    }),

    ...md("md", {
      padding: "13px .8rem",
    }),

    ...md("xmd", {
      padding: "13px 2rem",
    }),
  },

  selection: {
    margin: "0",
    fontSize: "14px",
    textAlign: "center",

    ...md("xs", {
      fontSize: "16px",
    }),

    ...md("md", {
      fontSize: "1rem",
    }),
  },

  //

  theTabList: {
    alignItems: "center",
    display: "flex",
    borderBottom: "1px solid #d7d7d7",
  },

  theTabActive: {
    borderBottom: "3px solid #000",
    padding: "0 1rem",
  },

  theTab: {
    borderBottom: "3px solid transparent",
    padding: "0 1rem",
  },

  theText: {
    color: "#878787",
    margin: "1rem 0 1rem 0",
    textAlign: "center",
  },

  theTextActive: {
    color: "#000",
    fontWeight: "700",
    margin: "1rem 0 1rem 0",
    textAlign: "center",
  },

  //

  title: {
    color: "#000",
    fontWeight: "600",
    fontSize: "1rem",
    ...md("sm", {
      fontSize: "1.2rem",
    }),
  },

  theContainer: {
    position: "relative",
    // padding: "1rem  0 0 0",
  },

  theFlavorCard: {
    position: "relative",
    textAlign: "center",
    margin: "0 auto",
  },

  flavorIMG: {
    width: "100%",
    margin: "0 auto",
    objectFit: "contain",

    ...md("sm", {
      width: "60%",
    }),

    ...md("md", {
      width: "40%",
    }),
  },

  theLabel: {
    fontSize: "14px",
  },

  //

  theFlexTaste: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #efeff1",
    marginBottom: "1rem",
  },

  //

  theFlexQ: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    padding: "1rem 0 0 0",
  },

  theFlexBox: {
    position: "relative",
    // width: "51%",
    width: "46%",

    ...md("sm", {
      width: "40%",
    }),

    ...md("md", {
      width: "38%",
    }),

    // ...md("xmd", {
    //   width: "30%",
    // }),
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: "1rem 0",
    border: "1px solid #f4ad46",
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },

  theActionButton: {
    background: "#4675b6",
    padding: "0.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  labelQuantity: {
    color: " #8f8f8f",
    // fontWeight: "700",
    margin: "0 0 0.8rem 0",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",

    // ...md("xxs", {
    //   border: "1px solid #f4ad46",
    // }),
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  theAddCart: {
    position: "relative",
    color: "#fff",
    fontWeight: "600",
    background: "#f4ad46",
    padding: "14px 2rem",
    justifyContent: "center",
    display: "flex",
    fontSize: "14px",

    ...md("xxs", {
      padding: "14px 20px",
    }),

    ...md("xs", {
      padding: "14px 2rem",
    }),

    ...md("xmd", {
      padding: "14px 3rem",
    }),
  },

  tag: {
    background: "#e60c0b",
    padding: ".6rem",
    margin: "1rem 0 1.4rem 0",
  },

  theListing: {
    width: "90%",
    // border: "1px solid transparent",
    // margin: ".4rem 0",
    // padding: ".1rem 1rem",

    ...md("md", {
      width: "90%",
    }),

    ...md("xmd", {
      width: "80%",
    }),
  },

  theListingActive: {
    border: "1px solid #d7d7d7",
    borderRadius: "4px",
    padding: "0 1rem",
    width: "90%",
    // border: "1px solid #000",
    margin: ".4rem 0",
    padding: ".1rem 1rem",

    ...md("md", {
      width: "90%",
    }),

    ...md("xmd", {
      width: "80%",
    }),
  },

  theListingRow: {
    margin: ".2rem 0",
  },

  theNormal: {
    color: "#1e1e1e",
    // fontWeight: "600",
    margin: 0,
    padding: "0 .2rem"

  },
  theSelectedPrice: {
    color: "red",
    // fontWeight: "600",
    margin: 0,
    border: "1px solid #d0011b",
    padding: "0 .2rem"
  },

  thePrice: {
    color: "#d0011b",
    // fontWeight: "700"
  },

  theFlexRow: {
    display: "flex",
    marginBottom: ".4rem",
  },

  theTable: {
    position: "relative",
    whiteSpace: "nowrap",
    // overflow: "scroll",
    // padding: " 1rem",
    // boxShadow: "0px 0px 4px 1px rgb(0 0 0 / 8%)",

    "& table": {
      position: "relative",
      width: "100%",
    },
  },
  theBorderHeader: {
    position: "relative",
    width: "100%",

    "& th": {
      borderBottom: "1px solid #eaeaea",
    },
  },

  theTableList: {
    "& td": {
      padding: "1rem 0",
      borderBottom: "1px solid #eaeaea",
    },
  },
  text: {
    textAlign: "justify !important",
    "p": {
      textAlign: "justify !important",
    }
  },
  theAva: {
    background: "#e60c0b",
    padding: "0.4rem .8rem",
    width: "70%",
    marginBottom: ".6rem",
    display: "flex",

    ...md("md", {
      width: "50%",
    }),

    ...md("xmd", {
      width: "40%",
    }),
  },
  theAvaa: {
    width: "80%",
    // marginBottom: ".6rem",
    // display: "flex",
   
    "& img": {
      width: "100%"
    }
  },
  theStockAva: {
    background: "#e60c0b",
    padding: "0.4rem .8rem",
    width: "40%",
    transform: "translate(-50%,-50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "block",

    ...md("sm", {
      width: "70%",
    }),

    ...md("md", {
      width: "50%",
    }),

    ...md("xmd", {
      width: "50%",
    }),
  }
};

export default makeStyles(config);
