import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const config = {
  root: {},
  theHeaderProfile: {
    position: "relative",
    display: "flex",
    alignItems: 'center',
    marginBottom: '1.8rem',
    justifyContent: 'space-between'
  },
  theTable: {
    position: "relative",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "scroll",

    "& table": {
      position: "relative",
      width: "100%",
    },
  },
  theBorderHeader: {
    position: "relative",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "scroll",

    "& th": {
      borderBottom: "1px solid #eaeaea",
    },
  },

  // theTableList: {
  //   "& td": {
  //     padding: "1rem 0",
  //     borderBottom: "1px solid #eaeaea",
  //   },
  // },

  // theProduct: {
  //   alignItems: "center",
  //   display: "flex",
  // },

  // theFlex: {
  //   alignItems: "center",
  //   display: "flex",
  // },

  // theProdIMG: {
  //   position: "relative",
  //   width: 200,
  //   height: 110,
  //   textAlign: 'center',
  //   padding: 6,
  //   marginRight: 6,
  //   boxShadow: " 0px 0px 4px 1px rgb(0 0 0 / 8%)",

  //   "& img": {
  //     width: "100%",
  //     height: "auto",
  //     objectFit: "contain"
  //   },
  // },

  

  // theQuantity: {
  //   position: "relative",
  //   marginLeft: "1rem",
  // },

  // theDetail: {
  //   position: "relative",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   width: "100%",
  //   display: "flex",
  // },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  theTotalAmount: {
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    margin: "1rem 0",
  },

  price: {
    color: "#fa4c4e",
    fontSize: "1.2rem",
    fontWeight: "700",
    marginLeft: "1rem",
  },



  theTableList: {
    "& td": {
      padding: "1rem 0",
      borderBottom: "1px solid #eaeaea",
    },
  },

  theProduct: {
    alignItems: "center",
    display: "flex",
  },

  theFlex: {
    alignItems: "center",
    display: "flex",
    borderBottom: "1px solid #eaeaea",
    padding: "1rem 0",
  },

  theProdIMG: {
    position: "relative",
    width: "80px",
    boxShadow: " 0px 0px 4px 1px rgb(0 0 0 / 8%)",
  },

  img: {
    width: "100%",
    height: "70px",
    objectFit: "contain",
  },

  theQuantity: {
    position: "relative",
    alignItems: "center",
    // justifyContent: "center",
    display: "flex",
  },

  theDetail: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginLeft: "1.4rem",
    whiteSpace: "break-spaces"

    // display: "flex",
  },
};

export const SideBarContainer = styled.div``;

export const SideBarBox = styled.div`
  position: relative;
  text-align: center;
`;
 
export const Icon = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Circle = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);

  @media screen and (min-width: 992px) {
    padding: 0.3rem 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const SiderBarList = styled.div`
  position: relative;
`;

export const NavFlexLink = styled(Link)`
  align-items: center;
  display: flex;
  padding: 1rem 0;
`;

export const SmallIcon = styled.div`
  position: relative;
  width: 8%;
  display: flex;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    width: 4%;
  }
  @media screen and (min-width: 992px) {
    width: 10%;
  }
`;

export const SmallIconIMG = styled.img`
  width: 100%;
`;

export const ActiveBorder = styled.img`
  position: relative;
  background: #7f222c;
  width: 4px;
  height: 30px;
  margin-right: 1rem;
`;

export default makeStyles(config);
