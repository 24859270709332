import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  const [shippingType, setShippingType] = React.useState("standard");

  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    // alert(JSON.stringify(cart))
    setCart(cart);
  }
  function addProduct(item) {
    console.log(item);
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    newItem.price = getPriceByQuantity(newItem, newItem.quantity);
    if (cartTemp.length === 0) {
      newItem["index"] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;
      console.log(cartTemp);
      for (var i = 0; i < cartTemp.length; i++) {
        if (
          cartTemp[i].product_option_selection_id ==
          newItem.product_option_selection_id
          && cartTemp[i].product_id == newItem.product_id
        ) {
          existed = 1;
          index = i;
        }
      }

      if (existed == 1) {
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
        cartTemp[index]["quantity"] = new_quantity;
        cartTemp[index]["price"] = getPriceByQuantity(
          cartTemp[index],
          new_quantity
        );
      } else {
        newItem["index"] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);
    window.localStorage.setItem("length", cartTemp.length);
    // console.log(cartTemp);
  }

  function increaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    cartTemp[index]["quantity"] = quantity;
    cartTemp[index]["price"] = parseFloat(
      getPriceByQuantity(cartTemp[index], quantity)
    );
    setCart(cartTemp);
    Storage(cartTemp);


    // console.log(cartTemp);
  }

  function getPriceByQuantity(cartRowItem, qty) {
    if (qty >= 24 && cartRowItem.price_for_24 > 0) {
      return (cartRowItem.price_for_24 / 24) * qty;
    } else if (qty >= 12 && cartRowItem.price_for_12 > 0) {
      return (cartRowItem.price_for_12 / 12) * qty;
    } else if (qty >= 6 && cartRowItem.price_for_6 > 0) {
      return (cartRowItem.price_for_6 / 6) * qty;
    } else {
      return cartRowItem.single_price * qty;
    }
  }
  function decreaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] = getPriceByQuantity(cartTemp[index], quantity);
    } else {
      cartTemp.splice(index, 1);
      // window.localStorage.setItem('length', cartTemp.length - 1)
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }

    setCart(cartTemp);
    Storage(cartTemp);

    // console.log(cartTemp);
  }

  function getTotalCartWithoutShipping() {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var total_cart_price = 0;



    // for (var i = 0; i < cartTemp.length; i++) {
    //   if (shipping == "instant") {
    //     if (!cartTemp[i].is_instant || !cartTemp[i].is_instant == "1") continue;
    //     else
    //       total_cart_price += parseFloat(
    //         getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
    //       );
    //   } else {
    //     if (cartTemp[i].is_instant || cartTemp[i].is_instant == "1") continue;
    //     else
    //       total_cart_price += parseFloat(
    //         getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
    //       );
    //   }
    // }

    for (var i = 0; i < cartTemp.length; i++) {


      total_cart_price += parseFloat(
        getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
      );
    }

    // console.log(shipping);

    return total_cart_price;
  }

  function getTotalCart(shipping) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var total_cart_price = 0;



    // for (var i = 0; i < cartTemp.length; i++) {
    //   if (shipping == "instant") {
    //     if (!cartTemp[i].is_instant || !cartTemp[i].is_instant == "1") continue;
    //     else
    //       total_cart_price += parseFloat(
    //         getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
    //       );
    //   } else {
    //     if (cartTemp[i].is_instant || cartTemp[i].is_instant == "1") continue;
    //     else
    //       total_cart_price += parseFloat(
    //         getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
    //       );
    //   }
    // }

    for (var i = 0; i < cartTemp.length; i++) {
      if (shipping == "instant") {
        // console.log("INSTANT BLOCK",cartTemp[i].is_instant,
        // !cartTemp[i].is_instant, cartTemp[i].is_instant != '1');
        if (!cartTemp[i].is_instant || cartTemp[i].is_instant != "1") continue;
        else
          total_cart_price += parseFloat(
            getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
          );
      } else {
        if (cartTemp[i].is_instant != undefined && cartTemp[i].is_instant == "1") continue;
        else
          total_cart_price += parseFloat(
            getPriceByQuantity(cartTemp[i], cartTemp[i].quantity)
          );
      }
    }

    // console.log(shipping);

    return total_cart_price;
  }

  function removeProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    setCart(cartTemp);
    Storage(cartTemp);
  }

  function get_by_shipping(shippingType) {
    // console.log("cart test ", shippingType);
    var temp_checkoutData = null;
    if (shippingType == "instant") {
      var temp_products = [];
      var products_temp = [...cart];
      for (var i = 0; i < products_temp.length; i++) {
        if (products_temp[i].is_instant == 1) {
          temp_products.push(products_temp[i]);
        }
      }
      temp_checkoutData = [...temp_products];
    } else {
      var temp_products = [];
      var products_temp = [...cart];
      for (var i = 0; i < products_temp.length; i++) {

        temp_products.push(products_temp[i]);
      }
      temp_checkoutData = [...temp_products];
    }
    // console.log("Cart", temp_checkoutData);

    return temp_checkoutData;
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    get_by_shipping,
    removeProduct,
    getTotalCartWithoutShipping,
    getCart,
    increaseProduct,
    decreaseProduct,
    setCartFunction,

  };
};

export default useCart;