import React from "react";
import { Container, Grid, Snackbar } from "@material-ui/core";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { IncrementAction } from "./styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  get_product,
  get_product_option_selection,
  get_specific_product_option_selection,
} from "../../api/API";
import { Add, Check, Close, Remove, ShoppingCart } from "@material-ui/icons";

import banner from "../../assets/images/stocky_banner.png"

import useCart from "../../hooks/useCart";

import SmoothImage from "react-smooth-image";

const ProductDetail = (props) => {
  const classes = useStyles();

  console.log(useHistory());

  let { ProductDetail } = useLocation();

  const [displayPrice, setDisplayPrice] = React.useState(0);
  const [savingPrice, setSavingPrice] = React.useState(0);


  React.useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("history", props.history);
    // console.log("params", props.params);
  }, [ProductDetail]);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 8,
      borderRadius: 3,
      width: "100%",
    },
    colorPrimary: {
      backgroundColor: "#e0dfe3",
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "#f4ad46",
    },
  }))(LinearProgress);

  // API

  let { product_id } = useParams();

  const [chooseSelection, setChooseSelection] = React.useState({
    product_option_selection_id: "",
  });
  const [productOptionList, setProductOptionList] = React.useState("");

  const [productPrice, setProductPrice] = React.useState([]);

  const [productOptionSelection, setProductOptionSelection] = React.useState(
    []
  );

  const [
    productOptionSelectionSinglePrice,
    setProductOptionSelectionSinglePrice,
  ] = React.useState([]);

  const [productOptionSelectionName, setProductOptionSelectionName] =
    React.useState("");

  const [productOptionSelectionPriceFor6, setProductOptionSelectionPriceFor6] =
    React.useState("0.00");
  const [
    productOptionSelectionPriceFor12,
    setProductOptionSelectionPriceFor12,
  ] = React.useState("0.00");

  const [
    productOptionSelectionPriceFor24,
    setProductOptionSelectionPriceFor24,
  ] = React.useState("0.00");

  const [currentStock, setCurrentStock] = React.useState("");

  const handleChooseSelection = (product_option_selection_id) => {
    var new_price_classes = {};

    setChooseSelection(product_option_selection_id);

    console.log("productoptionid " + product_option_selection_id);
    setCount(1);
    setSavingPrice(0);
    setPriceClass(new_price_classes);

    let postparam = {
      product_id: product_id,
      product_option_selection_id: product_option_selection_id
      // shop_id: props.history.location.state.shop_id
    };
    // alert(JSON.stringify(props))
    if ((props.history.location).state != undefined) {
      postparam.shop_id = props.history.location.state.shop_id
    }
    get_product_option_selection(postparam).then((json) => {
      setProductOptionSelection(json.data);
      setProductPrice(json.data[0].price);
      setProductOptionSelectionSinglePrice(json.data[0].price);
      setProductOptionSelectionPriceFor6(json.data[0].price_for_6);
      setProductOptionSelectionPriceFor12(json.data[0].price_for_12);
      setProductOptionSelectionPriceFor24(json.data[0].price_for_24);
      setDisplayPrice(json.data[0].price);
      setProductOptionSelectionName(json.data);
      setCurrentStock(json.data[0].stock);

    });
  };

  console.log("CURRENT SRTOCK " + currentStock);

  const [tabs, setTabs] = React.useState("1");

  const handleChangeTab = (text) => {
    setTabs(text);
  };

  // COUNT QUANTITY

  const handleChangeQty = (new_qty) => {
    if (new_qty < 1) {
      return;
    }

    var new_price_classes = {
      q_6: classes.theNormal,
      q_12: classes.theNormal,
      q_24: classes.theNormal,
    };

    setCount(new_qty);
    if (new_qty >= 24 && productOptionSelectionPriceFor24 > 0) {
      setDisplayPrice((productOptionSelectionPriceFor24 / 24) * new_qty);
      setSavingPrice(productPrice * new_qty);
      new_price_classes.q_24 = classes.theSelectedPrice;
    } else if (new_qty >= 12 && productOptionSelectionPriceFor12 > 0) {
      setDisplayPrice((productOptionSelectionPriceFor12 / 12) * new_qty);
      setSavingPrice(productPrice * new_qty);
      new_price_classes.q_12 = classes.theSelectedPrice;
    } else if (new_qty >= 6 && productOptionSelectionPriceFor6 > 0) {
      setDisplayPrice((productOptionSelectionPriceFor6 / 6) * new_qty);
      setSavingPrice(productPrice * new_qty);
      new_price_classes.q_6 = classes.theSelectedPrice;
    } else {
      setDisplayPrice(productPrice * new_qty);
      setSavingPrice(0);
    }

    setPriceClass(new_price_classes);
  };

  const [count, setCount] = React.useState(1);

  // GET PRODUCT DETAIL

  const [isBusy, setIsBusy] = React.useState(true);

  const [product, setProduct] = React.useState({});

  const [tabsPhoto, setTabsPhoto] = React.useState(1);

  const [productIMGDesp, setProductIMGDesp] = React.useState([]);

  const [productImage, setProductImage] = React.useState("");

  const [productName, setProductName] = React.useState("");

  const [productVariant, setProductVariant] = React.useState([]);
  const [productSelection, setProductSelection] = React.useState([]);
  const [productFlavour, setProductFlavour] = React.useState([]);
  const [productTaste, setProductTaste] = React.useState([]);
  const [productPriceDefault, setProductPriceDefault] = React.useState("");
  const [productIMGCount, setProductIMGCount] = React.useState([]);
  const [priceClass, setPriceClass] = React.useState({
    q_6: classes.theNormal,
    q_12: classes.theNormal,
    q_24: classes.theNormal,
  });
  const [isInstant, setIsInstant] = React.useState("");

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };






  React.useEffect(() => {
    let postparam = {
      product_id: product_id,
      // shop_id: props.history.location.state.shop_id
    };
    // alert(JSON.stringify(props))
    if ((props.history.location).state != undefined) {
      postparam.shop_id = props.history.location.state.shop_id
    }

    get_product(postparam).then((json) => {
      setProduct(json.data[0]);
      setTabsPhoto(json.data[0].images[0].image);
      setProductName(json.data[0].name);
      setProductImage(json.data[0].images[0].image);
      setProductVariant(json.data[0].options);
      setProductFlavour(json.data[0].flavour);
      setProductTaste(json.data[0].taste);
      setProductIMGDesp(json.data[0].description_images);
      setIsInstant(json.data[0].is_instant);
      // setProductPhoto(json.data[0].images[0].image);

      console.log(productIMGDesp);

      var leng = json.data[0].images.length;
      if (leng <= 1) {
        setProductIMGCount(leng);
      } else {
        setProductIMGCount(leng);
      }

      setIsBusy(false);
      if (json.data[0].options == "") {
        setChooseSelection(json.data);
        setProductPriceDefault(json.data[0].pricing);
        setCurrentStock(json.data[0].stock);
        // setDisplayPrice(json.data[0].pricing);

        handleChangeQty(1);
      } else {
        setChooseSelection(
          json.data[0].options[0].selections[0].product_option_selection_id
        );

        setProductOptionSelectionPriceFor6(
          json.data[0].options[0].selections[0].price_for_6
        );
        setProductOptionSelectionPriceFor12(
          json.data[0].options[0].selections[0].price_for_12
        );
        setProductOptionSelectionPriceFor24(
          json.data[0].options[0].selections[0].price_for_24
        );

        setProductSelection(json.data[0].options[0].selections);

        setProductPrice(json.data[0].options[0].selections[0].price);

        setProductOptionList(json.data[0].options[0].selections);

        setCurrentStock(json.data[0].options[0].selections[0].stock);

        handleChangeQty(1);
      }

      setProductCart({
        ...productCart,
        name: productName,
        images: productImage,
      });

      if (json.data[0].options == "") {
        setCartItem({
          product_id: json.data[0].product_id,
          name: json.data[0].name,
          price: json.data[0].pricing,
          quantity: 1,
          single_price: json.data[0].pricing,
          images: json.data[0].images[0].image,
          is_instant: json.data[0].is_instant,
          option: "",
        });
      } else {
        var index = 0;

        for (var i = 0; i < json.data[0].options[0].selections.length; i++) {
          if (
            json.data[0].options[0].selections[i][
            "product_option_selection_id"
            ] == chooseSelection
          ) {
            index = i;
          }
        }
        setCartItem({
          product_id: json.data[0].product_id,
          name: json.data[0].name,
          product_option_selection_id:
            json.data[0].options[0].selections[index]
              .product_option_selection_id,
          option: json.data[0].options[0].selections[index].name,
          price: json.data[0].options[0].selections[index].price,
          is_instant: json.data[0].is_instant,
          quantity: 1,
          single_price: json.data[0].options[0].selections[index].price,
          images: json.data[0].images[0].image,
          price_for_6:
            json.data[0].options[index].selections[index].price_for_6,
          price_for_12:
            json.data[0].options[index].selections[index].price_for_12,
          price_for_24:
            json.data[0].options[index].selections[index].price_for_24,
        });
      }
    });
  }, []);

  const handleChange = (event) => {
    setCartItem({
      ...cartItem,
      [event.target.name]: event.target.value,
    });
  };

  // CART INFO

  const [cartItem, setCartItem] = React.useState({
    product_id: 0,
    name: "",
    product_option_selection_id: 0,
    variant: "",
    options: "",
    price: 0,
    quantity: 0,
    single_price: 0,
    images: "",
    is_instant: "",
  });

  // console.log(cartItem.length);

  const handleChangeChooseSelection = (event) => {
    var cartArray = {};

    productSelection.map(function (val, i) {
      if (event.target.value == val.product_option_selection_id) {
        cartArray = {
          product_id: product_id,
          name: productName,
          product_option_selection_id: val.product_option_selection_id,
          price: count * val.price,
          price_for_6: displayPrice,
          price_for_12: displayPrice,
          price_for_24: displayPrice,
          // price: displayPrice,
          is_instant: isInstant,
          quantity: count,
          single_price: val.price,
          images: productImage,
          option: val.name,
        };
      }
    });

    setCartItem(cartArray);

    setProductCart({
      ...productCart,
      [event.target.name]: event.target.value,
    });

    console.log("CARTITEM " + JSON.stringify(cartArray));
  };

  const { addProduct, clearCart } = useCart();

  const [productCart, setProductCart] = React.useState({
    product_id: product_id,
    option: [...productSelection],
  });

  // ALERT ADDED TO CART

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const { getCart } = useCart();

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const handleAddToCart = (newState) => {
    if (currentStock < count) {
      alert("There's only " + currentStock + " left for this product");
    } else {
      let productCartList = {
        ...cartItem,
        price_for_6: productOptionSelectionPriceFor6,
        price_for_12: productOptionSelectionPriceFor12,
        price_for_24: productOptionSelectionPriceFor24,
        // is_instant: props.is_fast_delivery,
        is_instant: isInstant,
      };

      // CHECK INSTANT

      // if (cart.length > 0) {

      //   if (cart[0].is_instant == isInstant) {

      //     addProduct(productCartList);

      //     let array = [];

      //     array.push(productCartList);

      //     window.localStorage.setItem("productCartList", JSON.stringify(array));

      //     setState({ open: true, ...newState });

      //   } else {

      //     alert("Current Cart Having Product in Different Shipping Method");

      //   }

      // } else {

      addProduct(productCartList);

      let array = [];

      array.push(productCartList);

      window.localStorage.setItem("productCartList", JSON.stringify(array));

      setState({ open: true, ...newState });

      // }
    }
  };

  // console.log("MY CURRENT CART " + JSON.stringify(cart.length))

  // CALCULATION QUANTITY

  React.useEffect(() => {
    var price = cartItem.single_price;
    // var weight = cartItem.single_weight;
    setCartItem({
      ...cartItem,
      quantity: count,
      price: count * price,
      price_for_6: displayPrice,
      price_for_12: displayPrice,
      price_for_24: displayPrice,
      // price: displayPrice,
      // weight: count * weight,
      // single_weight: weight,
    });
  }, [count]);

  const [priceDifferent, setPriceDifferent] = React.useState([]);

  React.useEffect(() => {
    let postparam = {
      product_id: product_id,
      // shop_id: props.history.location.state.shop_id
    };
    // alert(JSON.stringify(props.history.location.state))
    if ((props.history.location).state != undefined) {
      postparam.shop_id = props.history.location.state.shop_id
    }
    get_specific_product_option_selection(postparam).then((json) => {
      setPriceDifferent(json.data);
    });
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == true ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          <Container>
            <Section>
              <div className={classes.theProductContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5} md={6}>
                    {currentStock == 0 ? (
                      <>
                        {product.image_setting_id == "1" ? (
                          <div className={classes.theProductIMG_}>
                            <img src={tabsPhoto} />
                            <div className={classes.theStockAva}>
                              <Paragraph size="600" color="#fff" bold="700" center>
                                OUT OF STOCK
                              </Paragraph>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.theProductIMG2_}>
                            <img src={tabsPhoto} />
                            <div className={classes.theStockAva}>
                              <Paragraph size="600" color="#fff" bold="700" center>
                                OUT OF STOCK
                              </Paragraph>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {product.image_setting_id == "1" ? (
                          <div className={classes.theProductIMG_}>
                            <img src={tabsPhoto} />
                          </div>
                        ) : (
                          <div className={classes.theProductIMG2_}>
                            <img src={tabsPhoto} />
                          </div>
                        )}
                      </>
                    )}
                    {productIMGCount == 1 ? (
                      <></>
                    ) : (
                      <>
                        <div className={classes.theGallery}>
                          <div className={classes.theGalleryFlex}>
                            {product.images && (
                              <>
                                {product.images.map((item) => (
                                  <div
                                    className={
                                      tabsPhoto == item.image
                                        ? classes.theNavLinkActive
                                        : classes.theNavLink
                                    }
                                    onClick={() => {
                                      handleChangeTabPhoto(item.image);
                                    }}
                                  >
                                    <img src={item.image} />
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} sm={7} md={6}>
                    <div className={classes.theDetail}>
                      {/* {isInstant == 1 ? (
                        <div className={classes.theAvaa}>
                          <img src={banner} />
                        </div>
                      ) : (
                        <></>
                      )} */}

                      {productVariant == "" ? (
                        <Paragraph
                          size="700"
                          color="#f4b14a"
                          bold="600"
                          margin="0 0 .4rem 0"
                        >
                          RM {parseFloat(productPriceDefault).toFixed(2)}
                        </Paragraph>
                      ) : (
                        <>
                          {displayPrice == "0.00" ? (
                            <Paragraph
                              size="700"
                              color="#f4b14a"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              RM {parseFloat(productPrice).toFixed(2)}
                            </Paragraph>
                          ) : (
                            <>
                              <Paragraph
                                size="700"
                                color="#f4b14a"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                RM {parseFloat(displayPrice).toFixed(2)}
                              </Paragraph>
                            </>
                          )}
                        </>
                      )}

                      {savingPrice > 0 && (
                        <div className={classes.theFlex}>
                          <Paragraph
                            size="500"
                            color="#878787"
                            textDecoration="line-through"
                            bold="600"
                            margin="0 1rem 0 0"
                          >
                            RM{parseFloat(savingPrice).toFixed(2)}
                          </Paragraph>
                          <span style={{ color: "red", fontWeight: "500" }}>
                            SAVE RM
                            {parseFloat(savingPrice - displayPrice).toFixed(2)}
                          </span>
                        </div>
                      )}

                      <Paragraph
                        size="600"
                        color="#878787"
                        bold="600"
                        margin="0 0 .4rem 0"
                      >
                        {product.name}
                      </Paragraph>

                      {productOptionSelection == '' &&
                        productOptionSelectionPriceFor6 == '0.00' &&
                        productOptionSelectionPriceFor12 == '0.00' &&
                        productOptionSelectionPriceFor24 == '0.00' ? (
                        <></>
                      ) : (
                        <div className={
                          productOptionSelectionPriceFor6 == '0.00' &&
                            productOptionSelectionPriceFor12 == '0.00' &&
                            productOptionSelectionPriceFor24 == '0.00'
                            ? classes.theListing
                            : classes.theListingActive
                        }>
                          {productOptionSelectionPriceFor6 == "0.00" ? (
                            <></>
                          ) : (
                            <div className={classes.theListingRow}>
                              <p
                                className={priceClass.q_6}
                                style={{ margin: 0, padding: "0 .2rem" }}
                              >
                                Buy 6 for{" "}
                                <span className={classes.thePrice}>
                                  RM
                                  {parseFloat(
                                    productOptionSelectionPriceFor6 / 6
                                  ).toFixed(2)}
                                </span>{" "}
                                each
                              </p>
                            </div>
                          )}
                          {productOptionSelectionPriceFor12 == "0.00" ? (
                            <></>
                          ) : (
                            <div className={classes.theListingRow}>
                              <p
                                className={priceClass.q_12}
                                style={{ margin: 0, padding: "0 .2rem" }}
                              >
                                Buy 12 for{" "}
                                <span className={classes.thePrice}>
                                  RM
                                  {parseFloat(
                                    productOptionSelectionPriceFor12 / 12
                                  ).toFixed(2)}
                                </span>{" "}
                                each
                              </p>
                            </div>
                          )}
                          {productOptionSelectionPriceFor24 == "0.00" ? (
                            <></>
                          ) : (
                            <div className={classes.theListingRow}>
                              <p
                                className={priceClass.q_24}
                                style={{ margin: 0, padding: "0 .2rem" }}
                              >
                                Buy 24 for{" "}
                                <span className={classes.thePrice}>
                                  RM
                                  {parseFloat(
                                    productOptionSelectionPriceFor24 / 24
                                  ).toFixed(2)}
                                </span>{" "}
                                each
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      {/* <div className={classes.tag}>
                        <Paragraph color="#fff" bold="700">
                          SAVE RM 10 IF PURCHASE 6 BOTTLES
                        </Paragraph>
                      </div> */}
                      {productVariant == "" ? (
                        <></>
                      ) : (
                        <>
                          {productVariant.map((item) => (
                            <div className={classes.theVariantContainer}>
                              <Paragraph
                                size="300"
                                bold="600"
                                margin="0 1rem .4rem 0"
                              >
                                {item.option_name} :
                              </Paragraph>
                              <div className={classes.theVariantList}>
                                {productSelection.map((item) => (
                                  <label className={classes.theOptionBox}>
                                    <div className={classes.theFlexCheckbox}>
                                      <input
                                        className={classes.theHiddenRadioButton}
                                        type="radio"
                                        value={item.product_option_selection_id}
                                        name="product_option_selection_id"
                                        checked={
                                          chooseSelection ==
                                          item.product_option_selection_id
                                        }
                                        onChange={handleChangeChooseSelection}
                                        onClick={() =>
                                          handleChooseSelection(
                                            item.product_option_selection_id
                                          )
                                        }
                                      />

                                      {item.stock == 0 ? (
                                        <div
                                          className={
                                            classes.theVariantCardOutStock
                                          }
                                        >
                                          <Paragraph bold="600">
                                            {item.name} - OUT OF STOCK
                                          </Paragraph>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            chooseSelection ==
                                              item.product_option_selection_id
                                              ? classes.theVariantCardActive
                                              : classes.theVariantCard
                                          }
                                        >
                                          <Paragraph bold="600">
                                            {item.name}
                                          </Paragraph>
                                          <div
                                            className={
                                              chooseSelection ==
                                                item.product_option_selection_id
                                                ? classes.theVariantCardActiveTick
                                                : classes.theVariantCardTick
                                            }
                                          >
                                            <Check
                                              className={classes.theCheckIcon}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                ))}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {currentStock < 10 && (
                        <p style={{

                          fontSize: "0.8em",
                          color: 'red'
                        }}>last {currentStock} in stock</p>
                      )}


                      {/* SELECTIONS */}

                      {productOptionList == "" ? (
                        <></>
                      ) : (
                        <>
                          {productOptionList == "" ? (
                            <></>
                          ) : (
                            <div className={classes.theSelectionList}>
                              {productOptionSelectionPriceFor6 == "0.00" || currentStock < 6 ? (
                                <></>
                              ) : (
                                <label className={classes.theSelectBox}>
                                  <div className={classes.theFlexCheckbox}>
                                    <input
                                      className={classes.theHiddenRadioButton}
                                      type="radio"
                                      value={productSelection.price_for_6}
                                      name="price_for_6"
                                      checked={
                                        chooseSelection ==
                                        productSelection.price_for_6
                                      }
                                      value="6"
                                      // onChange={handleChangeChooseSelection}
                                      onClick={() => handleChangeQty(6)}
                                    />

                                    <div
                                      className={
                                        chooseSelection ==
                                          productSelection.price_for_6
                                          ? classes.theSelectionCardActive
                                          : classes.theSelectionCard
                                      }
                                    >
                                      <p className={classes.selection}>6 QTY</p>
                                    </div>
                                  </div>
                                  {/* <Paragraph color="#d0011b" bold="600">
                                    (RM
                                    {parseFloat(
                                      (
                                        productOptionSelectionPriceFor6 / 6
                                      ).toFixed(2)
                                    )}
                                    / PER QTY)
                                  </Paragraph> */}
                                </label>
                              )}

                              {productOptionSelectionPriceFor12 == "0.00" || currentStock < 12 ? (
                                <></>
                              ) : (
                                <label className={classes.theSelectBox}>
                                  <div className={classes.theFlexCheckbox}>
                                    <input
                                      className={classes.theHiddenRadioButton}
                                      type="radio"
                                      value={productSelection.price_for_12}
                                      name="price_for_12"
                                      checked={
                                        chooseSelection ==
                                        productSelection.price_for_12
                                      }
                                      value="12"
                                      // onChange={handleChangeChooseVariant}
                                      onClick={() => handleChangeQty(12)}
                                    />

                                    <div
                                      className={
                                        chooseSelection ==
                                          productSelection.price_for_12
                                          ? classes.theSelectionCardActive
                                          : classes.theSelectionCard
                                      }
                                    >
                                      <p className={classes.selection}>
                                        12 QTY
                                      </p>
                                    </div>
                                  </div>
                                  {/* <Paragraph color="#d0011b" bold="600">
                                    (RM
                                    {parseFloat(
                                      (
                                        productOptionSelectionPriceFor12 / 6
                                      ).toFixed(2)
                                    )}
                                    / PER QTY)
                                  </Paragraph> */}
                                </label>
                              )}

                              {productOptionSelectionPriceFor24 == "0.00" ? (
                                <></>
                              ) : (
                                <label className={classes.theSelectBox}>
                                  <div className={classes.theFlexCheckbox}>
                                    <input
                                      className={classes.theHiddenRadioButton}
                                      type="radio"
                                      value={productSelection.price_for_24}
                                      name="price_for_24"
                                      checked={
                                        chooseSelection ==
                                        productSelection.price_for_24
                                      }
                                      value="24"
                                      // onChange={handleChangeChooseVariant}
                                      onClick={() => handleChangeQty(24)}
                                    />

                                    <div
                                      className={
                                        chooseSelection ==
                                          productSelection.price_for_24
                                          ? classes.theSelectionCardActive
                                          : classes.theSelectionCard
                                      }
                                    >
                                      <p className={classes.selection}>
                                        24 QTY
                                      </p>
                                    </div>
                                  </div>
                                  {/* <Paragraph color="#d0011b" bold="600">
                                    (RM
                                    {parseFloat(
                                      (
                                        productOptionSelectionPriceFor24 / 6
                                      ).toFixed(2)
                                    )}
                                    / PER QTY)
                                  </Paragraph> */}
                                </label>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      <div className={classes.theFlexQ}>
                        <div className={classes.theFlexBox}>

                          <p className={classes.labelQuantity}>Quantity</p>


                          <div className={classes.theFlexQuantity}>
                            <div className={classes.theIncrement}>
                              <IncrementAction
                                margin="0 1rem 0 0"
                                onClick={() => handleChangeQty(count - 1)}
                              >
                                <Remove className={classes.theButtonStyle} />
                              </IncrementAction>

                              {count == 0 ? (
                                <div className={classes.theBox}>
                                  <p className={classes.theCount}>0</p>
                                </div>
                              ) : (
                                <div className={classes.theBox}>
                                  <p className={classes.theCount}>{count}</p>
                                </div>
                              )}

                              <IncrementAction
                                margin="0 0 0 1rem"
                                // onClick={() => setCount(count + 1)}
                                onClick={() => handleChangeQty(count + 1)}
                              >
                                <Add className={classes.theButtonStyle} />
                              </IncrementAction>
                            </div>

                          </div>
                        </div>


                        <div className={classes.theBtn}>
                          <div
                            className={classes.theAddCart}
                            // onClick={() => handleAddToCart()}
                            onClick={() =>
                              handleAddToCart({
                                vertical: "top",
                                horizontal: "right",
                              })
                            }
                          >
                            ADD TO CART
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {productFlavour == "" && productTaste == "" ? (
                <>
                  <div className={classes.theTabList}>
                    <Link onClick={() => handleChangeTab("1")}>
                      <div
                        className={
                          tabs == "1" ? classes.theTabActive : classes.theTab
                        }
                      >
                        <p
                          className={
                            tabs == "1"
                              ? classes.theTextActive
                              : classes.theText
                          }
                        >
                          Detail
                        </p>
                      </div>
                    </Link>
                    {/* <Link onClick={() => handleChangeTab("2")}>
                      <div
                        className={
                          tabs == "2" ? classes.theTabActive : classes.theTab
                        }
                      >
                        <p
                          className={
                            tabs == "2" ? classes.theTextActive : classes.theText
                          }
                        >
                          Review
                        </p>
                      </div>
                    </Link> */}
                  </div>
                  {tabs == "1" && (
                    <>
                      <div className={classes.theContainer}>
                        <p className={classes.title}>About This Product</p>
                        <p
                          className={classes.text}
                          dangerouslySetInnerHTML={{
                            __html: product.description,
                          }}
                        ></p>
                      </div>
                      <div>
                        <Grid container spacing={3}>
                          {productIMGDesp.map((item) => (
                            <Grid xs={12} sm={6} md={4}>
                              <div className={classes.theProdutIMGDescp}>
                                <img src={item.image} />
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </>
                  )}
                  {tabs == "2" && <></>}
                </>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    {productFlavour == "" ? (
                      <></>
                    ) : (
                      <div className={classes.theContainer}>
                        <p className={classes.title}>Awards</p>
                        <Grid container spacing={3}>
                          {productFlavour.map((item) => (
                            <Grid item xs={3} sm={3} md={3}>
                              <div className={classes.theFlavorCard}>
                                <img
                                  className={classes.flavorIMG}
                                  src={item.image}
                                />
                                {/* <p className={classes.theLabel}>
                                  {item.flavour}
                                </p> */}
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    )}

                    {productTaste == "" ? (
                      <></>
                    ) : (
                      <>
                        <div className={classes.theContainer}>
                          <p className={classes.title}>Taste Profile</p>
                          {productTaste.map((item) => (
                            <>
                              <BorderLinearProgress
                                variant="determinate"
                                // value={parseFloat(item.score)*10}
                                value={item.latest_score}
                              />
                              <div className={classes.theFlexTaste}>
                                <p className={classes.theLabel}>
                                  {item.taste_1}{" "}
                                </p>
                                <p className={classes.theLabel}>
                                  {item.taste_2}{" "}
                                </p>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.theTabList}>
                      <Link onClick={() => handleChangeTab("1")}>
                        <div
                          className={
                            tabs == "1" ? classes.theTabActive : classes.theTab
                          }
                        >
                          <p
                            className={
                              tabs == "1"
                                ? classes.theTextActive
                                : classes.theText
                            }
                          >
                            Detail
                          </p>
                        </div>
                      </Link>
                      {/* <Link onClick={() => handleChangeTab("2")}>
                      <div
                        className={
                          tabs == "2" ? classes.theTabActive : classes.theTab
                        }
                      >
                        <p
                          className={
                            tabs == "2" ? classes.theTextActive : classes.theText
                          }
                        >
                          Review
                        </p>
                      </div>
                    </Link> */}
                    </div>
                    {tabs == "1" && (
                      <>
                        <div className={classes.theContainer}>
                          <p className={classes.title}>About This Product</p>
                          <p
                            className={classes.text}
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          ></p>
                        </div>
                        <br />
                        <div>
                          <Grid container spacing={3}>
                            {productIMGDesp.map((item) => (
                              <Grid xs={12} sm={6} md={4}>
                                <div className={classes.theProdutIMGDescp}>
                                  <img src={item.image} />
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </>
                    )}
                    {tabs == "2" && <></>}
                  </Grid>
                </Grid>
              )}
            </Section>
          </Container>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
            autoHideDuration={1000}
            action={
              <React.Fragment>
                <div className={classes.theRoot}>
                  <div className="theFlexing">
                    <div className="theRowing">
                      <ShoppingCart />
                      <p className="theMessage">Added to Cart</p>
                    </div>
                    <Close className="theCloseIcon" onClick={handleClose} />
                  </div>
                </div>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      )}
    </React.Fragment>
  );
};

export default ProductDetail;
