import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FormControl } from "@material-ui/core";

export const config = {
  root: {},
  theHeaderProfile: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    marginBottom: "2rem",
  },
  theForm: {
    position: "relative",
    marginBottom: "1rem",
  },
  theBox: {
    margin: "2rem 0",
  },

  theIMG: {
    width: "70%",
    margin: "0 auto",
  },
  thFlexAdd: {
    alignItems: "center",
    display: "flex",
  },
  theIcon: {
    color: " #1e1e1e",
    marginRight: "1rem",
  },

  theCardAddress: {
    position: "relative",
    border: "1px solid #eaeaea",
    padding: "2rem 1.4rem",
  },

  theRow: {
    alignItems: "center",
    display: "flex",
  },

  theAction: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
  },

  theActionLink: {
    padding: "0 1rem",

    "& :last-child": {
      padding: 0,
    },
  },
  theActionIcon: {
    color: "#1e1e1e",
    cursor: "pointer",
  },
  theSubmit: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    marginTop: "2rem",
  },
};

export const SideBarContainer = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
  padding: 1rem 2rem;

  @media screen and (min-width: 992px) {
    /* width: 90%; */
  }
`;

export const SideBarBox = styled.div`
  position: relative;
  text-align: center;
`;

export const Icon = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Circle = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);

  @media screen and (min-width: 992px) {
    padding: 0.3rem 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const SiderBarList = styled.div`
  position: relative;
`;

export const NavFlexLink = styled(Link)`
  align-items: center;
  display: flex;
  padding: 1rem 0;
`;

export const SmallIcon = styled.div`
  position: relative;
  width: 8%;
  display: flex;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    width: 4%;
  }
  @media screen and (min-width: 992px) {
    width: 10%;
  }
`;

export const SmallIconIMG = styled.img`
  width: 100%;
`;

export const ActiveBorder = styled.div`
  position: relative;
  background: #7f222c;
  width: 4px;
  height: 30px;
  margin-right: 1rem;
`;

export const ModalAddress = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    width: 70%;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 2rem;
`;

export const FormBox = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export default makeStyles(config);
