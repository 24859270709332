import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { md } from "../../styles/responsive";

const config = {
  theProductContainer: {
    position: "relative",
    padding: "1rem 0",
  },

  theProductCard: {
    position: "relative",
    width: " 80%",
    margin: "0 auto",
    display: "block",
    padding: "0 0 1rem 0",
  },

  theProductIMG: {
    position: "relative",
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
    padding: "1rem",
    marginBottom: "1rem",
  },

  theGallery: {
    position: "relative",
    width: "100%",
  },

  theGalleryFlex: {
    position: "relative",
    width: "100%",
    flexDirection: "row",
    flexWrap: "unset",
    overflowX: "auto",
    display: "flex",
  },

  theNavLink: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  theNavLinkActive: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",
    borderWidth: "1px",
    borderColor: "#ececec",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  img: {
    width: "100%",
    height: "100px",
    objectFit: "contain",

    ...md("xs", {
      height: "150px",
    }),
  },

  theDetail: {
    position: "relative",
  },

  theFlex: {
    alignItems: "center",
    display: "flex",
    marginBottom: ".4rem",
  },
};

export const SubCategoryList = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
`;

export const FlexContainer = styled(Link)`
  color: #000;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #d7d7d7;
  padding: 1rem 0;
`;

export const Icon = styled(ChevronRight)`
  color: #000;
`;

export default makeStyles(config);
