import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Close, Search } from "@material-ui/icons";

import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import { get_category, get_sub_category } from "../../api/API";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));

const SidebarMenu = ({ open, onClose, onOpen, handleDrawerToggle }) => {
  const classes = useStyle();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // SEARCH PRODUCT

  const history = useHistory();

  const [search, setSearch] = React.useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    e.persist();
    history.push("/searchproduct/" + encodeURIComponent(search));
    window.location.reload();
  };

  const handleKeyDown = (e) => {
    if(e.keyCode === 13) { 
      e.preventDefault();
      e.persist();
      history.push("/searchproduct/" + encodeURIComponent(search));
      // window.location.reload();
  }
}

  const handleChangeSearch = (e) => {
    e.persist();
    setSearch(e.target.value);
  };

  const [category, setCategory] = React.useState([]);

  const getCategory = () => {
    get_category().then((json) => {
      setCategory(json.data);
    });
  };

  const get_category_data = (category_id) => {
    // console.log(category_id)
    get_sub_category({
      category_id: category_id,
    }).then((result) => {
      if (result) {
        // console.log(result);
        if (result.data[0].category_id == "82") {
          // alert("OK");
          history.push("/expressdelivery");
        } else {
          history.push("/subcategory/" + category_id);
        }
      }
    });
  };

  React.useEffect(() => {
    getCategory();
    get_category_data();
  }, []);

  return (
    <Drawer anchor="left" open={open} onOpen={onOpen} onClose={onClose}>
      {sideDrawerList("left")}
      <List component="nav">
        <NavbarSidebarList>
          <NavbarSidebarListLogo>
            <Link to="/">{/* <img src={clover}/> */}</Link>
          </NavbarSidebarListLogo>
          <CloseButtonBox>
            <Link onClick={handleDrawerToggle}>
              <CloseIcon />
            </Link>
          </CloseButtonBox>
          <SearchContainer>
            {/* <Box>
              <p>All</p>
            </Box> */}
            <SearchField
              placeholder="Search here..."
              value={search}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDown}
            />
            <SearchButtonMoblie
              background={"#e60c0b"}
              color={"#fff"}
              onClick={handleSearchSubmit}
             
            >
              <SearchIcon color="#fff" />
            </SearchButtonMoblie>
          </SearchContainer>
          {category.map((item) => (
            <ListItem>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                onClick={() => get_category_data(item.category_id)}
              >
                <ListItemText primary={item.name} />
              </Link>
            </ListItem>
          ))}
          {/* <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/"
            >
              <ListItemText primary="LOREM IPSUM" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/"
            >
              <ListItemText primary="LOREM IPSUM" />
            </Link>
          </ListItem> */}
        </NavbarSidebarList>
      </List>
    </Drawer>
  );
};

export default SidebarMenu;
