import React from "react";
import Slider from "react-slick";
import { Container, Grid, Modal } from "@material-ui/core";
import { useLocation, Link, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Countdown from "react-countdown";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Footer from "../../components/Footer/Footer";
import SmoothImage from "react-smooth-image";
import pi from '../../assets/images/pi.png'
import logo from "../../assets/images/logo.png";

import {
  CategoryContainer,
  CategoryContainerOverflow,
  CategoryIMG,
  IMG,
  CardArticleContainer,
  ArticleIMGContainer,
  IMGArticle,
  Tag,
  Span,
  FlexDetail,
  CategoryIMGMobile,
  SpanDot,
  FlexDate,
  NewArrivalIMGCard,
  NewArrivalIMG,
  ModalOS,
  ModalHeader,
  FlexIcon,
  ModalBody,
} from "./styles.js";
import banner from "../../assets/images/banner.png";
import applestore from "../../assets/images/applestore.png"
import googlestore from "../../assets/images/googlestore.png"
import useStyles from "./styles";
import {
  new_get_blog,
  get_category,
  get_banner,
  get_banner_page,
  get_sub_category,
  get_new_arrival_product,
} from "../../api/API";
import "./styles.css";
import "./custom.css";
import moment from "moment";
// import { CSSTransition } from 'react-transition-group';
// import Modal from 'react-modal';
import { Close } from "@material-ui/icons";
import useGeneral from "../../hooks/useGeneral";

export default function Home(props) {
  const classes = useStyles();

  const history = useHistory();
  const { get_download_prompt, set_download_prompt } = useGeneral();

  let { Home } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);

  // const [isOpen, setIsOpen] = React.useState(false);

  // const toggleModal = () => setIsOpen(previousState => !previousState);


  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setIsOpen(true)
  //   }, 2000);
  // }, [])


  // const modalStyles = {
  //   overlay: {
  //     backgroundColor: 'rgba(0, 0, 0, 0.8)',
  //   },
  // };

  // const [isMobile, setIsMobile] = React.useState(false);

  // MODAL SHOW ADDRESS

  const [showModal, setShowModal] = React.useState(false);


  const handleOpenModal = () => {
    if (window.screen.width <= 576) {
      setShowModal(get_download_prompt());
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);
    set_download_prompt(true);
  };

  React.useEffect(() => {
    handleOpenModal();
  }, [])

  // if (window.screen.width <= 768) {
  //   return (
  //     <Modal
  //       open={showModal}
  //       onClose={handleCloseModal}
  //       aria-labelledby="simple-modal-title"
  //       aria-describedby="simple-modal-description"
  //     >
  //       <ModalOS>
  //         <ModalHeader>
  //           <FlexIcon onClick={() => handleCloseModal()}>
  //             <Close />
  //           </FlexIcon>
  //         </ModalHeader>
  //         <ModalBody>

  //         </ModalBody>
  //       </ModalOS>
  //     </Modal>
  //   )
  // }


  // API

  const [category, setCategory] = React.useState([]);

  const [blog, setBlog] = React.useState([]);

  const [banner, setBanner] = React.useState([]);

  const [isBusy, setIsBusy] = React.useState(true);

  const [promoDate, setPromoDate] = React.useState("");

  const getCategory = () => {
    get_category().then((json) => {
      setCategory(json.data);
      setIsBusy(false);
    });
  };

  const getBlog = () => {
    new_get_blog().then((json) => {
      setBlog(json.data);
      setIsBusy(false);

      var index = 0;

      for (var i = 0; i < json.data.length; i++) {
        if (json.data[i].offer_date["blog_id"]) {
          index = i;
        }
      }
      setPromoDate(json.data[index].offer_date);
    });
  };

  const getBanner = () => {
    let postparam = {
      page: "Home",
    };
    get_banner_page(postparam).then((json) => {
      setBanner(json.data);
      setIsBusy(false);
    });
  };

  React.useEffect(() => {
    getCategory();
    getBlog();
    getBanner();
    setIsBusy(true);
  }, []);

  const [countdown, setCountdown] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    setTimeout(() => {
      getCountdown();
    }, 100);
  }, []);

  const getCountdown = () => {
    const timeRemaining = new Date(promoDate) - new Date();
    if (timeRemaining) {
      let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      let hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
      let seconds = Math.floor((timeRemaining / 1000) % 60);

      setCountdown({ days: days, hours: hours, minutes, seconds });
    }
  };

  const get_category_data = (category_id) => {
    // console.log(category_id)
    get_sub_category({
      category_id: category_id,
    }).then((result) => {
      if (result) {
        // console.log(result);
        if (result.data[0].category_id == "82") {
          // alert("OK");
          history.push("/expressdelivery");
        } else {
          history.push("/subcategory/" + category_id);
        }
      }
    });
  };

  const [prod, setProd] = React.useState([]);

  React.useEffect(() => {
    get_new_arrival_product().then((json) => {
      setProd(json.data);
    });
  }, []);

  const customList = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Navbar />


      <>

        <>
          {isBusy == true ? (
            <>
              <Loader
                type="TailSpin"
                color="#ff8001"
                height={80}
                timeout={6000}
                width={80}
              />
            </>
          ) : (
            <>
              {banner == null ? (
                <></>
              ) : (
                <Carousel>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </Carousel>
              )}
              <Section pd="1rem 0 0 0">
                <Container>
                  <div className="theHeight">
                    <div className="listing">
                      {category.map((item, index) => (
                        <Link
                          className="card"
                          onClick={() => get_category_data(item.category_id)}
                        >
                          <img src={item.category_image} />
                          <Paragraph margin=".6rem 0" center>
                            {item.name}
                          </Paragraph>
                        </Link>
                      ))}
                    </div>
                  </div>
                </Container>
                <div className={classes.theCategoryMoblie}>
                  <CategoryContainer className="theMobile">
                    {category != null && category.length > 0 && (
                      <>
                        {category.map((item, i) => (
                          <div key={i}>
                            <CategoryIMGMobile
                              onClick={() => get_category_data(item.category_id)}
                            >
                              <IMG src={item.category_image} />
                              <Paragraph center>{item.name}</Paragraph>
                            </CategoryIMGMobile>
                          </div>
                        ))}
                      </>
                    )}
                  </CategoryContainer>
                </div>
              </Section>
              <Container>

                {/* <Paragraph size="500" bold="700" style={{ margin: '1rem 0' }}>New Arrival</Paragraph> */}
                <p className="theTitlex">New Arrival</p>
                {/* <Section> */}
                  <div class="theNewArrivalList">
                    <Slider {...customList}>
                      {prod.map((item) => (
                        <Link
                          className={classes.theProductCard2}
                          to={`../productdetail/${item.product_id}`}
                        >
                          <div className={classes.theProductIMGxx}>
                            {item.max_stock == "" ? (
                              <>
                                {item.stock == 0 ? (
                                  <div className={classes.theOutofStock}>
                                    <Paragraph
                                      size="500"
                                      color="#fff"
                                      bold="600"
                                      center
                                    >
                                      Out Of Stock
                                    </Paragraph>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {item.max_stock == 0 ? (
                                  <div className={classes.theOutofStock}>
                                    <Paragraph
                                      size="500"
                                      color="#fff"
                                      bold="600"
                                      center
                                    >
                                      Out Of Stock
                                    </Paragraph>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}

                            <SmoothImage
                              imageStyles={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                              }}
                              src={item.image}
                            />
                          </div>

                          <div className={classes.theDetail}>
                            {item.single_price == null ? (
                              <Paragraph
                                size="300"
                                color="#f4b14a"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                RM {parseFloat(item.pricing).toFixed(2)}
                              </Paragraph>
                            ) : (
                              <Paragraph
                                size="300"
                                color="#f4b14a"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                RM {parseFloat(item.single_price).toFixed(2)}
                              </Paragraph>
                            )}
                            {item.ori_price != "0.00" && (
                              <div className={classes.theFlex}>
                                <Paragraph
                                  size="300"
                                  color="#878787"
                                  textDecoration="line-through"
                                  bold="600"
                                  margin="0 1rem 0 0"
                                >
                                  RM{parseFloat(item.ori_price).toFixed(2)}
                                </Paragraph>
                              </div>
                            )}
                            <p
                              class="the2Line"
                            >
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </Slider>
                  </div>
                {/* </Section> */}
                <div class="theNewArrivalMobile">
                  {prod.map((item) => (
                    <NewArrivalIMGCard
                      className={classes.theProductCardx}
                      to={`../productdetail/${item.product_id}`}
                    >
                      <div className={classes.theProductIMG}>
                        {item.max_stock == "" ? (
                          <>
                            {item.stock == 0 ? (
                              <div className={classes.theOutofStock}>
                                <Paragraph
                                  // size="400"
                                  color="#fff"
                                  bold="600"
                                  center
                                  style={{fontSize: '.67rem'}}
                                >
                                  Out Of Stock
                                </Paragraph>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            {item.max_stock == 0 ? (
                              <div className={classes.theOutofStock}>
                                <Paragraph
                                  // size="400"
                                  color="#fff"
                                  bold="600"
                                  center
                                  style={{fontSize: '.67rem'}}
                                >
                                  Out Of Stock
                                </Paragraph>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        <NewArrivalIMG src={item.image} />
                      </div>

                      <div className={classes.theDetail}>
                        {item.single_price == null ? (
                          <Paragraph
                            size="300"
                            color="#f4b14a"
                            bold="600"
                            margin="0 0 .2rem 0"
                          >
                            RM {parseFloat(item.pricing).toFixed(2)}
                          </Paragraph>
                        ) : (
                          <Paragraph
                            size="300"
                            color="#f4b14a"
                            bold="600"
                            margin="0 0 .2rem 0"
                          >
                            RM {parseFloat(item.single_price).toFixed(2)}
                          </Paragraph>
                        )}
                        {item.ori_price != "0.00" && (
                          <div className={classes.theFlex}>
                            <Paragraph
                              size="300"
                              color="#878787"
                              textDecoration="line-through"
                              bold="600"
                              margin="0 1rem 0 0"
                            >
                              RM{parseFloat(item.ori_price).toFixed(2)}
                            </Paragraph>
                          </div>
                        )}
                        <p
                          class="the2Line"
                        >
                          {item.name}
                        </p>
                      </div>
                    </NewArrivalIMGCard>
                  ))}
                </div>

                <Section pd="1rem 0 0 0">
                  <Grid container spacing={1}>
                    {blog.map((item) => (
                      <Grid item xs={6} sm={6} md={4}>
                        <CardArticleContainer
                          to={`../articledetail/${item.blog_id}`}
                        >
                          <ArticleIMGContainer>
                            <SmoothImage
                              src={item.image}
                              imageStyle={{
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                            {item.offer_date != "0000-00-00 00:00:00" && (
                              <Tag>
                                <Countdown
                                  date={moment(item.offer_date).format()}
                                  renderer={({ days, hours, minutes, seconds }) => (
                                    <FlexDate className="countdown_date">
                                      {days > 0 && (
                                        <>
                                          <Span> {days}</Span>
                                          <SpanDot>:</SpanDot>
                                        </>
                                      )}

                                      <Span> {hours}</Span>
                                      <SpanDot>:</SpanDot>
                                      <Span> {minutes}</Span>

                                      {days <= 0 && (
                                        <>
                                          <SpanDot>:</SpanDot>
                                          <Span> {seconds}</Span>
                                        </>
                                      )}
                                    </FlexDate>
                                  )}
                                ></Countdown>
                              </Tag>
                            )}
                          </ArticleIMGContainer>
                          <FlexDetail>
                            <Paragraph
                              size="400"
                              color="#1e1e1e"
                              bold="700"
                              margin="0 0 0.4rem 0"
                              className={classes.theTitle}
                            >
                              {item.title}
                            </Paragraph>
                          </FlexDetail>
                        </CardArticleContainer>
                      </Grid>
                    ))}
                  </Grid>
                </Section>
              </Container>
              <Footer />
            </>
          )}
        </>
        <Modal
          open={showModal}
          // onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalOS>
            <ModalHeader>
              {/* <FlexIcon onClick={() => handleCloseModal()}>
                    <Close />
                  </FlexIcon> */}
            </ModalHeader>
            <ModalBody>
              <div className="theLogoStocky">
                <img src={logo} />
              </div>
              {/* <Paragraph style={{ fontSize: 14 }}>
                    STOCKY is an online application for everyone to purchase alcohol that is 100% authentic at an affordable price. This app is user-friendly and hassle-free. Our purpose is to enable everyone to enjoy premium quality alcohol in any circumstance. Our excellent customer service team will also ensure you have a pleasant online shopping experience.
                  </Paragraph> */}
              <div className="theIMGPI">
                <img src={pi} />
              </div>
              <Paragraph bold="700" color="#fff" center style={{ fontSize: 20, marginTop: 14, marginBottom: 14 }}>Download Now for Free</Paragraph>
              <div style={{ flexDirection: 'row', display: 'flex', marginTop: 14 }}>
                <a className="theStoreCon" href="https://apps.apple.com/my/app/stockymy/id1581707114" target="_blank" >
                  <img src={applestore} />
                </a>
                <a className="theStoreCon_" href="https://play.google.com/store/apps/details?id=com.stockyapp" target="_blank">
                  <img src={googlestore} />
                </a>

              </div>
              <Link style={{ fontSize: '18px', color: '#fff', textAlign: 'center', display: 'block', margin: '1.2rem 0', fontWeight: '600' }} onClick={handleCloseModal}>CONTINUE ON WEB</Link>
            </ModalBody>
          </ModalOS>
        </Modal>

      </>



    </React.Fragment >
  );
}
