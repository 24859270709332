import React from "react";
import {
    Container,
    Checkbox,
    Grid,
    Select,
    FormControl,
    MenuItem,
    Modal,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import stripecardasset from '../../assets/images/stripe-badge-transparent.png';
import fpxcardasset from '../../assets/images/fpx_badge.png';
import "./custom.css";

import { StripeProvider } from "react-stripe-elements";
import {
    CardElement,
    FpxBankElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import StatusMessages, { useMessages } from "./StatusMessages";

import { Button } from "../../components/Button/Button";
import { stripe_payment, get_order_by_intent } from "../../api/API";

import useStyles from "./styles";

// import "./Checkout.css";
// import {
//   get_delivery_time,
//   get_user_address,
//   user_address,
//   get_state,
//   purchase,
//   get_delivery_type,
// } from "../../api/API";

import useCart from "../../hooks/useCart";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe(
    "pk_live_51IsMxSAWCzsiK49t0CQf1CYMklCZJ6BGF90MNoplivpy7c7z1BkvHxSF0gH8m7KqP7i5mTN6Bel0jKO81lzEXQdV00KMeCCftD"
);


export default function PaymentPage(props) {
    const classes = useStyles();

    let { Payment } = useLocation();
    const [clientSecret, setClientSecret] = React.useState("");
    const [paymentId, setPaymentId] = React.useState(0);
    const history = useHistory();
    const [checkoutType, setCheckoutType] = React.useState('card');
    const { clearCart } = useCart();

    React.useEffect(() => {
        if (props.match.params.type != undefined) {
            setCheckoutType(props.match.params.type);
        }
        if (history.location.state) {
            if (history.location.state.secret != undefined) {
                setClientSecret(history.location.state.secret);
                setPaymentId(history.location.state.payment_id);
            } else {
                alert("FAIL");
            }
        } else {
            alert("FAIL");
        }

        window.scrollTo(0, 0);

        // stripe_payment().then((res) => {
        //   if (res.status) {
        //     setClientSecret(res.data.client_secret);
        //   } else {
        //     alert("FAIL, STOP TRANSACTION");
        //   }
        // });
    }, [Payment]);

    const [tabs, setTab] = React.useState(1);

    const handleChangeTab = (text) => {
        setTab(text);
    };

    return (
        <React.Fragment>
            <Navbar />
            <Container>
                <Section>
                    <div className={classes.theContainer}>
                        {/* <div className={classes.theTabList}>
                            <h1>CARD TYPE</h1>
                            <div className={classes.theFlex}>
                                <Link
                                    className={
                                        tabs == "1" ? classes.theBoxActive : classes.theBox
                                    }
                                    onClick={() => handleChangeTab(1)}
                                >
                                    Credit Card
                                </Link>
                                <Link
                                    className={tabs == 2 ? classes.theBoxActive : classes.theBox}
                                    onClick={() => handleChangeTab(2)}
                                >
                                    FPX Card
                                </Link>
                            </div>
                        </div> */}
                        <div className={classes.theTable}>
                            {checkoutType == 'card' && (
                                <Elements stripe={stripePromise}>
                                    <CardForm clientSecret={clientSecret}></CardForm>
                                </Elements>
                            )}
                            {checkoutType == 'fpx' && (
                                <Elements stripe={stripePromise}>
                                    <FpxForm clientSecret={clientSecret} payment_id={paymentId}></FpxForm>
                                </Elements>
                            )}
                        </div>
                    </div>
                </Section>
            </Container>
        </React.Fragment>
    );
}

const CardForm = ({ clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [messages, addMessage] = useMessages();
    const classes = useStyles();
    const history = useHistory();
    const { clearCart } = useCart();
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        stripe
            .confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            })
            .then((payload) => {
                if (payload.error) {
                    alert("Payment failed");
                    history.push("/checkout");
                } else {
                    console.log(payload);
                    clearCart();
                    get_order_by_intent({
                        id: history.location.state.payment_id
                    }).then(res => {
                        history.push("/orderdetail/" + res.data.orders_id);
                    });
                }
                setIsLoading(false);
            }).catch(e => {
                setIsLoading(false);
            });
    };
    return (
        <>
            <h3>CARD</h3>

            <CardElement
                options={{
                    style: {
                        base: {
                            padding: "10px 12px",
                            color: "#32325d",
                            fontFamily:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                            fontSmoothing: "antialiased",
                            fontSize: "16px",
                            "::placeholder": {
                                color: "#aab7c4",
                            },
                        },
                        invalid: {
                            color: "#fa755a",
                        },
                    },
                }}
            />
            <br />
            {!isLoading ? (
                <button className={classes.theButton} onClick={handleSubmit}>
                    SUBMIT
                </button>
            ) : (
                <div style={{
                    textAlign: "center"
                }}>
                    <CircularProgress></CircularProgress>
                </div>
            )}
            <img style={{
                width: "100%"
            }} src={stripecardasset}></img>
        </>
    );
};

const FpxForm = ({ clientSecret, payment_id }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [messages, addMessage] = useMessages();
    const classes = useStyles();

    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const { clearCart } = useCart();

    const handleSubmit = async (e) => {

        if (isLoading) {
            return;
        }
        setIsLoading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();


        get_order_by_intent({
            id: payment_id
        }).then(res => {
            stripe
                .confirmFpxPayment(clientSecret, {
                    payment_method: {
                        fpx: elements.getElement(FpxBankElement),
                    },
                    return_url: "https://" + window.location.host + "/orderdetail/" + res.data.orders_id
                })
                .then((payload) => {
                    if (payload.error) {
                        alert("Payment failed");
                        history.push("/checkout");
                    } else {
                        console.log(payload);
                        clearCart();
                        get_order_by_intent({
                            id: history.location.state.payment_id
                        }).then(res => {
                            history.push("/orderdetail/" + res.data.orders_id);
                        });
                    }
                    setIsLoading(false);
                }).catch(e => {
                    setIsLoading(false);
                });
        });

    };

    return (
        <>
            <h3>FPX</h3>

            <form id="payment-form" onSubmit={handleSubmit}>
                <FpxBankElement options={{ accountHolderType: "individual" }} />
                <br />
                {!isLoading ? (
                    <button className={classes.theButton} onClick={handleSubmit}>
                        SUBMIT
                    </button>
                ) : (
                    <div style={{
                        textAlign: "center"
                    }}>
                        <CircularProgress></CircularProgress>
                    </div>
                )}

            </form>

            <StatusMessages messages={messages} />
            <div style={{
                width: "100%",
                textAlign: "center"
            }}>
                <img src={fpxcardasset}
                    style={{
                        marginTop: 10,

                        width: "30%"
                    }} />
            </div>

        </>
    );
};