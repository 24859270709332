import React from "react";
import { Container, Grid, Popover, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./Footer.css";
import FormInput from "../FormInput/FormInput";
import { ChatBubble, Image, WhatsApp } from "@material-ui/icons";
import Section from "../Section/Section";
import Paragraph from "../Typography/Paragraph";
import useStyles from "./styles";
import product from "../../assets/images/wine.png"


export default function Footer() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const [form, setForm] = React.useState({
    message: "",
    img: ""

  })

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleUploadIMG = (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("img", e.target.name);
    formData.append("file", file);

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    console.log(file);
  }

  const handleSend = () => {

    console.log(form);
  }

  return (
    <React.Fragment>
      <div className="theFooter">
        <Container>
          <div className="theFooterList">
            <div className="theFooterLogo">
              <img src={logo} />
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={3} md={3}>
              <div className="theFooterList">
                <p className="theTitle">Product</p>
                <Link className="theNavLink" to="/subcategory/17">Sake</Link>
                <Link className="theNavLink" to="/subcategory/18">Liquor</Link>
                <Link className="theNavLink" to="/subcategory/47">Wine</Link>
                <Link className="theNavLink" to="/subcategory/52">Soft Drinks</Link>
              </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <div className="theFooterList">
                <p className="theTitle">About Us</p>
                <a
                  href="https://stocky.com.my/FAQ%20STOCKY.pdf"
                  className="theNavLink"
                  target="_blank"
                >
                  FAQ
                </a>
                <a
                  href="https://stocky.com.my/STOCKY -COOKIE.pdf"
                  className="theNavLink"
                  target="_blank"
                >
                  Cookie Policy
                </a>

                <a
                  href="https://stocky.com.my/TERM&COND STOCKY.pdf"
                  className="theNavLink"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                <a
                  href="https://stocky.com.my/privacy_policy.pdf"
                  className="theNavLink"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="theFooterList">
                <div className="theAbout">
                  <p className="bold">About Stocky</p>
                  <p className="descp">
                    STOCKY is an online application for everyone to purchase
                    alcohol that is 100% authentic at an affordable price. This
                    app is user-friendly and hassle-free. Our purpose is to
                    enable everyone to enjoy premium quality alcohol in any
                    circumstance. Our excellent customer service team will also
                    ensure you have a pleasant online shopping experience.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="theFooterList">
                <div className="theAbout">
                  <p className="bold">Contact Us</p>
                  <p className="descp">TEL :+6010-772 0002</p>
                  <p className="descp">
                    EMAIL :support@stocky.com.my to learn more about this app or
                    our products and services
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="theCopyright">
            <p>Copyright © 2021 Stocky</p>
          </div>
        </Container>
      </div>
      <a className="theFloating" href="https://wa.me/60107720002" target="_blank">
        <WhatsApp className="theIcon" />
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}

        className={classes.theBox}
      >
        <Section pd="0">
          <div className="theHeader">
            <Paragraph center color="#fff">Stocky Chat</Paragraph>
          </div>
          <div className="theSectionMessage">
            <ul>
              <li
              // style={{
              //   flexDirection:
              //     username === msg.receiver ? "row" : "row-reverse",
              // }}
              // key={index}
              >
                <div className="user-pic">
                  {/* <img src={require(`../users/${msg.avatar}`).default} /> */}
                </div>
                <div>
                  {/* {msg.media && msg.media.image ? ( */}
                  <div className="image-container">
                    <img src={product} width="200" alt="" />
                  </div>
                  {/* ) : null} */}
                  {/* {msg.message !== "" ? ( */}
                  <div className="message-text">LOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUMLOREM IPSUM LOREM IPSUM</div>
                  {/* ) : null} */}
                </div>
              </li>
            </ul>
          </div>
          <div className="theBottomText">
            {/* <div>
              <img src="" />
            </div> */}
            <div className="theFlex">
              <TextField
                className="theFormControl"
                placeholder="Text Here..."
                name="message"
                multiline
                rows={2}
                onChange={handleChange}
              />
              <div className="theInputFile">
                <input
                  type="file"
                  // onChange={(e) => {
                  //   const file = e.target.files[0];
                  //   const reader = new FileReader();
                  //   reader.readAsDataURL(file);
                  //   reader.onload = function () {
                  //     console.log(reader.result);
                  //     // setMedia({
                  //     //   image: true,
                  //     //   content: reader.result,
                  //     //   name: file.name,
                  //     // });
                  //   };
                  //   reader.onerror = function (error) {
                  //     console.log(error);
                  //   };
                  // }}
                  name="img"
                  onChange={handleUploadIMG}
                  id="hidden-file"
                />
                <label htmlFor="hidden-file">
                  <Image />
                </label>
              </div>


              <Link className="theSendBtn" onClick={() => handleSend()}>SEND</Link>
            </div>

          </div>
        </Section>
      </Popover>
    </React.Fragment>
  );
}
