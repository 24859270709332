import React from "react";
import "./styles.css";
import {
  Container,
  Checkbox,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Modal,
  TextField,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
  ModalTime,
  ModalAddress,
  ModalHeader,
  FlexIcon,
  ModalBody,
  ModalBodyTime,
  FormBox,
  FormInputSelect,
  DropdownList,
} from "./styles";
import useStyles, { PhoneInput } from "./styles";
import { Add, Close, Edit, Remove } from "@material-ui/icons";
import pin from "../../assets/images/pin.svg";
import LoginModal from "./LoginModal";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import {
  calculate2,
  get_delivery_time,
  // get_delivery_slot,
  check_voucher_code,
  get_user_profile,
  shop_purchase_phase_2_new,
  get_state,
  check_city,
} from "../../api/API";

import useCart from "../../hooks/useCart";

import useAddress from '../../hooks/useAddress';

// const libraries = ["places"];

// const mapContainerStyle = {
//   height: "400px",
// };

export default function Checkout(props) {
  const classes = useStyles();
  let { Checkout } = useLocation();
  const [shippingType, setShippingType] = React.useState("standard");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    var token = window.localStorage.getItem("token");
    if (token == "" || token == undefined || token == 'undefined') {
      setOpenLoginModal(true);

    }
  }, [Checkout,]);


  const { getUserAddress, clearAddress } = useAddress();

  const [userAddress, setUserAddress] = React.useState(getUserAddress());


  // const [userAddress_, setUserAddress] = React.useState(JSON.parse(localStorage.getItem('address')));

  // var userAddress = userAddress_[0];

  // React.useEffect(() => {
  //   if (userAddress.address) {

  //     setUserAddress(getUserAddress());
  //     // getDeliveryNowTime();
  //   } else {

  //     if (localStorage.getItem("address") != []) {

  //       var localAddress = JSON.parse(localStorage.getItem("address"))

  //       setUserAddress(localAddress[0]);
  //     }


  //   }
  // }, []);

  console.log('userAddress', userAddress)

  const [deliveryNowTime, setDeliveryNowTime] = React.useState();

  // const getDeliveryNowTime = () => {
  //   get_delivery_slot().then(json => {
  //     setDeliveryNowTime(json.data[0].time);
  //     alert(JSON.stringify(json));
  //   });
  // };


  const [shippingPriceSelection, setShippingPriceSelection] = React.useState({
    standard: null,
    instant: null,
  });
  const [openLoginModal, setOpenLoginModal] = React.useState(false);

  const handleCloseLoginModal = () => {
    var token = window.localStorage.getItem("token");
    if (token != undefined && token != '') {
      get_user_profile({}).then((res) => {
        if (res.status) {
          setUserDetail({
            name: res.data.name,
            contact: res.data.contact
          });
        }
      });
    }
    setOpenLoginModal(false);
    setUserAddress(getUserAddress());
    // getDeliveryNowTime();
  };



  const [count, setCount] = React.useState(1);

  const history = useHistory();

  // GET DELIVERY TIME

  const [deliveryDate, setDeliveryDate] = React.useState({
    date: moment().format("YYYY-MM-DD").split("T").slice(0, 4).join(" "),
  });


  // GET STATE

  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    get_state().then((json) => {
      setState(json.data);
    });
  }, []);
  //

  const [timeSlot, setTimeSlot] = React.useState([]);

  const [showDateTimeSection, setShowDateTimeSection] = React.useState(true);

  const [chosseDeliveryMethod, setChooseDeliveryMethod] = React.useState("");

  const handleChooseDevileryMethod = () => {
    // setChooseDeliveryMethod()
  };

  const [voucherApplied, setVoucherApplied] = React.useState(null);
  // MODAL STANDARD DELIVERY TIME

  const [openStandardTimeModal, setOpenStandardTimeModal] =
    React.useState(false);

  React.useEffect(() => {
    // console.log("TEST SHIPPING TYPE", shippingType);
    handleDateSlot();
  }, [shippingType]);
  const handleOpenStandardTimeModal = () => {
    setShippingType("standard");
    setShowDateTimeSection(false);
    validate_promotion();
  };
  const handleCloseStandardTimeModal = () => {
    setShippingType("standard");
    // setOpenStandardTimeModal(false);
    // setTimeSlot("");
  };

  // MODAL INSTANT DELIVERY TIME

  const [openInstantTimeModal, setOpenInstantTimeModal] = React.useState(false);

  const handleOpenInstantTimeModal = () => {
    setShippingType("instant");
    setShowDateTimeSection(true);
    validate_promotion();
    // setOpenInstantTimeModal(true);
  };
  const handleCloseInstantTimeModal = () => {
    setShippingType("instant");
    // setOpenInstantTimeModal(false);
    // setTimeSlot("");
  };

  const handeleCheckDeiveryTime = () => {
    // get_delivery_slot(deliveryDate).then((json) => {
    //   if (json.status) {
    //     setTimeSlot(json.data);
    //     setDisplayDeliveryTime({
    //       time: json.data[0].time,
    //     });
    //   } else {
    //     setDisplayDeliveryTime({
    //       time: "",
    //     });
    //     setTimeSlot([]);
    //   }
    // });
  };

  const handleChangeDate = (e) => {
    setDeliveryDate({
      date: e.target.value,
    });
    get_delivery_time({ date: e.target.value }).then((json) => {
      if (json.status) {
        setTimeSlot(json.data);
        setDisplayDeliveryTime({ time: json.data[0].time });
      } else {
        setTimeSlot([]);
        setDisplayDeliveryTime({ time: "" });
        alert(json.message);
        // setDeliveryDate({
        //   date: moment().format("YYYY-MM-DD").split("T").slice(0, 4).join(" "),
        // })
      }
    });
  };

  const [displayDeliveryTime, setDisplayDeliveryTime] = React.useState({
    time: "",
  });

  const handleDateSlot = () => {
    get_delivery_time({
      date: moment().format("YYYY-MM-DD").split("T").slice(0, 4).join(" "),
    }).then((json) => {
      if (json.status) {
        setTimeSlot(json.data);
        setDisplayDeliveryTime({ time: json.data[0].time });
      } else {
        setTimeSlot([]);
      }
    });
  };

  React.useEffect(() => {
    // handleDateSlot();
  }, [])

  React.useEffect(() => {


    var token = window.localStorage.getItem("token");
    if (token != undefined && token != '') {
      get_user_profile({}).then((res) => {
        if (res.status) {
          setUserDetail({
            name: res.data.name,
            contact: res.data.contact
          });
        }
      });
    }
  }, []);

  // CHECK PROMO CODE AVIALABLE

  const [promoCode, setPromoCode] = React.useState({
    voucher_code: "",
  });

  const [promoCodePrice, setPromoCodePrice] = React.useState("0.00");

  // const [totalPrice, setTotalPrice] = React.useState("");

  const handleChangePromo = (e) => {
    setPromoCode({
      ...promoCode,
      [e.target.name]: e.target.value,
    });
  };


  const validate_promotion = (voucher) => {
    if (voucher == null) {
      return;
    }

    var discount_value = 0;

    if (getTotalCart(shippingType) < voucher.min_spend) {
      alert("You have to spend above RM" + voucher.min_spend + " to use this voucher");
      setPromoCodePrice("0.00");
      return;
    }

    if (voucher.voucher_type == 2) {

      var new_shipping = {
        ...shippingPriceSelection
      }

      if (voucher.express == "1") {
        new_shipping['instant'] = new_shipping.instant == null ? null : 0;
      }
      if (voucher.standard == "1") {
        new_shipping['standard'] = new_shipping.standard == null ? null : 0;
      }
      setShippingPriceSelection({
        ...new_shipping
      });

      if (shippingType == 'instant' && voucher.express == '1') {
        if (getTotalCart(shippingType) >= parseInt(voucher.additional_discount_min_spend)) {
          setPromoCodePrice(voucher.additional_discount);
        }
      }
      if (shippingType == 'standard' && voucher.standard == '1') {
        if (getTotalCart(shippingType) >= parseInt(voucher.additional_discount_min_spend)) {
          setPromoCodePrice(voucher.additional_discount);
        }
      }

    } else {
      if (voucher.value_type == "FLAT") {
        discount_value = voucher.value;
      } else {
        var temp_value = getTotalCart(shippingType) * voucher.value / 100;
        discount_value = temp_value > voucher.max_discount ? voucher.max_discount : temp_value;
      }
      setPromoCodePrice(discount_value);
    }
  };

  
  const [checkedPromo, setCheckedPromo] = React.useState("");

  const handleChangeSubmitPromo = () => {
    // calculate_cart();
    check_voucher_code(promoCode).then((json) => {
      if (json.status) {
        
        setVoucherApplied(json.data);
        validate_promotion(json.data);
        setCheckedPromo(promoCode.voucher_code);
        // var discount_value = 0;
        //   if (getTotalCart(shippingType) < json.data.min_spend) {
        //     alert("You have to spend above RM" + json.data.min_spend + " to use this voucher");
        //     return;
        //   }



        //   if (json.data.voucher_type == 2) {
        //     // discount_value = shippingPriceSelection.standard;

        //     console.log("BEFORE ", shippingPriceSelection);
        //     var new_shipping = {
        //       ...shippingPriceSelection
        //     }

        //     if (json.data.express == "1") {
        //       new_shipping['instant'] = new_shipping.instant == null ? null : 0;
        //     }
        //     if (json.data.standard == "1") {
        //       new_shipping['standard'] = new_shipping.standard == null ? null : 0;
        //     }

        //     // for (var key in new_shipping) {
        //     //   if (new_shipping[key] != null) {
        //     //     new_shipping[key] = 0;
        //     //   }
        //     // }
        //     setShippingPriceSelection({
        //       ...new_shipping
        //     });
        //   } else {
        //     if (json.data.value_type == "FLAT") {
        //       discount_value = json.data.value;
        //     } else {
        //       var temp_value = getTotalCart(shippingType) * json.data.value / 100;
        //       discount_value = temp_value > json.data.max_discount ? json.data.max_discount : temp_value;
        //     }
        //     setPromoCodePrice(discount_value);
        //   }

          alert("Promo Code Applied");

      } else {
        alert("Promotion Code Not Valid");
        calculate_cart();
      }

    });
  };

  // ADD ADDRESS

  const [userDetail, setUserDetail] = React.useState({
    name: "",
    contact: ""
  });

  const [addressData, setAddressData] = React.useState({
    name: "",
    contact: "",
    unit: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
  });

  const [giftData, setGiftData] = React.useState({
    sender_name: "",
    receiver_name: "",
    message: ""
  })

  const [isEnabled, setIsEnabled] = React.useState(false);

  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  const { is_active } = addressData;

  const handleChange = (event) => {
    if (event.target.name == 'name' || event.target.name == 'contact') {
      setUserDetail({
        ...userDetail,
        [event.target.name]: event.target.value
      });
    } else {
      setAddressData({
        ...addressData,
        [event.target.name]: event.target.value,
      });
      setDeliveryDate({
        ...deliveryDate,
        [event.target.name]: event.target.value,
      });
      setDisplayDeliveryTime({
        ...displayDeliveryTime,
        [event.target.name]: event.target.value,
      });
      setGiftData({
        ...giftData,
        [event.target.name]: event.target.value,
      })

      // setUserAddress({
      //   ...userAddress,
      //   [event.target.name]: event.target.value,
      // })
    }

  };

  const handleSubmitDeliveryTime = () => {
    if (displayDeliveryTime.time == "") {
      alert("Please Choose a Delivery Time");
    } else {
      setOpenStandardTimeModal(false);
      setOpenInstantTimeModal(false);
    }
    // setTimeSlot("");
  };

  // AUTO COMPLETE MAP

  const handleChangeCheck = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.checked,
    });
  };

  // CHECKOUT CART

  const [token, setToken] = React.useState("");

  let user_id = window.localStorage.getItem("user_id");

  const {
    getCart,
    setCartFunction,
    increaseProduct,
    getTotalCart,
    decreaseProduct,
    removeProduct,
    clearCart,
    hasUpdate,
    get_by_shipping,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const productCartList = getcart.replace(/\\/g, "");

  const [cart, setCart] = React.useState(getCart);

  const [cartCount, setCartCount] = React.useState(0);

  React.useEffect(() => {

    setCart(getCart());
  }, [cartCount]);

  const removeCartProduct = (index) => {
    removeProduct(index);
    setCartCount(cartCount + 1);
    window.location.reload();
  };

  // CHECK TOKEN OR GO LOGIN

  const handleToLogin = () => {
    alert("Please login first to proceed this action.");
    history.push("/login");
  };
  const [instantShippingError, setInstantShippingError] = React.useState("");

  const [shippingFee, setShippingFee] = React.useState("");

  //

  const [checkoutData, setCheckoutData] = React.useState({
    product: productCartList,
  });

  const handleChangeCheckout = (event) => {
    setCheckoutData({
      ...checkoutData,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {

  }
    , [addressData]);

  const [hiddenArea, setHiddenArea] = React.useState(true);
  const calculate_cart = (replace_shipping = true) => {
    var cartLength = getCart().length;
    if (cartLength == 0) {
      return false;
    }

    let postparam = {
      city: userAddress.city,
      state: userAddress.state,
      unit: addressData.unit,
      product: JSON.stringify(get_by_shipping(shippingType)),

    };

    // console.log("POST PARAM", postparam);

    calculate2(postparam).then((json) => {

      if (json.status) {
        setInstantShippingError("");
        setPromoCode("");
        setPromoCodePrice(0);
        if (json.shipping) {
          var shipping = {
            standard: null,
            instant: null,
          };
          for (let i = 0; i < json.shipping.length; i++) {
            if (json.shipping[i].label == "Standard") {
              shipping.standard = json.shipping[i].price;
            } else {
              shipping.instant = json.shipping[i].price;
            }
          }

          setShippingPriceSelection(shipping);
          // console.log(shippingPriceSelection);
          setShippingFee(
            parseFloat(shippingPriceSelection[shippingType]).toFixed(2)
          );

          // console.log("SHIPPING FEE", shipping);

          if (shipping["instant"]) {
            setShippingType("instant");
          } else {
            setShippingType("standard");
          }

        } else {
          alert(json.message);
          window.location.reload();
        }
        // setHiddenArea(true);
      } else {
        if (json.t == 5) {
          setInstantShippingError("This area is not covered by instant delivery yet");

          setHiddenArea(false);
        } else {
          setInstantShippingError("");
        }
        // alert(json.message)
        setHiddenArea(false);
      }

    });
  };



  React.useEffect(() => {
    calculate_cart();
  }, []);


  React.useEffect(() => {
    calculate_cart();
  }, [hiddenArea]);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleCheckout = (checkoutType) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (shippingType == "instant") {
      let token = window.localStorage.getItem("token");

      if (token == "" || token == undefined || token == 'undefined') {
        setOpenLoginModal(true);
        setIsLoading(false);
        return;
      }

      var cartLength = getCart().length;
      if (cartLength == 0) {
        alert("Your cart is empty.");
        setIsLoading(false);
        return false;
      }
      console.log("checkoutData", checkoutData);
      console.log("addressData", addressData);

      if (
        userDetail.name == "" ||
        // addressData.address == "" ||
        // addressData.postcode == "" ||
        // addressData.city == "" ||
        // addressData.state == "" ||
        userDetail.contact == ""
      ) {
        alert("Please fill up all the fields");
        setIsLoading(false);
        return;
      }

      let postparam = {
        ...checkoutData,
        // ...addressData,
        ...userAddress,
        date: userAddress.date,
        time: userAddress.time,
        ...giftData,
        deliveryType: shippingType,
        total_amount: parseFloat(
          getTotalCart(shippingType) +
          parseFloat(shippingPriceSelection[shippingType]) -
          promoCodePrice
        ).toFixed(2),
        shipping_fee: parseFloat(shippingPriceSelection[shippingType]).toFixed(2),
        token: token,
        promocode: checkedPromo,
        // temporary
        payment_type: checkoutType,
        shop_id: window.localStorage.getItem("shopID"),

        ...userDetail,
      };
      shop_purchase_phase_2_new(postparam).then((res) => {
        if (res.status) {
          // console.log(props);
          props.history.push("/payment/" + checkoutType, {
            secret: res.data.client_secret,
            payment_id: res.data.id,
          });
          // clearCart();
          setIsLoading(false);
        } else {
          alert(res.message);
          setIsLoading(false);
        }
      }).catch(res => {
        setIsLoading(false);
      });
    } else {
      let token = window.localStorage.getItem("token");

      if (token == "" || token == undefined || token == 'undefined') {
        setOpenLoginModal(true);
        setIsLoading(false);
        return;
      }

      var cartLength = getCart().length;
      if (cartLength == 0) {
        alert("Your cart is empty.");
        setIsLoading(false);
        return false;
      }
      // console.log("checkoutData", checkoutData);
      // console.log("addressData", addressData);

      if (
        userDetail.name == "" ||
        addressData.address == "" ||
        addressData.postcode == "" ||
        addressData.city == "" ||
        addressData.state == "" ||
        userDetail.contact == ""
      ) {
        alert("Please fill up all the fields");
        setIsLoading(false);
        return;
      }

      // console.log(checkoutData, addressData, deliveryDate, displayDeliveryTime, giftData, shippingType, promoCode, checkoutType);
      let postparam = {
        ...checkoutData,
        ...addressData,
        ...giftData,
        deliveryType: shippingType,
        total_amount: parseFloat(
          getTotalCart(shippingType) +
          parseFloat(shippingPriceSelection[shippingType]) -
          promoCodePrice
        ).toFixed(2),
        shipping_fee: parseFloat(shippingPriceSelection[shippingType]).toFixed(2),
        token: token,
        promocode: checkedPromo,
        // temporary
        payment_type: checkoutType,

        ...userDetail,
      };
      shop_purchase_phase_2_new(postparam).then((res) => {
        if (res.status) {
          // console.log(props);
          props.history.push("/payment/" + checkoutType, {
            secret: res.data.client_secret,
            payment_id: res.data.id,
          });
          // clearCart();
          setIsLoading(false);
        } else {
          alert(res.message);
          setIsLoading(false);
        }
      }).catch(res => {
        setIsLoading(false);
      });
    }
  };

  React.useEffect(() => {
    // calculate_cart();
    // console.log("TEST");
  }, [increaseProduct, decreaseProduct]);


  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}>
              <Card>
                <Paragraph
                  size="500"
                  bold="600"
                  color="#1e1e1e"
                  margin="0 0 1.8rem 0"
                >
                  Shipping Address
                </Paragraph>

                {shippingType == "standard" && (
                  <>
                    <FormInput
                      bg="#fff"
                      placeholder="Full Name"
                      name="name"
                      label="Full Name"
                      value={userDetail.name}
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChange}
                    />
                    <Paragraph color="#1e1e1e" bold="600" margin="1rem 0" style={{ fontSize: "1rem" }}>Contact Number</Paragraph>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderLeft: "1px solid #d7d7d7",
                          borderTop: "1px solid #d7d7d7",
                          borderBottom: "1px solid #d7d7d7",
                          backgroundColor: "#fff",
                          padding: "10px 5px",
                          color: "#909399",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          width: "50px",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      >
                        +60
                      </div>
                      <PhoneInput
                        placeholder="Contact Number"
                        name="contact"
                        onChange={handleChange}
                        value={userDetail.contact}
                        type="number"
                      />
                    </div>

                    <FormInput
                      bg="#fff"
                      placeholder="Unit/Block"
                      name="unit"
                      label="Unit/Block"
                      color="#1e1e1e"
                      bold="600"
                      value={addressData.unit}
                      onChange={handleChange}
                    />
                    <FormInput
                      bg="#fff"
                      placeholder="Address"
                      name="address"
                      label="Address"
                      color="#1e1e1e"
                      bold="600"
                      value={addressData.address}
                      onChange={handleChange}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormInput
                          bg="#fff"
                          placeholder="Postcode"
                          name="postcode"
                          label="Postcode"
                          color="#1e1e1e"
                          bold="600"
                          value={addressData.postcode}
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormInput
                          bg="#fff"
                          placeholder="City"
                          name="city"
                          label="City"
                          color="#1e1e1e"
                          bold="600"
                          value={addressData.city}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {/* <FormInput
                      bg="#fff"
                      placeholder="State"
                      name="state"
                      label="State"
                      color="#1e1e1e"
                      bold="600"
                      value={addressData.state}
                      onChange={handleChange}
                    /> */}
                        <Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >State</Paragraph>
                        <FormInputSelect>
                          <Select
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="state"
                            value={addressData.state}
                          >
                            {state.map((item) => (
                              <MenuItem key={item.name} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormInputSelect>
                      </Grid>
                      {instantShippingError && (
                        <Grid item xs={12}>
                          <p style={{
                            color: "red"
                          }}>This area is not supported for instant shipping yet</p>
                        </Grid>
                      )}

                    </Grid>
                    <div className={classes.theDivider}></div>
                    <br />
                    <div className="theSubTitle">
                      <Paragraph
                        size="500"
                        bold="600"
                        color="#1e1e1e"
                        margin="0 0 1.4rem 0"
                      >
                        Shipping Method
                      </Paragraph>
                    </div>

                    <Grid container spacing={2}>

                      {hiddenArea == false ? (
                        <></>
                      ) : (
                        <>
                          {cart != undefined && cart.length > 0 ? (
                            <>
                              {shippingPriceSelection.instant != null && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    onClick={() => handleOpenInstantTimeModal()}
                                    className={
                                      shippingType == "instant"
                                        ? classes.shipping_selection_active
                                        : classes.shipping_selection
                                    }
                                  >
                                    Express (1 hour)

                                    <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM {parseFloat(shippingPriceSelection.instant).toFixed(2)}</Paragraph>
                                  </Grid>
                                </>
                              )}
                              {shippingPriceSelection.standard != null && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    onClick={() => handleOpenStandardTimeModal()}
                                    className={
                                      shippingType == "standard"
                                        ? classes.shipping_selection_active
                                        : classes.shipping_selection
                                    }
                                  >
                                    Standard (3-5days)

                                    <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM {parseFloat(shippingPriceSelection.standard).toFixed(2)}</Paragraph>
                                  </Grid>
                                </>
                              )}
                            </>
                          ) : null}

                        </>
                      )}


                    </Grid>

                  </>
                )}

                {shippingType == "instant" && (
                  <>
                    <FormInput
                      bg="#fff"
                      placeholder="Full Name"
                      name="name"
                      label="Full Name"
                      value={userDetail.name}
                      color="#1e1e1e"
                      bold="600"
                      onChange={handleChange}
                    />
                    <Paragraph color="#1e1e1e" bold="600" margin="1rem 0" style={{ fontSize: "1rem" }}>Contact Number</Paragraph>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderLeft: "1px solid #d7d7d7",
                          borderTop: "1px solid #d7d7d7",
                          borderBottom: "1px solid #d7d7d7",
                          backgroundColor: "#fff",
                          padding: "10px 5px",
                          color: "#909399",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          width: "50px",
                          textAlign: "center",
                          fontWeight: "400",
                        }}
                      >
                        +60
                      </div>
                      <PhoneInput
                        placeholder="Contact Number"
                        name="contact"
                        onChange={handleChange}
                        value={userDetail.contact}
                        type="number"
                      />
                    </div>

                    <FormInput
                      bg="#fff"
                      placeholder="Unit/Block"
                      name="unit"
                      label="Unit/Block"
                      color="#1e1e1e"
                      bold="600"
                      value={userAddress.unit}
                      onChange={handleChange}
                      disabled
                    />
                    <FormInput
                      bg="#fff"
                      placeholder="Address"
                      name="address"
                      label="Address"
                      color="#1e1e1e"
                      bold="600"
                      value={userAddress.address}
                      onChange={handleChange}
                      disabled
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormInput
                          bg="#fff"
                          placeholder="Postcode"
                          name="postcode"
                          label="Postcode"
                          color="#1e1e1e"
                          bold="600"
                          value={userAddress.postcode}
                          type="number"
                          onChange={handleChange}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormInput
                          bg="#fff"
                          placeholder="City"
                          name="city"
                          label="City"
                          color="#1e1e1e"
                          bold="600"
                          value={userAddress.city}
                          onChange={handleChange}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormInput
                          bg="#fff"
                          placeholder="State"
                          name="state"
                          label="State"
                          color="#1e1e1e"
                          bold="600"
                          value={userAddress.state}
                          onChange={handleChange}
                          disabled
                        />
                      </Grid>
                      {instantShippingError && (
                        <Grid item xs={12}>
                          <p style={{
                            color: "red"
                          }}>This area is not supported for instant shipping yet</p>
                        </Grid>
                      )}

                    </Grid>
                    <div className={classes.theDivider}></div>
                    <br />
                    <div className="theSubTitle">
                      <Paragraph
                        size="500"
                        bold="600"
                        color="#1e1e1e"
                        margin="0 0 1.4rem 0"
                      >
                        Shipping Method
                      </Paragraph>
                    </div>

                    <Grid container spacing={2}>

                      {hiddenArea == false ? (
                        <></>
                      ) : (
                        <>
                          {cart != undefined && cart.length > 0 ? (
                            <>
                              {shippingPriceSelection.instant != null && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    onClick={() => handleOpenInstantTimeModal()}
                                    className={
                                      shippingType == "instant"
                                        ? classes.shipping_selection_active
                                        : classes.shipping_selection
                                    }
                                  >
                                    Express (1 hour)

                                    <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM {parseFloat(shippingPriceSelection.instant).toFixed(2)}</Paragraph>
                                  </Grid>
                                </>
                              )}
                              {shippingPriceSelection.standard != null && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    onClick={() => handleOpenStandardTimeModal()}
                                    className={
                                      shippingType == "standard"
                                        ? classes.shipping_selection_active
                                        : classes.shipping_selection
                                    }
                                  >
                                    Standard (3-5days)

                                    <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM {parseFloat(shippingPriceSelection.standard).toFixed(2)}</Paragraph>
                                  </Grid>
                                </>
                              )}
                            </>
                          ) : null}

                        </>
                      )}


                    </Grid>

                    {shippingType == "instant" && hiddenArea ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormInput
                            bg="#fff"
                            placeholder="Delivery Date "
                            label="Delivery Date"
                            name="date"
                            value={userAddress.date}
                            color="#1e1e1e"
                            bold="600"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <FormInput
                            bg="#fff"
                            placeholder="Delivery Time "
                            label="Delivery Time"
                            name="time"
                            // type="time"
                            value={userAddress.time}
                            color="#1e1e1e"
                            bold="600"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <div className={classes.theTable}>
                <Paragraph
                  size="500"
                  color="#1e1e1e"
                  bold="500"
                  margin="1rem 0"
                >
                  Product List
                </Paragraph>
                <div className="cart_product_list">
                  {cart != undefined && cart.length > 0
                    ? cart.map((item) => (
                      <div
                        className={
                          shippingType == "instant"
                            ? item.is_instant == "1"
                              ? classes.theTableList
                              : classes.theTableList + " disabled"
                            : item.is_instant == "1"
                              ? classes.theTableList + " disabled"
                              : classes.theTableList
                        }
                      >
                        <div className={classes.theFlex}>
                          <div className={classes.theProdIMG}>
                            <img className={classes.img} src={item.images} />
                          </div>
                          <div className={classes.theDetail}>
                            {item.option == "" ? (
                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                bold="600"
                                margin="0 0 0.4rem 0"
                                className={classes.prod}
                              >
                                {item.name}
                              </Paragraph>
                            ) : (
                              <Paragraph
                                size="300"
                                color="#1e1e1e"
                                bold="600"
                                margin="0 0 .6rem 0"
                                className={classes.prod}
                              >
                                {item.name} - {item.option}
                              </Paragraph>
                            )}

                            <div className={classes.theQuantity}>
                              <Paragraph
                                size="200"
                                color="#1e1e1e"
                                bold="500"
                                margin="0rem 1rem 0 0"
                              >
                                Quantity :
                              </Paragraph>
                              {/* <div className={classes.theIncrement}> */}
                              {/* <div
                                  className={classes.theIncrementBtn}
                                  onClick={() => {
                                    decreaseProduct(item.index);
                                    setCartCount(cartCount - 1);
                                  }}
                                >
                                  <Remove className={classes.theBtnStyle} />
                                </div> */}
                              {count == 0 ? (
                                // <div className={classes.theBox}>
                                <p>0</p>
                              ) : (
                                // </div>
                                // <div className={classes.theBox}>
                                <Paragraph
                                  size="300"
                                  color="#1e1e1e"
                                  bold="600"
                                >
                                  {item.quantity}
                                </Paragraph>
                                // </div>
                              )}
                              {/* <div
                                  className={classes.theIncrementBtn}
                                  onClick={() => {
                                    increaseProduct(item.index);
                                    setCartCount(cartCount + 1);
                                  }}
                                >
                                  <Add className={classes.theBtnStyle} />
                                </div> */}
                              {/* </div> */}
                            </div>
                            <div className={classes.theTotalPrice}>
                              <Paragraph
                                size="300"
                                color="#7f222c"
                                bold="500"
                                margin=".4rem 0"
                              >
                                {shippingType == "instant" ? (
                                  <>
                                    {item.is_instant == "1" ? (
                                      <>
                                        <span
                                          style={{ marginRight: ".4rem" }}
                                        >
                                          RM
                                        </span>
                                        {(
                                          item.price * parseFloat(count)
                                        ).toFixed(2)}
                                      </>
                                    ) : (
                                      "Item not available for express delivery"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.is_instant == "1" ? (
                                      <>
                                        Item not available for standard delivery
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          style={{ marginRight: ".4rem" }}
                                        >
                                          RM
                                        </span>
                                        {(
                                          item.price * parseFloat(count)
                                        ).toFixed(2)}
                                      </>
                                    )}
                                  </>
                                )}
                              </Paragraph>
                            </div>
                          </div>
                          <Link
                            className="theRemoveItem"
                            onClick={() => removeCartProduct(item.index)}
                          >
                            <Close className={classes.theCloseIcon} />
                          </Link>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
                <div className={classes.theGiftCard}>
                  <div className={classes.theFlexGiftCard}>
                    <Paragraph
                      size="500"
                      color="#1e1e1e"
                      bold="500"
                      margin="0"
                    >
                      Add a Gift Card
                    </Paragraph>
                    <label>
                      <Toggle
                        defaultChecked={isEnabled}
                        onChange={toggleSwitch}
                        icons={false}

                      />
                    </label>
                  </div>
                  {isEnabled == true && (
                    <>
                      <FormInput
                        bg="#fff"
                        placeholder="From"
                        name="sender_name"
                        label="From"
                        color="#1e1e1e"
                        bold="600"
                        onChange={handleChange}
                      />
                      <FormInput
                        bg="#fff"
                        placeholder="To"
                        name="receiver_name"
                        label="To"
                        color="#1e1e1e"
                        bold="600"
                        onChange={handleChange}
                      />
                      <FormInput
                        bg="#fff"
                        placeholder="Message to your recipient"
                        name="message"
                        label="Message"
                        color="#1e1e1e"
                        bold="600"
                        rows={4}
                        multiline
                        onChange={handleChange}
                      />
                      <br />
                    </>
                  )}
                </div>
                <div className={classes.theCalculate}>
                  <Paragraph
                    size="500"
                    bold="600"
                    color="#1e1e1e"
                    margin="1rem 0"
                  >
                    Checkout Detail
                  </Paragraph>
                  <div className={classes.theFlexCalculate}>
                    <Paragraph size="500" color="#1e1e1e">
                      SubTotal
                    </Paragraph>
                    <Paragraph size="500" color="#1e1e1e" bold="500">
                      RM {getTotalCart(shippingType).toFixed(2)}
                    </Paragraph>
                  </div>

                  <div className={classes.theFlexCalculate}>
                    <Paragraph size="500" color="#1e1e1e">
                      Delivery Fee
                    </Paragraph>
                    <Paragraph size="500" color="#1e1e1e" bold="500">
                      RM{" "}
                      {shippingPriceSelection[shippingType] ? (
                        <>
                          {parseFloat(
                            shippingPriceSelection[shippingType]
                          ).toFixed(2)}{" "}
                        </>
                      ) : (
                        "0.00"
                      )}
                    </Paragraph>
                  </div>

                  {promoCodePrice > 0 && (
                    <div className={classes.theFlexCalculate}>
                      <Paragraph size="500" color="#1e1e1e">
                        Discount
                      </Paragraph>
                      <Paragraph size="500" color="#1e1e1e" bold="500">
                        RM {promoCodePrice}
                      </Paragraph>
                    </div>
                  )}


                  {/* <div className={classes.theFlexCalculate}>
                    <Paragraph size="300" color="#1e1e1e">
                      Delivery Fee
                    </Paragraph>
                    <Paragraph size="300" color="#1e1e1e" bold="600">
                      RM {deliveryTypePrice}
                    </Paragraph>
                  </div> */}

                  <div className={classes.theFlexRow}>
                    <FormInput
                      bg="#fff"
                      placeholder="Promo Code"
                      name="voucher_code"
                      onChange={handleChangePromo}
                      color="#1e1e1e"
                      bold="600"
                      margin="0"
                      mb="0"
                    />

                    <Button
                      label="Submit"
                      bold="600"
                      bg="#ffae33"
                      color="#fff"
                      margin="0.7rem 3rem"
                      className={classes.paddBtn}
                      onClick={() => handleChangeSubmitPromo()}
                    />
                  </div>

                  <br />
                  <div className={classes.theFlexCalculate}>
                    <Paragraph size="500" bold="600" color="#1e1e1e">
                      Total Price
                    </Paragraph>
                    {/* <Paragraph size="500" bold="600" color="#7f222c">RM {totalCount}</Paragraph> */}
                    <Paragraph size="500" bold="600" color="#7f222c">
                      RM{" "}
                      {
                        !isNaN(
                          parseFloat(
                            getTotalCart(shippingType) +
                            parseFloat(shippingPriceSelection[shippingType]) -
                            promoCodePrice
                          ).toFixed(2)
                        ) ?
                          parseFloat(
                            getTotalCart(shippingType) +
                            parseFloat(shippingPriceSelection[shippingType]) -
                            promoCodePrice
                          ).toFixed(2) : 0.00

                      }
                    </Paragraph>
                  </div>
                </div>
                {!isLoading ? (
                  <>
                    <div className={classes.theCheckout}>
                      <Button
                        label="Pay With Card"
                        bold="600"
                        bg="#869623"
                        color="#fff"
                        onClick={() => handleCheckout('card')}
                      />
                    </div>
                    <div className={classes.theCheckout}>
                      <Button
                        label="Pay With FPX"
                        bold="600"
                        bg="#000"
                        color="#fff"
                        onClick={() => handleCheckout('fpx')}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{
                    textAlign: "center"
                  }}>
                    <CircularProgress style={{

                      textAlign: "center"
                    }}></CircularProgress>
                  </div>
                )}

              </div>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <LoginModal
        onClose={handleCloseLoginModal}
        isOpen={openLoginModal}
      ></LoginModal>
    </React.Fragment>
  );
}
