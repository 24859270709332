import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";

export const CategoryContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  /* padding: 1rem 0; */

  /* @media screen and (min-width: 992px) {
    width: 90%;
  } */
`;

export const CategoryContainerOverflow = styled.div`
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding-right: 90px;
`;

// export const CategoryIMG = styled(Link)`
//   position: relative;
//   color: #000;
//   /* padding: 0 0 1rem 0; */
//   display: block;
//   /* height: 130px; */
// `;

export const CategoryIMG = styled(Link)`
  position: relative;
  color: #000;
  /* padding: 0 0 1rem 0; */
  display: block;
  width: 140px;
  /* height: 130px; */

  @media screen and (min-width: 992px){
    width: 120px;
  }
  
  @media screen and (min-width: 1024px){
    width: 157px;
  }

  @media screen and (min-width: 1200px){
    width: 182px;
  }

  @media screen and (min-width: 1440px){
    width: 190px;
  }

  @media screen and (min-width: 1600px){
    width: 300px;
  }
`;

export const CategoryIMGMobile = styled(Link)`
  position: relative;
  color: #000;
  /* padding: 0 0 1rem 0; */
  display: block;
  width: 84px;
  /* height: 130px; */

  @media screen and (min-width: 414px){
    width: 93px;
  }
`;

export const IMG = styled.img`
  width: 60%;
  height: 60px;
  margin: 0 auto;
  padding: 0 0 0.4rem 0;
  object-fit: contain;
  display: block;
  text-align: center;

  @media screen and (min-width: 768px) {
    width: 80%;
    height: 60px;
  }
`;

export const CardArticleContainer = styled(Link)`
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%); */
  margin-bottom: 1rem;
  display: block;
`;

export const ArticleIMGContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const IMGArticle = styled.img`
  width: 100%;
  /* border-radius: 10px; */
  object-fit: contain;
  /* height: 200px; */

  @media screen and (min-width: 768px) {
    object-fit: cover;
  }
`;

export const Tag = styled.div`
  position: absolute;
  top: 10px;
  right: 6px;
`;

// export const Span = styled.span`
//   color: #fff;
//   background: #e60c0b;
//   padding: 1rem;
//   font-size: 1rem;
//   display: block;
// `;

// export const FlexDate = styled.div`
//   display: flex;
// `;

export const FlexDetail = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;


export const Span = styled.span`
  color: #fb5c18;
  background: #E5EBE9;
  font-weight: 700;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: .8rem;
  display: block;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 0;
  

  @media screen and (min-width: 768px){
    
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 1rem;
  }
`;

export const SpanDot = styled.span`
  color: #fb5c18;
  font-weight: 700;
  padding: 0;
  width: 4px;
  height: 30px;
  text-align: center;
  font-size: 1rem;
  display: block;
  margin: 0 .2rem;
  line-height: 1.;

  @media screen and (min-width: 768px){
    font-size: 1.4rem;
    width: 15px;
    height: 30px;
    text-align: center;
  }
`;

export const FlexDate = styled.div`
  display: flex;
  /* position: absolute; */
  /* top: 24px;
  right: 30px; */
  z-index: 999;
`;

export const NewArrivalIMGCard = styled(Link)`
  position: relative;
  color: #000;
  /* padding: 0 0 1rem 0; */
  display: block;
  /* width: 84px; */
  /* height: 130px; */

  @media screen and (min-width: 414px){
    /* width: 93px; */
  }
`;

export const NewArrivalIMG = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  padding: 0.2rem;
  object-fit: contain;
  display: block;
  text-align: center;

  @media screen and (min-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;


export const ModalOS = styled.div`
  position: absolute;
  background: rgb(255, 128, 1);
  background: linear-gradient(
    0deg,
  
    rgba(255, 215, 95, 1) 35%
    rgba(255, 128, 1, 1) 100%,
  );
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* @media screen and (min-width: 768px) {
    width: 70%;
  }
  @media screen and (min-width: 992px) {
    width: 50%;
  } */
`;

export const ModalHeader = styled.div`
  position: relative;
  /* padding: 1rem 1rem 0 0; */
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 0 1rem;
`;

const config = {
  root: {
    position: "relative",

    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        // height: "100%",
      },
      "& > div > div": {
        // height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: -38,

        ...md("sm", {
          right: "-4px",
        }),

        "&:before": {
          content: "",
          content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: -38,

        ...md("sm", {
          left: "-8px",
        }),

        "&:before": {
          content: "",
          content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "static",
      marginTop: -40,

      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 8,
          borderRadius: "100px",
          backgroundColor: "#fff",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 30,
          },
        },
      },
    },
  },

  theOverlay: {
    zIndex: 1,
    // overflow: "hidden",
    position: "relative",
    paddingRight: "120px",
    display: "flex",
    ...md("xs", {
      paddingRight: "90px",

    }),
    ...md("sm", {
      height: "100px"
    }),

    "&  .slick-list": {
      // overflow: "visible",
    },
  },

  content: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  date: {
    "& :last-child": {
      display: "none",
    },
  },
  theTitle: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  theCategoryDesktop: {
    display: "none",
    ...md("sm", {
      display: "flex",
    }),
  },

  theCategoryMoblie: {
    overflow: "hidden",
    display: "block",
    // marginBottom: '-.4rem',
    ...md("md", {
      display: "none",
    }),
  },

  theMobile: {
    display: "flex",
    overflow: "scroll",
    // ::-webkit-scrollbar {
    // 	width: 5px;
    // 	height: 8px;
    // 	background-color: #aaa;
    // 	border-radius: 10px;
    // }
    // ::-webkit-scrollbar-thumb {
    // 	background-color: rgba(0, 0, 0, 0.5);
    // 	border-radius: 30px;
    // }
  },

  theIMG: {
    width: "100%",
    objectFit: "cover"
  },

  theProductCardx: {
    position: "relative",
    width: "100%",
    // width: "140px",
    margin: "0 auto",
    display: "block",
    padding: "0 .9rem 0 0",
    cursor: 'pointer',
    display: "block",
    ...md("md", {
      padding: "0 3rem 1rem 0",
    }),

  },

  theProductCard2: {
    position: "relative",
    width: "90%",
    margin: "0 auto",
    display: "block",
    // padding: "0 3rem 1rem 0",
    cursor: 'pointer',
    display: "block",
  },

  theProductIMGMobile: {
    position: "relative",
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
    padding: "1rem",
    marginBottom: "1rem",
    ...md("xs", {
      display: "none"
    }),
  },

  theProductIMG: {
    // position: 'relative',
    // display: "none",
    // ...md("sm", {
    //   position: "relative",
    //   borderRadius: "6px",
    //   border: "1px solid #d7d7d7",
    //   padding: "1rem",
    //   marginBottom: "1rem",
    //   display: "block",
    // }),

    position: "relative",
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
    // padding: ".2rem",
    marginBottom: ".4rem",
    display: "block",

  },

  theProductIMGxx: {
    position: 'relative',
    // display: "none",
    ...md("xs", {
      position: "relative",
      borderRadius: "6px",
      border: "1px solid #d7d7d7",
      margin: "1rem",
      marginBottom: "1rem",
      display: "block",
    }),

  },

  theDetail: {
    width: "90%",
    margin: "0 auto"
  },

  theOutofStock: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    backgroundColor: "#e60c0b",
    zIndex: '1',
    width: "80%",
    display: "block",
    padding: "0.4rem .4rem",
    lineHeight: "1.2",

    ...md("sm", {
      width: "66%",
    }),

    ...md("md", {
      width: "60%",
      padding: "0.4rem .8rem",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theModal: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    // right: 'auto',
    bottom: '0',
    transform: 'unset',
    inset: 'unset',
    backgroundColor: '#fff',
    width: '100%',
    padding: 20,
    zIndex: 1
  }
};

export default makeStyles(config);
