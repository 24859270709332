import React from "react";
import { Drawer, List } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { Add, Close, Remove } from "@material-ui/icons";
import {
  CartList,
  SidebarCartContainer,
  SidebarCartHeader,
  TitleContainer,
  FlexContainer,
  ProductIMG,
  ProductDetail,
  FlexSubContainer,
  QuantityContainer,
  IncarementContainer,
  IncrementButton,
  IncrementBox,
  TotalPriceContainer,
  SidebarCartBottom,
  SidebarCartBottomFlex,
  Checkout,
  CheckoutBackground,
  CheckoutButton,
} from "./styles";

import useCart from "../../hooks/useCart";
import Paragraph from "../Typography/Paragraph";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
  prod: {
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "180px",
    whiteSpace: "nowrap",
  },
}));

const SidebarCart = ({ open, onClose, onOpen, handleDrawerToggle }) => {
  const classes = useStyle();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  //

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  // CHECKOUT CART

  let user_id = window.localStorage.getItem("user_id");

  const [count, setCount] = React.useState(1);

  const {
    getCart,
    setCartFunction,
    increaseProduct,
    getTotalCartWithoutShipping,
    decreaseProduct,
    removeProduct,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const [cartCount, setCartCount] = React.useState(0);

  const cartItem = window.localStorage.getItem("cart");

  const countCart = JSON.parse(cartItem);

  React.useEffect(() => {
    setCart(getCart());
  }, [cartCount, getCart]);

  const removeCartProduct = (index) => {
    removeProduct(index);
    setCartCount(cartCount + 1);
  };

  const handleToCheckout = () => {
    console.log(getCart());
  }

  return (
    <Drawer anchor="right" open={open} onOpen={onOpen} onClose={onClose}>
      {sideDrawerList("right")}
      <List component="nav">
        <SidebarCartContainer>
          <SidebarCartHeader>
            <TitleContainer>
              <p className="title">Shopping Cart</p>
              <span>Total {cart ? cart.length : null} items</span>
            </TitleContainer>
            <Link className="theClose" onClick={handleDrawerToggle}>
              <Close style={{color: "#000"}} />
            </Link>
          </SidebarCartHeader>
          {cart != undefined && cart.length > 0
            ? cart.map((item) => (
                <CartList>
                  <FlexContainer>
                    <ProductIMG>
                      <img src={item.images} />
                    </ProductIMG>
                    <ProductDetail>
                      <Paragraph size="300" bold="600">
                        {item.name}
                      </Paragraph>
                      <p className="priceweight">RM {item.single_price}</p>
                      <FlexSubContainer>
                        <QuantityContainer>
                          <IncarementContainer>
                            <IncrementButton
                              margin={"0 0 0 0.3rem"}
                              onClick={() => {
                                decreaseProduct(item.index);
                                setCartCount(cartCount - 1);
                              }}
                            >
                              <Remove className="theButtonStyle" />
                            </IncrementButton>
                            {count == 0 ? (
                              <IncrementBox>
                                <Paragraph center>0</Paragraph>
                              </IncrementBox>
                            ) : (
                              <IncrementBox>
                                <Paragraph center>{item.quantity}</Paragraph>
                              </IncrementBox>
                            )}
                            <IncrementButton
                              margin={"0 0.3rem 0 0"}
                              onClick={() => {
                                increaseProduct(item.index);
                                setCartCount(cartCount + 1);
                              }}
                            >
                              <Add className="theButtonStyle" />
                            </IncrementButton>
                          </IncarementContainer>
                        </QuantityContainer>
                        <TotalPriceContainer>
                          <p className="price">
                            RM
                            {(item.price * parseFloat(count)).toFixed(2)}
                          </p>
                        </TotalPriceContainer>
                      </FlexSubContainer>
                    </ProductDetail>
                  </FlexContainer>
                  {item.option == "" ? (
                    <></>
                  ) : (
                    <FlexContainer style={{ margin: "0" }}>
                      <Paragraph size="300" bold="600" margin=" 0 .4rem 0 0">
                        Variant:
                      </Paragraph>
                      <Paragraph size="300" color="#7f222c" bold="600">
                        {item.option}
                      </Paragraph>
                    </FlexContainer>
                  )}
                </CartList>
              ))
            : null}
        </SidebarCartContainer>
      </List>
      {/* <br/> */}
      <SidebarCartBottom>
        <SidebarCartBottomFlex>
          <p>Subtotal</p>
          <p>RM {getTotalCartWithoutShipping().toFixed(2)}</p>
        </SidebarCartBottomFlex>
        <Checkout>
          <CheckoutBackground>
            <CheckoutButton to="/checkout">Checkout</CheckoutButton>
          </CheckoutBackground>
        </Checkout>
      </SidebarCartBottom>
    </Drawer>
  );
};

export default SidebarCart;
