import React, { useContext } from "react";
import { GeneralContext } from "../context/GeneralContext";


const useGeneral = () => {
    const [general, setGeneral] = useContext(GeneralContext);
  
    const get_download_prompt = ()=>{
        if(general.download_prompt != undefined){
            return false;
        }else{
            return true;
        }
    };
    const set_download_prompt = (res) => {
        setGeneral({
            ...general,
            download_prompt: res
        });
    }
    return {
        get_download_prompt,
        set_download_prompt
    };
  };
  
  export default useGeneral;
  
