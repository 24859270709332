import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Menu, Search } from "@material-ui/icons";

export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const NavbarContainer = styled.div`
  /* background: #fff; */
  background: rgb(255, 128, 1);
  background: linear-gradient(
    0deg,
    rgba(255, 128, 1, 1) 35%,
    rgba(255, 215, 95, 1) 100%
  );
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  @media screen and (min-width: 992px) {
    padding: 1rem 0;
  }

  .MuiPaper-root {
    background-color: transparent;
    /* box-shadow: 0 0 5px 0 rgb(0 0 0 / 9%) !important; */
  }
`;

export const NavbarList = styled.div`
  display: block;
  width: 100%;
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLower = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 1rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarLink = styled(Link)`
  color: ${({ color }) => color};
  font-weight: 600;
  align-items: center;
  display: flex;
  padding: 0 1rem;
  position: relative;
  border-right: 1px solid #d7d7d7;

  @media screen and (min-width: 992px) {
    padding: 0 1rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 2rem;
  }

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled(Link)`
  width: 220px;

  @media screen and (min-width: 992px) {
    width: 180px;
  }

  @media screen and (min-width: 1440px) {
    width: 220px;
  }
  /* padding-top: 8px; */
  img {
    width: 220px;

    @media screen and (min-width: 992px) {
      width: 180px;
    }

    @media screen and (min-width: 1440px) {
      width: 220px;
    }
  }
`;

export const NavbarIconList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarIconLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ShapeCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #eeeeee;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);
  margin: ${({ margin }) => margin};

  @media screen and (min-width: 992px) {
    width: 40px;
    height: 40px;
    padding: 0.3rem 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const Icon = styled.img`
  width: 80%;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    width: 70%;
  }
`;

export const Badge = styled.span`
  border-radius: 50%;
  background: #5abb4a;
  color: #fff;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.2rem 0.4rem;
  display: initial;
  font-weight: 600;
  top: -20%;
  margin-left: -20px;
  position: absolute;
  right: -8px;
`;

export const NavbarMobileLogo = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  /* padding-top: 10px; */

  @media screen and (min-width: 576px) {
    margin-left: 8rem;
  }

  @media screen and (min-width: 768px) {
    margin-left: 3rem;
  }
  img {
    width: 120px;
    margin: 0 0.2rem;
    @media screen and (min-width: 768px) {
      width: 140px;
      margin: unset;
    }
    @media screen and (min-width: 992px) {
      width: 190px;
    }
  }
`;

export const NavbarIconMobileList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;

  .MuiListItem-root {
    display: flex;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
`;

export const NavLinking = styled(Link)`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;

export const NavbarLeftList = styled.div`
  align-items: center;
  display: flex;
`;

export const SearchContainerDesktop = styled.div`
  align-items: center;
  display: flex;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 50%;
`;

export const Box = styled.div`
  padding: 0.66rem 1rem;
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f8f8f8;
  p {
    color: #000;
    margin: 0;
  }
`;

export const SearchField = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eeeeee;
  background-clip: padding-box;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const SearchButton = styled(Link)`
  /* background: #7f222c; */
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0.6rem 0.8rem;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  align-items: center;
  display: flex;
`;

export const SearchIcon = styled(Search)`
  color: ${({ color }) => color};
`;
