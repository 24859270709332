import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";
import styled from "styled-components/macro";

export const config = {
  theProductCard: {
    position: "relative",
    width: " 90%",
    margin: "0 auto",
    display: "block",
    padding: "0 0 1rem 0",
  },

  theProductIMG: {
    position: "relative",
    borderRadius: "6px",
    marginBottom: "1rem",
    border: "1px solid #d7d7d7",
    "& img": {
      width: "100%",
      height: "auto",
      objectFit: "contain",
      // top: "50% !important",
      // left: "50% !important",
      // transform: "translate(-50%,-50%)"
    },
  },

  theProductIMG2_: {
    position: "relative",
    borderRadius: "6px",
    marginBottom: "1rem",
    border: "1px solid #d7d7d7",
    padding: "1rem",
    "& img": {
      width: "100%",
      height: "130px",
      objectFit: "contain",
      top: "50% !important",
      left: "50% !important",
      transform: "translate(-50%,-50%)",
    },
  },

  theGallery: {
    position: "relative",
    width: "100%",
  },

  theGalleryFlex: {
    position: "relative",
    width: "100%",
    flexDirection: "row",
    flexWrap: "unset",
    overflowX: "auto",
    display: "flex",
  },

  theNavLink: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  theNavLinkActive: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",
    borderWidth: "1px",
    borderColor: "#ececec",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  img: {
    width: "100%",
    height: "auto",
    // objectFit: "contain",

    ...md("xs", {
      height: "auto",
    }),
  },

  theDetail: {
    position: "relative",
  },

  theFlex: {
    alignItems: "center",
    display: "flex",
    marginBottom: ".4rem",
  },

  row: {
    display: "flex",
  },

  theFlexing: {
    display: "block",
    justifyContent: "space-between",
    alignItems: "center",
    ...md("md", {
      display: "flex",
    }),
  },

  theOutofStock: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    backgroundColor: "#e60c0b",
    zIndex: "1",
    width: "70%",
    display: "block",
    padding: "0.4rem .8rem",
    lineHeight: "1.2",

    ...md("sm", {
      width: "50%",
    }),

    ...md("md", {
      width: "60%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },
};

export const CardArticleContainer = styled(Link)`
  position: relative;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
`;

export const ArticleIMGContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem;
`;

export const IMGArticle = styled.img`
  width: 100%;
  border-radius: 10px;
`;

// export const Tag = styled.div`
//   position: absolute;
//   top: 45px;
//   left: 16px;
// `;

// export const Span = styled.span`
//   color: #fff;
//   background: #e60c0b;
//   padding: 0.375rem .4rem;
//   display: flex;
// `;

export const Tag = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
`;

export const Span = styled.span`
  color: #fb5c18;
  /* background: #E5EBE9; */
  font-weight: 700;
  padding: 0.6rem 0.2rem;
  font-size: 1rem;
  display: block;
  /* border-radius: 4px;
  border: 1px solid #fafafa; */
  /* height: 30px; */

  @media screen and (min-width: 992px) {
    font-size: 1rem;
    padding: 0.6rem 0.4rem;
  }
`;

export const SpanDot = styled.span`
  color: #000;
  font-weight: 700;
  padding: 0.6rem;
  font-size: 1rem;
  display: block;
  /* margin: 0 .4rem; */
  /* line-height: 1.5; */

  @media screen and (min-width: 992px) {
    font-size: 1rem;
    /* padding: .8rem; */
    margin: 0 0.1rem;
    padding: 0.6rem;
  }
`;

export const FlexDate = styled.div`
  display: flex;
  /* position: absolute;
  top: 24px;
  right: 30px;
  z-index: 999; */
  background: #e5ebe9;
  line-height: 1.5;
  padding: 0 0.4rem;
  width: 80%;

  @media screen and (min-width: 768px) {
    padding: 0.2rem;
    width: 40%;
  }

  @media screen and (min-width: 992px) {
    padding: 0.2rem;
    width: auto;
  }
`;

export const FlexDetail = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export default makeStyles(config);
