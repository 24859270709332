import React, { createContext, useState } from "react";

export const GeneralContext = React.createContext();



const GeneralContextProvider = (props) => {
  const [general, setGeneral] = useState({});

  return (
    <GeneralContext.Provider value={[general, setGeneral]}>
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
