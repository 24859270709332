import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import { FormControl, TextField } from "@material-ui/core";
import { md } from "../../styles/responsive";
// import { ComboboxInput } from "@reach/combobox";

export const config = {
	root: {},
	theBox: {
		position: 'relative',
		display: 'block',
		margin: '0 auto',
		padding: '12px'
	},
	theChooseActive: {
		border: '2px solid #009a38',
		// background: '#ededf7',
		padding: 6,
		display: 'block'
	},
	theChoose: {
		border: '1px solid #ffa921',
		// background: '#ededf7',
		padding: 6,
		display: 'block'
	},
	theChooseActive_: {
		border: '2px solid #009a38',
		// background: '#ededf7',
		padding: 6,
		display: 'block',
		width: '30%'
	},
	theChoose_: {
		border: '1px solid #ffa921',
		// background: '#ededf7',
		padding: 6,
		display: 'block',
		width: '30%'
	},
	theFlex: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '1rem',
		alignItems: 'center'
	},
	theTime: {
		padding: 6,
		display: 'block',
	},
	theTimeActive: {
		padding: 6,
		backgroundColor: '#ddd',
		display: 'block',
	},
	theTimeHeight: {
		height: 100,
		overflow: 'scroll',
		border: '1px solid #f0f0f0'
	},
	theDefaultAddress: {
		backgroundColor: '#f0f0f0',
		padding: '0.375rem 0.75rem',
		display: 'block',
		borderBottom: '1px solid #000'
	},
	theSelectedAddress: {
		borderBottom: '1px solid #d7d7d7',
		backgroundColor: '#fff',
		padding: '0.375rem 0.75rem',
		display: 'block'
	},
	theHisDefaultAddress: {
		borderBottom: '1px solid #d7d7d7',
		padding: '0.575rem 0.75rem',
		display: 'block',
	},
	theHisSelectedAddress: {
		borderBottom: '1px solid #d7d7d7',
		backgroundColor: '#fff',
		padding: '0.575rem 0.75rem',
		display: 'block'
	},
	theHistoryHeight: {
		height: '300px',
		overflow: 'scroll'
	},

	theFlexCreateBtn: {
		display: 'flex',
		alignItems: 'center',
		border: '2px solid #ffa921',
		padding: 6,
		justifyContent: 'space-between',
		marginBottom: 14
	},

	theFlexCreateBtn_: {
		display: 'flex',
		alignItems: 'center',
		border: '1px solid #d7d7d7',
		padding: 6,
		justifyContent: 'space-between',
		marginBottom: 14,

	},

	theCurrentAddress: {
		padding: '0.375rem 0.75rem',
		border: '1px solid #eaeaea',
		borderRadius: 5,
		display: 'block',
		color: 'rgba(0,0,0,0.8)',
		marginBottom: 14,
		fontWeight: '600'

	}

};

export const ShowAddress = styled.div`
 display: block !important;
  padding: .6rem 0.85rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: ${(props) => props.textcolor};
  background: ${(props) => props.bg};
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  /* padding: 6px 0 7px; */
  /* height: 1.1876em; */
`;

export const ModalAddress = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  height: 750px;
  overflow: scroll;
  @media screen and (min-width: 768px) {
    width: 70%;
  }
  @media screen and (min-width: 992px) {
    width: 30%;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 0 2rem;
`;

export default makeStyles(config);
