import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const config = {
  root: {},
};

export const CardArticleContainer = styled(Link)`
  position: relative;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
`;

export const ArticleIMGContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const IMGArticle = styled.img`
  width: 100%;
  border-radius: 10px;
`;

export const Tag = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
`;

export const Span = styled.span`
  color: #fff;
  background: #e60c0b;
  padding: 0.375rem 0.8rem;
`;

export const FlexDetail = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export default makeStyles(config);
