import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import banner from "../../assets/images/banner.png";
import useStyles, { FlexContainer, Icon, SubCategoryList } from "./styles";
import { get_subcategory_banner_page, get_sub_category } from "../../api/API";

const Sub_SubCategory = () => {
  const classes = useStyles();
  let { category_id } = useParams();

  let { Sub_SubCategory } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Sub_SubCategory]);

  // API
  const [isBusy, setIsBusy] = React.useState(true);

  const [subCategory, setSubCategory] = React.useState([]);
  const [subSubCategory, setSubSubCategory] = React.useState([]);
  const [product, setProduct] = React.useState([]);

  const [productType, setProductType] = React.useState("category");
  const [banner, setBanner] = React.useState([]);

  //   const [productCategoryID, setProductCategoryID] = React.useState([]);

  const handleChangePage = (category_id) => {
    let postparam = {
      category_id: category_id,
      type: "products",
    };

    get_sub_category(postparam).then((json) => {
      setProduct(json.data[0].products);
      setProductType(json.type);

      setIsBusy(true);
    });
    setProductType("products");
    console.log(category_id);
  };

  const getSubSubCategory = () => {
    let postparam = {
      category_id: category_id,
      type: "category",
    };

    get_sub_category(postparam).then((json) => {
      setSubSubCategory(json.data[0].sub_cat);
      setProductType(json.type);
      setIsBusy(true);
      console.log(json.data[0].sub_cat);
    });
  };

  const getProduct = () => {
    let postparam = {
      category_id: category_id,
      type: "products",
    };

    get_sub_category(postparam).then((json) => {
      setProduct(json.data[0].products);
      setProductType(json.type);

      setIsBusy(true);
    });
  };

  const getBanner = () => {
    let postparam = {
      category_id: category_id,
    };
    get_subcategory_banner_page(postparam).then((json) => {
      setBanner(json.data);
      setIsBusy(false);
    });
  };

  React.useEffect(() => {
    getSubSubCategory();
    getProduct();
    // setSubCategory();
    getBanner();
    setIsBusy(false);
  }, []);

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == false ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          {productType == "category" ? (
            <>
              {banner == null ? (
                <></>
              ) : (
                <Carousel>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </Carousel>
              )}
              <Container>

                <Section>
                  <SubCategoryList>
                    {subSubCategory.map((item) => (
                      <FlexContainer
                        // to={`../sub_subcategory/${item.category_id}`}
                        onClick={() => handleChangePage(item.category_id)}
                      >
                        <Paragraph size={300} fontWeight={"normal"}>
                          {item.name}
                        </Paragraph>
                        <Icon />
                      </FlexContainer>
                    ))}
                  </SubCategoryList>
                </Section>
              </Container>
            </>
          ) : (
            <>
              {banner == null ? (
                <></>
              ) : (
                <Carousel>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </Carousel>
              )}
              <Container>

                <Section>
                  <div className={classes.theProductContainer}>
                    <Grid container spacing={3}>
                      {product.map((item) => (
                        <Grid xs={6} sm={4} md={3} key={item.category_id}>
                          <Link
                            className={classes.theProductCard}
                            to={`../productdetail/${item.product_id}`}
                          >
                            <div className={classes.theProductIMG}>
                              <img
                                className={classes.img}
                                src={item.product_image}
                              />
                            </div>
                            <div className={classes.theDetail}>
                              {item.single_price == null ? (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.single_price).toFixed(2)}
                                </Paragraph>
                              ) : (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.single_price).toFixed(2)}
                                </Paragraph>
                              )}
                             
                              <Paragraph
                                size="300"
                                color="#878787"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                {item.name}
                              </Paragraph>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Section>
              </Container>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default Sub_SubCategory;
