import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Close, Search } from "@material-ui/icons";

export const CartList = styled.div`
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding: 2rem 1rem 1rem 1rem;
`;

export const SidebarCartContainer = styled.div`
  position: relative;
  height: 83vh;
  overflow: scroll;
  /* margin-bottom: 100px; */
`;

export const SidebarCartHeader = styled.div`
  position: relative;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0.2rem 1rem;
  border-bottom: 1px solid #eaeaea;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;

  .title {
    color: #1e1e1e;
    font-weight: 600;
    margin: 0;
  }
  span {
    color: #7f2328;
    font-size: 14px;
    font-weight: 600;
    margin: 0.2rem 0 0 0.6rem;
    line-height: 1;
  }
`;

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;

export const ProductIMG = styled.div`
  position: relative;
  width: 80px;
  border: 1px solid #eeeeee;
  margin-right: 1rem;
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
`;

export const ProductDetail = styled.div`
  position: relative;
  .prod {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0.4rem 0;
  }
  .priceweight {
    color: #7f222c;
    font-weight: 500;
    margin: 0 0 0.4rem 0;
  }
`;

export const FlexSubContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const QuantityContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  display: flex;
`;

export const IncarementContainer = styled.div`
  border: 1px solid #d6d6d6;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const IncrementButton = styled.div((props) => ({
  margin: props.margin,
  padding: "0.3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "4px",

  theButtonStyle: {
    color: "#1e1e1e",
    fontSize: "1rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const IncrementBox = styled.div`
  position: relative;
  width: 30px;
  border-radius: 4px;
`;

export const TotalPriceContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  width: 100%;

  .price {
    /* text-align: center; */
    color: #ee4d2d;
    font-weight: 500;
    margin: 0;
  }
`;

export const SidebarCartBottom = styled.div`
  /* position: sticky; */
  position:absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
`;

export const SidebarCartBottomFlex = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 1rem;
  p {
    color: #7f2328;
    font-weight: 700;
    margin: 0;
  }
`;

export const Checkout = styled.div`
  background: #f2f2f2;
  padding: 1rem;
`;

export const CheckoutBackground = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #899c42;
`;

export const CheckoutButton = styled(Link)`
  position: relative;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 0.6rem 3rem;
  justify-content: center;
  display: block;
`;