import React from "react";
import { Container, Grid, Select, MenuItem } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import banner from "../../assets/images/banner.png";
import useStyles, {
  FlexContainer,
  Icon,
  SubCategoryList,
  FormInputSelect,
} from "./styles";
import {
  get_sub_category,
  get_banner_page,
  get_subcategory_banner_page,
  filter_product,
} from "../../api/API";
import { useHistory } from "react-router";
import SmoothImage from "react-smooth-image";

const SubCategory = (props) => {
  const classes = useStyles();
  let { category_id } = useParams();

  let { SubCategory } = useLocation();
  React.useEffect(() => {}, [SubCategory]);

  // API
  const history = useHistory();
  const [isBusy, setIsBusy] = React.useState(true);

  const [sortState, setSortState] = React.useState(0);
  const [subCategory, setSubCategory] = React.useState([]);
  const [subSubCategory, setSubSubCategory] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [banner, setBanner] = React.useState([]);
  const [selectionPrice, setSelectionPrice] = React.useState([]);

  const getSubCategory = () => {
    let postparam = {
      category_id: category_id,
      type: "category",
    };

    get_sub_category(postparam).then((json) => {
      setSubCategory(json.data[0].sub_cat);
      // setIsBusy(true);
    });
  };

  const getBanner = () => {
    let postparam = {
      category_id: category_id,
    };
    get_subcategory_banner_page(postparam).then((json) => {
      setBanner(json.data);
      // setIsBusy(false);
    });
  };

  const getProduct = () => {
    let postparam = {
      category_id: category_id,
      type: "products",
      is_sort: sortState,
    };

    get_sub_category(postparam).then((json) => {
      setProduct(json.data[0].products);
      // setIsBusy(true);

      var previousOffset = window.localStorage.getItem("scrollYCategory");
      var previousCategory = window.localStorage.getItem("current_category");

      if (
        previousOffset != undefined &&
        previousCategory != undefined &&
        previousCategory == category_id
      ) {
        // setTimeout(() => {
        window.scrollTo(0, previousOffset);
        // }, 3000);
      } else {
        window.scrollTo(0, 0);
      }
    });
  };

  const handleChange = (event) => {
    console.log("select", event.target.value);
    setSortState(event.target.value);

    let postparam = {
      category_id: category_id,
      type: "products",
      is_sort: event.target.value,
    };

    get_sub_category(postparam).then((json) => {
      setProduct(json.data[0].products);
      // setIsBusy(true);

      var previousOffset = window.localStorage.getItem("scrollYCategory");
      var previousCategory = window.localStorage.getItem("current_category");

      if (
        previousOffset != undefined &&
        previousCategory != undefined &&
        previousCategory == category_id
      ) {
        // setTimeout(() => {
        window.scrollTo(0, previousOffset);
        // }, 3000);
      } else {
        window.scrollTo(0, 0);
      }
    });
  };

  React.useEffect(() => {
    setSubCategory([]);
    setProduct([]);
    console.log(props);
    getSubCategory();
    // getSubSubCategory();
    getBanner();
    getProduct();
    // setIsBusy(false);
  }, [props.match.params]);

  const openProduct = (product_id) => {
    console.log(window.pageYOffset);
    window.localStorage.setItem("scrollYCategory", window.pageYOffset);
    window.localStorage.setItem(
      "current_category",
      props.match.params.category_id
    );
    props.history.push("/productdetail/" + product_id);
  };

  const goNextPage = (category_id) => {
    history.push("/subcategory/" + category_id);
    console.log(history);
  };

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == false ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          {subCategory != "" ? (
            <>
              {banner == null ? (
                <></>
              ) : (
                <Carousel>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </Carousel>
              )}
              <Container>
                <Section>
                  <SubCategoryList>
                    {subCategory.map((item) => (
                      <FlexContainer
                        onClick={() => {
                          goNextPage(item.category_id);
                        }}
                      >
                        <Paragraph size={300} fontWeight={"normal"}>
                          {item.name}
                        </Paragraph>
                        <Icon />
                      </FlexContainer>
                    ))}
                  </SubCategoryList>
                </Section>
              </Container>
            </>
          ) : (
            <>
              {banner == null ? (
                <></>
              ) : (
                <Carousel>
                  {banner.map((item) => (
                    <CarouselIMG src={item.banner_image} />
                  ))}
                </Carousel>
              )}
              <Container>
                <Section>
                  <div className={classes.theProductContainer}>
                    <div className={classes.theProductFilter}>
                      <FormInputSelect>
                        <Select
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="is_sort"
                          value={sortState}
                        >
                          <MenuItem value={0}>Default</MenuItem>
                          <MenuItem value={1}>Price Low To High</MenuItem>
                          <MenuItem value={2}>Price Hight To Low</MenuItem>
                        </Select>
                      </FormInputSelect>
                    </div>
                    <Grid container spacing={3}>
                      {product.map((item) => (
                        <Grid xs={6} sm={4} md={3} key={item.categori_id}>
                          <div
                            className={classes.theProductCard}
                            onClick={() => {
                              openProduct(item.product_id);
                            }}
                            // to={`../productdetail/${item.product_id}`}
                          >
                            <div className={classes.theProductIMGMobile}>
                              {item.max_stock == "" ? (
                                <>
                                  {item.stock == 0 ? (
                                    <div className={classes.theOutofStock}>
                                      <Paragraph
                                        size="400"
                                        color="#fff"
                                        bold="600"
                                        // margin="0.2rem 0.5rem"
                                        center
                                      >
                                        Out Of Stock
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.max_stock == 0 ? (
                                    <div className={classes.theOutofStock}>
                                      <Paragraph
                                        size="400"
                                        color="#fff"
                                        bold="600"
                                        // margin="0.2rem 0.5rem"
                                        center
                                      >
                                        Out Of Stock
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                              <SmoothImage
                                imageStyles={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "contain",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                }}
                                src={item.product_image}
                              />
                            </div>
                            <div className={classes.theProductIMG}>
                              {item.max_stock == "" ? (
                                <>
                                  {item.stock == 0 ? (
                                    <div className={classes.theOutofStock}>
                                      <Paragraph
                                        size="500"
                                        color="#fff"
                                        bold="600"
                                        // margin="0.2rem 0.5rem"
                                        center
                                      >
                                        Out Of Stock
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.max_stock == 0 ? (
                                    <div className={classes.theOutofStock}>
                                      <Paragraph
                                        size="500"
                                        color="#fff"
                                        bold="600"
                                        // margin="0.2rem 0.5rem"
                                        center
                                      >
                                        Out Of Stock
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}

                              <SmoothImage
                                imageStyles={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                }}
                                src={item.product_image}
                              />
                            </div>
                            {/* <div className={classes.theDetail}>
                              {item.single_price == "" ? (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.pricing).toFixed(2)}
                                </Paragraph>
                              ) : (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.single_price).toFixed(2)}
                                </Paragraph>
                              )}
                              <Paragraph
                                size="300"
                                color="#878787"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                {item.name}
                              </Paragraph>
                            </div> */}
                            <div className={classes.theDetail}>
                              {item.single_price == null ? (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.pricing).toFixed(2)}
                                </Paragraph>
                              ) : (
                                <Paragraph
                                  size="300"
                                  color="#f4b14a"
                                  bold="600"
                                  margin="0 0 .4rem 0"
                                >
                                  RM {parseFloat(item.single_price).toFixed(2)}
                                </Paragraph>
                              )}
                              {item.ori_price != "0.00" && (
                                <div className={classes.theFlex}>
                                  <Paragraph
                                    size="300"
                                    color="#878787"
                                    textDecoration="line-through"
                                    bold="600"
                                    margin="0 1rem 0 0"
                                  >
                                    RM{parseFloat(item.ori_price).toFixed(2)}
                                  </Paragraph>
                                </div>
                              )}
                              <Paragraph
                                size="300"
                                color="#878787"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                {item.name}
                              </Paragraph>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Section>
              </Container>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default SubCategory;
