import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { md } from "../../styles/responsive";
import { FormControl, TextField } from "@material-ui/core";

const config = {
  theProductContainer: {
    position: "relative",
    padding: "1rem 0",
  },
  theProductFilter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "2rem",
    paddingRight: "0.7rem",
    ...md("sm", {
      paddingBottom: "2.5rem",
    }),
    ...md("xmd", {
      paddingRight: "1.1rem",
    }),
  },

  theProductCard: {
    position: "relative",
    width: " 80%",
    margin: "0 auto",
    display: "block",
    padding: "0 0 1rem 0",
    cursor: 'pointer',
  },

  theProductIMGMobile: {
    position: "relative",
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
    padding: "1rem",
    marginBottom: "1rem",
    ...md("xs", {
      display: "none"
    }),
  },

  theProductIMG: {
    position: 'relative',
    display: "none",
    ...md("xs", {
      position: "relative",
      borderRadius: "6px",
      border: "1px solid #d7d7d7",
      padding: "1rem",
      marginBottom: "1rem",
      display: "block",
    }),

  },

  theOutofStock: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    backgroundColor: "#e60c0b",
    zIndex: '1',
    width: "70%",
    display: "block",
    padding: "0.4rem .8rem",
    lineHeight: "1.2",

    ...md("sm", {
      width: "50%",
    }),

    ...md("md", {
      width: "60%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theGallery: {
    position: "relative",
    width: "100%",
  },

  theGalleryFlex: {
    position: "relative",
    width: "100%",
    flexDirection: "row",
    flexWrap: "unset",
    overflowX: "auto",
    display: "flex",
  },

  theNavLink: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  theNavLinkActive: {
    position: "relative",
    padding: "0.8rem",
    cursor: "pointer",
    marginRight: "1rem",
    borderWidth: "1px",
    borderColor: "#ececec",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
    },
  },

  img: {
    width: "100%",
    height: "100px",
    objectFit: "contain",

    ...md("xs", {
      height: "150px",
    }),
  },

  theDetail: {
    position: "relative",
  },

  theFlex: {
    alignItems: "center",
    display: "flex",
    marginBottom: ".4rem",
  },
};

export const SubCategoryList = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
`;

export const FlexContainer = styled.div`
  color: #000;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #d7d7d7;
  padding: 1rem 0;
`;

export const Icon = styled(ChevronRight)`
  color: #000;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export default makeStyles(config);
