import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselIMG } from "../../components/Carousel/styles";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import banner from "../../assets/images/banner.png";
import Loader from "../../components/Loader/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Countdown from "react-countdown";

import useStyles, {
  ArticleIMGContainer,
  IMGArticle,
  Tag,
  Span,
  SpanDot,
  FlexDate,
} from "./styles";
import { get_blog_detail } from "../../api/API";
import SmoothImage from "react-smooth-image";
import moment from "moment";

const ArticleDetail = (props) => {
  const classes = useStyles();
  let { blog_id } = useParams();
  let { ArticleDetail } = useLocation();
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [ArticleDetail]);

  //API

  const [article, setArticle] = React.useState({});
  const [productByArticle, setProductByArticle] = React.useState([]);
  const [promoDate, setPromoDate] = React.useState("");
  const [detailImage, setDetailImage] = React.useState([]);
  const [steps, setSteps] = React.useState([]);
  const [isBusy, setIsBusy] = React.useState(true);

  const getArticle = () => {
    let postpram = {
      blog_id: blog_id,
    };
    get_blog_detail(postpram).then((json) => {
      setArticle(json.data);
      setProductByArticle(json.data.products);
      setIsBusy(false);
      setPromoDate(json.data.offer_date);

      if (json.data.detail_images == "") {
      } else {
        setDetailImage(json.data.detail_images);
      }

      if (json.data.setps == "") {
      } else {
        setSteps(json.data.steps);
      }

      var previousOffset = window.localStorage.getItem("scrollYCategory");
      var previousCategory = window.localStorage.getItem("current_article");

      if (
        previousOffset != undefined &&
        previousCategory != undefined &&
        previousCategory == blog_id
      ) {
        // setTimeout(() => {
        window.scrollTo(0, previousOffset);
        // }, 3000);
      } else {
        window.scrollTo(0, 0);
      }
    });
  };

  React.useEffect(() => {
    getArticle();
    setIsBusy(true);
  }, []);

  // const getCountdown = () => {
  //   const timeRemaining = new Date(promoDate) - new Date();
  //   let countdown = {};
  //   if (timeRemaining > 0) {
  //     countdown = {
  //       DAYS: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
  //       HR: Math.floor((timeRemaining / (1000 * 60 * 60)) % 24),
  //       MIN: Math.floor((timeRemaining / 1000 / 60) % 60),
  //       SECONDS: Math.floor((timeRemaining / 1000) % 60),
  //     };
  //   }
  //   return countdown;
  // };

  // const [countdown, setCountdown] = React.useState(getCountdown());

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setCountdown(getCountdown());
  //   }, 100);
  // });

  // const data = [];
  // Object.entries(countdown).forEach(([unit, value]) => {
  //   data.push(
  //     <Paragraph size="400" className={classes.row}>
  //       <Paragraph
  //         size="400"
  //         bold="700"
  //         margin="0 .6rem"
  //         className={classes.date}
  //       >
  //         {value}
  //       </Paragraph>
  //       {unit}
  //     </Paragraph>
  //   );
  // });

  const [countdown, setCountdown] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    setTimeout(() => {
      getCountdown();
    }, 100);
  });

  const getCountdown = () => {
    const timeRemaining = new Date(promoDate) - new Date();
    if (timeRemaining) {
      let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      let hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
      let seconds =
        (Math.floor((timeRemaining / 1000) % 60) < 10 ? "0" : "") +
        Math.floor((timeRemaining / 1000) % 60);
      setCountdown({ days: days, hours: hours, minutes, seconds });
    }
  };

  const openProduct = (product_id) => {
    console.log(window.pageYOffset);
    window.localStorage.setItem("scrollYCategory", window.pageYOffset);
    window.localStorage.setItem("current_article", blog_id);
    if (blog_id == 5) {
      // props.history.push("/article/product/" + product_id);
      props.history.push("/productdetail/" + product_id);
    } else {
      props.history.push("/productdetail/" + product_id);
    }
  };

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      {isBusy == true ? (
        <>
          <Loader
            type="TailSpin"
            color="#ff8001"
            height={80}
            // timeout={3000}
            width={80}
          />
        </>
      ) : (
        <>
          {detailImage == "" ? (
            <></>
          ) : (
            <Carousel>
              {detailImage.map((item) => (
                <CarouselIMG src={item.image} />
              ))}
            </Carousel>
          )}
          <Container>
            <Section pd="0">
              <div className={classes.theFlexing}>
                <Paragraph size={600} margin="1rem 0">
                  {article.title}
                </Paragraph>
                {article.offer_date == "" ||
                  article.offer_date == "0000-00-00 00:00:00" ? (
                  <></>
                ) : (
                  <Countdown
                    date={moment(article.offer_date).format()}
                    renderer={({ days, hours, minutes, seconds }) => (
                      <FlexDate className="countdown_date">
                        <Span style={{ color: "#e60c0b" }}>ENDING IN</Span>
                        {days > 0 && (
                          <>
                            <Span> {days}</Span>
                            <SpanDot>Day</SpanDot>
                          </>
                        )}

                        <Span> {hours}</Span>
                        <SpanDot>H</SpanDot>
                        <Span> {minutes}</Span>
                        <SpanDot>M</SpanDot>
                        {days <= 0 && (
                          <>
                            <Span> {seconds}</Span>
                            <SpanDot>S</SpanDot>
                          </>
                        )}
                      </FlexDate>
                    )}
                  ></Countdown>
                  // <Countdown
                  //   date={moment(article.offer_date).format()}
                  //   renderer={({ days, hours, minutes, seconds }) => (
                  //     <FlexDate className="countdown_date">
                  //       <Span>Ending In</Span>
                  //       {days > 0 && (
                  //         <>
                  //           <Span> {days}</Span>
                  //           <SpanDot>Day</SpanDot>
                  //         </>
                  //       )}

                  //       <Span> {hours}</Span>
                  //       <SpanDot>Hr</SpanDot>
                  //       <Span> {minutes}</Span>
                  //       <SpanDot></SpanDot>
                  //       {days <= 0 && (
                  //         <>
                  //           <Span> {seconds}</Span>
                  //           <SpanDot>s</SpanDot>
                  //         </>
                  //       )}

                  //     </FlexDate>
                  //   )}
                  // ></Countdown>
                )}
              </div>
              <Paragraph
                size={300}
                margin="1rem 0"
                dangerouslySetInnerHTML={{
                  __html: article.content,
                }}
              ></Paragraph>
            </Section>
            <br />
            <Section pd="0">
              <Grid container spacing={3}>
                {productByArticle.map((item) => (
                  <Grid xs={6} sm={4} md={3} key={item.categori_id}>
                    <div
                      onClick={() => {
                        openProduct(item.product_id);
                      }}
                      className={classes.theProductCard}
                    // to={`../productdetail/${item.product_id}`}
                    >
                      {article.blog_setting_id == 1 ? (
                        <div className={classes.theProductIMG}>
                          {item.max_stock == "" ? (
                            <>
                              {item.stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>

                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {item.max_stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>

                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <SmoothImage
                            imageStyle={{
                              width: "100%",
                              height: "auto",
                              objectFit: "contain",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                            src={item.image}
                          />
                        </div>
                      ) : (
                        <div className={classes.theProductIMG2_}>
                          {item.max_stock == "" ? (
                            <>
                              {item.stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>

                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {item.max_stock == 0 ? (
                                <div className={classes.theOutofStock}>
                                  <Paragraph
                                    size="400"
                                    color="#fff"
                                    bold="600"
                                    // margin="0.2rem 0.5rem"
                                    center
                                  >
                                    Out Of Stock
                                  </Paragraph>
                                </div>

                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <SmoothImage
                            imageStyle={{
                              width: "100%",
                              // height: "130px !important",
                              objectFit: "contain",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                            src={item.image}
                          />
                        </div>
                      )}

                      <div className={classes.theDetail}>
                        <div className={classes.theFlex}>
                          {item.pricing == "0.00" ? (
                            <Paragraph size="300" color="#f4b14a" bold="600">
                              RM {parseFloat(item.option_price).toFixed(2)}
                            </Paragraph>
                          ) : (
                            <Paragraph size="300" color="#d0011b" bold="600">
                              RM {parseFloat(item.pricing).toFixed(2)}
                            </Paragraph>
                          )}
                          {item.ori_price == "0.00" ? (
                            <></>
                          ) : (
                            <Paragraph
                              size="300"
                              color="#878787"
                              textDecoration="line-through"
                              bold="600"
                              margin="0 1rem 0 1rem"
                            >
                              RM{parseFloat(item.ori_price).toFixed(2)}
                            </Paragraph>
                          )}
                        </div>
                        <Paragraph
                          size="300"
                          color="#878787"
                          bold="600"
                          margin="0 0 .4rem 0"
                        >
                          {item.name}
                        </Paragraph>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                {steps.map((item) => (
                  <Grid xs={12} sm={4} md={3}>
                    <Link className={classes.theProductCard}>
                      <div className={classes.theProductIMG}>
                        <img className={classes.img} src={item.image} />
                      </div>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Section>
          </Container>
        </>
      )}
    </React.Fragment>
  );
};

export default ArticleDetail;
