import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import CartContextProvider from "./context/CartContext";
import { StripeProvider } from 'react-stripe-elements';
import AddressContextProvider from "./context/AddressContext";
import { CloseOutlined } from "@material-ui/icons";
import GeneralContextProvider from "./context/GeneralContext";
// import { createBrowserHistory } from "history";
// import { wrapHistory } from "oaf-react-router";

// const history = createBrowserHistory();

// const settings = {

//   disableAutoScrollRestoration: false,
//   restorePageStateOnPop: true,
//   // Set this to true for smooth scrolling.
//   // For browser compatibility you might want iamdustan's smoothscroll polyfill https://github.com/iamdustan/smoothscroll
//   smoothScroll: true,
// };

// console.log(history);

// wrapHistory(history, settings);

// pk_test_51IsMxSAWCzsiK49topQ3lbECz7A8Xbi3e00cEUqhvGqs08VGe0jmpOZ63sdufKxtAh4u5LryQlty3Pgv8bF7IVga00t4HLtild

// LIVE
// pk_live_51IsMxSAWCzsiK49t0CQf1CYMklCZJ6BGF90MNoplivpy7c7z1BkvHxSF0gH8m7KqP7i5mTN6Bel0jKO81lzEXQdV00KMeCCftD

function App() {

  // const [detectDevice, setDetectDevice] = React.useState();

  // const handleCheckDevice = () => {
  //   setDetectDevice(window.navigator.userAgent);
  //   // window.location = "stockyapp://"
  //   // setTimeout(() => {
  //   //   if (alert('You do not seem to have Your App installed, do you want to go download it now?')) {
  //   //     window.location = 'stockyapp://';
  //   //   }
  //   // }, 300);
  // }

  // if (detectDevice == /iPhone/i) {
  //   alert('iPhone');
  //   //   window.location.href = "stockyapp://"
  //   // } else {
  //   //   window.location.href = "https://apps.apple.com/my/app/stockymy/id1581707114"
  // }

  // if (detectDevice == /Android/i) {
  //   // handleCheckDevice();
  //   //   window.location.href = "stockyapp://"
  //   // } else {
  //   //   // window.location.href = "https://play.google.com/store/apps/details?id=com.stockyapp"
  //   //   window.location.href = "stockyapp://"
  //   window.location = 'stockyapp://';
  // }

  // console.log(detectDevice);

  // React.useEffect(() => {
  //   handleCheckDevice();

  // }, [])

  return (
    <StripeProvider apiKey="pk_live_51IsMxSAWCzsiK49t0CQf1CYMklCZJ6BGF90MNoplivpy7c7z1BkvHxSF0gH8m7KqP7i5mTN6Bel0jKO81lzEXQdV00KMeCCftD">
      <GeneralContextProvider>
        <CartContextProvider>
          <AddressContextProvider>
            <BrowserRouter>
              <Routes></Routes>
            </BrowserRouter>
          </AddressContextProvider>
        </CartContextProvider>
      </GeneralContextProvider>
    </StripeProvider>
  );
}

export default App;
