import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
} from "./styles";
import account from "../../assets/images/account.svg";
import pin from "../../assets/images/pin.svg";
import bag from "../../assets/images/bag.svg";
import gift from "../../assets/images/gift.svg";
import { get_user_profile, profile } from "../../api/API";
// import "./Account.css";
import useStyles from "./styles";

export default function Account() {
  const history = useHistory();
  const classes = useStyles();

  let { Profile } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Profile]);

  // GET PROFILE

  const [token, setToken] = React.useState("");
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    get_user_profile(postparam).then((json) => {
      setUser(json.data);
    });
  }, []);

  // UPDATE PROFILE

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
    setChangePass({
      ...changepass,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditProfile = () => {
    profile(user).then((json) => {
      if (json.status) {
        alert("Your profile has been updated!");
        history.push("/account");
      }else{
        alert(json.message);
      }
    });
  };

  // CHNAGE PASSWORD

  const [changepass, setChangePass] = React.useState({
    old_password: "",
  });

  const handleChangePassword = () => {
    // if (changepass.password !== changepass.password2) {
    //   alert("Password Not Same");
    // } else {
    //   profile(changepass).then((json) => {
    //     if (json.status) {
    //       alert("Your password has been changed!");
    //     } else {
    //       alert(json.message);
    //     }
    //   });
    // }
  };

  return (
    <React.Fragment>
      {/* <StickyHeader /> */}
      <Navbar />
      <Container>
        <Section>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <SideBarContainer>
                <SideBarBox>
                  <Icon>
                    <Circle>
                      <img className={classes.theIMG} src={account} />
                    </Circle>
                  </Icon>
                  <p className="name">{user.name}</p>
                  {/* <p className="name">LOREM IPSUM</p> */}
                </SideBarBox>
                <SiderBarList>
                  <NavFlexLink to="/account">
                    <ActiveBorder />
                    <SmallIcon>
                      <SmallIconIMG src={account} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      My Account
                    </Paragraph>
                  </NavFlexLink>
                  {/* <NavFlexLink to="/addresses">
                    <SmallIcon>
                      <SmallIconIMG src={pin} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Address
                    </Paragraph>
                  </NavFlexLink> */}
                  <NavFlexLink to="/orders">
                    <SmallIcon className="theIcon">
                      <SmallIconIMG src={bag} />
                    </SmallIcon>
                    <Paragraph color="#1e1e1e" bold="600" size="300">
                      Orders
                    </Paragraph>
                  </NavFlexLink>
                </SiderBarList>
              </SideBarContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Card>
                <div className={classes.theHeaderProfile}>
                  <Paragraph
                    size="600"
                    color="#7f222c"
                    bold="600"
                    margin="0 0 1rem 0"
                  >
                    My Account
                  </Paragraph>
                  <Paragraph size="500" color="#000" bold="700" margin="1rem 0">
                    Account Information
                  </Paragraph>
                </div>
                <div className={classes.theForm}>
                  <FormInput
                    bg="#eeeeee"
                    placeholder="Full Name"
                    name="name"
                    label="Full Name"
                    color="#1e1e1e"
                    bold="600"
                    value={user.name}
                    onChange={handleChange}
                  />

                  <FormInput
                    bg="#eeeeee"
                    label="Birthday Date"
                    color="#1e1e1e"
                    placeholder="Birthday Date"
                    name="age"
                    type="date"
                    bold="600"
                    value={user.age}
                    onChange={handleChange}
                  />
                  <FormInput
                    bg="#eeeeee"
                    label="Contact Number"
                    color="#1e1e1e"
                    placeholder="Contact Number"
                    name="contact"
                    type="number"
                    bold="600"
                    // disabled
                    value={user.contact}
                    onChange={handleChange}
                  />

                  <div className={classes.theBox}>
                    <Button
                      onClick={() => handleEditProfile()}
                      bg="#7f222c"
                      color="#fff"
                      label="Save"
                      flex="inline-flex"
                    />
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </React.Fragment>
  );
}
