import React from "react";
import {
  Container,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Modal,
  Checkbox,
  ButtonBase,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import { Add, Close, Edit, Remove } from "@material-ui/icons";
import useStyles, {
  FormBox,
  CustomFormControl,
  PhoneInput,
} from "./LoginModalStyles";
import {
  login_user,
  get_otp,
  login_social_email,
  login_social_facebook,
} from "../../api/API";
import { GoogleLogin, GoogleLogout } from "react-google-login";

import FacebookLogin from "react-facebook-login";

export default function LoginModal(props) {
  const classes = useStyles();
  const history = useHistory();
  let { Login } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Login]);

  const [loginData, setLoginData] = React.useState({
    contact: "",
    // otp: "",
    password: "",
    token: "",
  });

  // const [requestOTP, setRequestOTP] = React.useState({
  //   contact: "",
  // });

  const [initialTime, setInitialTime] = React.useState(0);

  const [startTimer, setStartTimer] = React.useState(false);

  // const handleGetOTP = () => {
  //   get_otp(requestOTP).then((json) => {
  //     if (json.status) {
  //       alert("Successful! Please check your phone for your OTP code");
  //       setInitialTime(60);
  //       setStartTimer(true);
  //     }
  //   });
  // };

  const handleChange = (event) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value,
    });
    // setRequestOTP({
    //   ...requestOTP,
    //   [event.target.name]: event.target.value,
    // });
  };

  const handleLogin = () => {
    if (loginData.contact.length == 0 || loginData.password.length == 0) {
      alert("All Field Required");
    }
    login_user(loginData).then((json) => {
      if (json.status) {
        alert("Welcome back to STOCKY");
        props.onClose();
      }
    });
  };

  // React.useEffect(() => {
  //   if (initialTime > 0) {
  //     setTimeout(() => {
  //       console.log("startTime, ", initialTime);
  //       setInitialTime(initialTime - 1);
  //     }, 1000);
  //   }

  //   if (initialTime === 0 && startTimer) {
  //     console.log("done");
  //     setStartTimer(false);
  //   }
  // }, [initialTime, startTimer]);

  // GOOGLE LOGIN

  const clientId =
    "598978527335-55erf8kl8isp35sl7lm3us9chp654s14.apps.googleusercontent.com";

  const [showLoginButton, setShowLoginButton] = React.useState(true);
  const [showLogoutButton, setShowLogoutButton] = React.useState(false);

  const onLoginSuccess = (res) => {
    let postparam = {
      name: res.profileObj.name,
      email: res.profileObj.email,
      googleId: res.profileObj.googleId,
      token: "",
    };

    login_social_email(postparam).then((json) => {
      if (json.status) {
        setShowLoginButton(false);
        setShowLogoutButton(true);
        alert("Welcome back to STOCKY");
        history.push("/");
        // console.log("LOGIN SUCCESS", res.profileObj);
      }
    });
  };

  const onFailureSuccess = (res) => {
    console.log("LOGIN FAILED", res);
  };

  const onSignoutSuccess = () => {
    alert("You have been signed out successfully");
    setShowLoginButton(true);
    setShowLogoutButton(false);
  };

  // FACEBOOK LOGIN

  const [token, setToken] = React.useState("");

  const responseFacebook = (res) => {
    let postparam = {
      name: res.name,
      email: res.email,
      userID: res.userID,
      token: "",
    };

    login_social_facebook(postparam).then((json) => {
      if (json.status) {
        history.push("/");
        console.log("FB" + JSON.stringify(res));
      } else {
        history.push("/login");
      }
    });
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Container>
        <Section>
          <Card
            className={classes.padd}
            style={{
              backgroundColor: "white",
            }}
          >
            <div className="theForm">
              <div className="theLogo">{/* <img src={clover} /> */}</div>
              <Paragraph
                style={{ fontSize: "1.4rem" }}
                color="#7f222c"
                bold="600"
                margin="0 0 1rem 0"
                center
              >
                Login To Continue
              </Paragraph>

              {/* <FormInput
								bg="#eeeeee"
								label="Contact Number"
								color="#1e1e1e"
								placeholder="Contact Number"
								name="contact"
								type="number"
								bold="600"
								onChange={handleChange}
							/> */}

              {/* <div className={classes.theBox}>
									<PhoneInput
										placeholder="Contact Number"
										name="contact"
										onChange={handleChange}
										type="number"
									/>
									<span className={classes.theNumber}>+60</span>
								</div> */}

              <Paragraph
                color="#1e1e1e"
                bold="600"
                margin="0 0 1rem 0"
                style={{ fontSize: "1rem" }}
              >
                Contact Number
              </Paragraph>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderLeft: "1px solid #d7d7d7",
                    borderTop: "1px solid #d7d7d7",
                    borderBottom: "1px solid #d7d7d7",
                    backgroundColor: "#f5f7fa",
                    padding: "10px 5px",
                    color: "#909399",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    width: "50px",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  +60
                </div>
                <PhoneInput
                  placeholder="Contact Number"
                  name="contact"
                  onChange={handleChange}
                  type="number"
                />
              </div>

              <FormInput
                bg="#eeeeee"
                label="Password"
                color="#1e1e1e"
                placeholder="Password"
                name="password"
                type="password"
                bold="600"
                onChange={handleChange}
              />

              {/* <p className={classes.theLabel}>SMS Verification Code</p>

              {initialTime != 0 ? (
                <div style={{ display: "flex" }}>
                  <CustomFormControl
                    placeholder="OTP Code"
                    name="otp"
                    onChange={handleChange}
                  />
                  <Link
                    style={{
                      borderRight: "1px solid #d7d7d7",
                      borderTop: "1px solid #d7d7d7",
                      borderBottom: "1px solid #d7d7d7",
                      backgroundColor: "#f5f7fa",
                      padding: "10px 20px 10px 20px",
                      color: "#909399",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "165px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    {initialTime} seconds
                  </Link>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <CustomFormControl
                    placeholder="OTP Code"
                    name="otp"
                    onChange={handleChange}
                  />
                  <Link
                    style={{
                      borderRight: "1px solid #d7d7d7",
                      borderTop: "1px solid #d7d7d7",
                      borderBottom: "1px solid #d7d7d7",
                      backgroundColor: "#f5f7fa",
                      padding: "10px 20px 10px 20px",
                      color: "#909399",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      width: "165px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                    onClick={() => handleGetOTP()}
                  >
                    GET CODE
                  </Link>
                </div>
              )} */}

              {/* <Link className={classes.theNavLink}>Forgotten Password?</Link> */}

              <br />
              <div className="theAction">
                <Button
                  label="Login To Existing Account"
                  color="#fff"
                  center={1}
                  bg="#7f222c"
                  bold="600"
                  onClick={() => handleLogin()}
                />
                <br />
                <Button
                  center={1}
                  label=" Don't Have An Account Yet? Sign Up Now!"
                  color="#fff"
                  bg="#264653"
                  bold="600"
                  to="/register"
                  style={{ padding: "0.6rem 2rem" }}
                />
              </div>
            </div>
            <br />
            {showLoginButton ? (
              <GoogleLogin
                className={classes.theGoogleLogin}
                clientId={clientId}
                buttonText="Sign in with Google"
                onSuccess={onLoginSuccess}
                onFailure={onFailureSuccess}
                cookiePolicy={"single_host_origin"}
              />
            ) : null}
            {showLogoutButton ? (
              <GoogleLogout
                className={classes.theGoogleLogin}
                clientId={clientId}
                buttonText="Logout"
                onLogoutSuccess={onSignoutSuccess}
              ></GoogleLogout>
            ) : null}
            <br />
            <br />
            <FacebookLogin
              disableMobileRedirect={false}
              // scope="public_profile, email, user_birthday"
              appId="364535515178506"
              textButton="Login with Facebook"
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
            />
          </Card>
        </Section>
      </Container>
    </Modal>
  );
}
