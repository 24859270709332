import React from "react";
import useStyles from "./styles";
import Slider from "react-slick";
import { CarouselContainer } from "./styles";

const Carousel = ({ children, settings }) => {
  const classes = useStyles();
  const defalutSetting = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    ...settings,
  };

  return (
    <CarouselContainer className={classes.root}>
      <Slider {...defalutSetting}>{children}</Slider>
    </CarouselContainer>
  );
};

export default Carousel;
